import { isNullOrUndefined } from 'util';
import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../../base/util/base.service';

@Injectable()
export class WorkflowService extends BaseService {
    constructor(public http: HttpClient) {
        super(http);
    }

    recuperarWorkflow(filtros: any, page: number, pageSize: number) {
        let urlDestino = environment.baseUrl + "solicitacao-workflow";
        urlDestino += this.parametrosUrl(filtros, page, pageSize);
        return this.get(urlDestino);
    }

    recuperaTipoSolicitacao() {
        return this.get(environment.baseUrl + "tipo-solicitacao?page=0&pageSize=0");
    }

    parametrosUrl(filtros: any, page: number, pageSize: number): string {

        let url = "?page=" + page + "&pageSize=" + pageSize;

        if (!isNullOrUndefined(filtros.tipoSolicitacao)) {
            url = url + "&idTipoSolicitacao=" + filtros.tipoSolicitacao;
        }

        if (!isNullOrUndefined(filtros.codigo)) {
            url = url + "&codigo=" + filtros.codigo;
        }

        if (!isNullOrUndefined(filtros.descricao)) {
            url = url + "&descricao=" + filtros.descricao;
        }

        if (!isNullOrUndefined(filtros.status)) {
            url = url + "&status=" + filtros.status;
        }
        return url;
    }

    recuperarNome(cod: string) {
        let urlDestino = environment.baseUrl + "solicitacao-workflow?page=1pageSize=5";
        let codItem: string;
        if (!isNullOrUndefined(cod)) {
            codItem = "&descricao=" + cod;
            urlDestino += codItem;
        }
        return this.get(urlDestino);
    }

    recuperarCodigo(cod: string) {
        let urlDestino = environment.baseUrl + "solicitacao-workflow?page=1pageSize=5";
        let codItem: string;
        if (!isNullOrUndefined(cod)) {
            codItem = "&codigo=" + cod;
            urlDestino += codItem;
        }
        return this.get(urlDestino);
    }
}
