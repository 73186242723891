import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../../../base/util/base.service';
import { environment } from './../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
@Injectable()
export class PessoaService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  recuperarPessoas(filtros: any) {
    let urlDestino = environment.baseUrl + "pessoas/documento";
    urlDestino += this.parametrosUrl(filtros, 1 , 1);
    return this.get(urlDestino);
  }

  recuperarColetores(filtros: any) {
    let urlDestino = environment.baseUrl + "coletores";
    urlDestino += this.parametrosUrl(filtros, 1, 1);
    return this.get(urlDestino);
  }

  parametrosUrl(filtros: any, page: number, pageSize: number): string {

    let url = "?page=" + page + "&pageSize=" + pageSize;
    if (!isNullOrUndefined(filtros.tipoDocumento)) {
        url = url + "&tipoDocumento=" + filtros.tipoDocumento;
    }
    if (!isNullOrUndefined(filtros.documento)) {
        url = url + "&documento=" + filtros.documento;
    }
    return url;
 }


}
