import { Component, OnInit } from '@angular/core';

// Service
import { ZSUploadService } from '../../../../../base/zsFrameWork/zs-upload/zs-upload.service';

@Component({
  selector: 'app-detalhes-interacao',
  templateUrl: './detalhes-interacao.component.html',
  styleUrls: ['./detalhes-interacao.component.css']
})
export class DetalhesInteracaoComponent implements OnInit {

  public isLoading: boolean;
  public displayModalDetalheInteracao: boolean;
  public protocolo: string;
  public interacao: any;
  public descricaoInteracao: string;
  public listaDeArquivosDaInteracao: any;

  constructor(private uploadService: ZSUploadService) { }

  ngOnInit() {
  }

  public show(solicitacaoInteracao: any, protocolo: string ) {
    this.clear();
    this.displayModalDetalheInteracao = true;
    this.interacao = solicitacaoInteracao;
    this.protocolo = protocolo;
    this.descricaoInteracao = this.interacao.texto;
    this.listaDeArquivosDaInteracao = this.interacao.listaDeArquivo;
  }

  public hide() {
    this.displayModalDetalheInteracao = false;
    this.clear();
  }

  public downloadArquivo(arquivo: any) {
    this.uploadService.download(arquivo);
  }

  private limpaModalDetalheInteracao() {
    // todo
  }

  private clear() {
    this.interacao = null;
    this.protocolo = null;
    this.descricaoInteracao = null;
    this.listaDeArquivosDaInteracao = null;
  }
}
