import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../base/util/base.service';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
@Injectable()
export class NovoTipoSolicitacaoService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  enviarTipoSolicitacao(tipoSolicitacao) {
    return this.post(environment.baseUrl + 'tipo-solicitacao', tipoSolicitacao);
  }

  recuperarTipoSolicitacao(id: string) {
    let urlDestino = environment.baseUrl + 'tipo-solicitacao';
    let idItem: string;
    if (!isNullOrUndefined(id)) {
        idItem = '/' + id;
        urlDestino += idItem;
    }
    return this.get(urlDestino);
}

  atualizarTipoSolicitacao(tipoSolicitacao) {
    const urlDestino = environment.baseUrl + 'tipo-solicitacao';
    return this.put(urlDestino, tipoSolicitacao);
  }

}
