import { EtapaSequenciaVO } from "./etapa-sequencia";
import { EtapaGrupoAtendimentoVO } from "./etapa-grupo-atendimento";


export class EtapaWorkflowVO {
    constructor(
        public id?: number,
        public idTemporario?: number,
        public nome?: string,
        public codigo?: string,
        public status?: number,
        public descricaoStatus?: string,
        public idSolicitacao?: number,
        public isNew?: boolean,
        public isEtapaUtilizada = false,
        // sequencias
        public sequenciasSelecionadas = new Array<EtapaSequenciaVO>(),
        // grupoAtendimento
        public gruposSelecionados = new  Array<EtapaGrupoAtendimentoVO>(),
        public nomeUsuarioCriacao?: string,
        public dataCriacao?: Date,
        public nomeUsuarioAlteracao?: string,
        public dataAlteracao?: Date,
        public nomeUsuarioCancelamento?: string,
        public dataCancelamento?: Date
    ) {}
}
