import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../../base/util/utils';

// Service
import { DeletarGrupoAtendimentoService } from './deletar-grupo-atendimento.service';

// VO
import { GrupoAtendimentoVO } from '../../../../base/vo/grupo-atendimento';

// PrimeNg
import { Message } from 'primeng/components/common/api';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-deletar-grupo-atendimento',
  templateUrl: './deletar-grupo-atendimento.component.html',
  styleUrls: ['./deletar-grupo-atendimento.component.css']
})
export class DeletarGrupoAtendimentoComponent implements OnInit {

  @Input() refazerBusca: any;
  @Output() respostaCriaAtualiza = new EventEmitter;

  public tipoGrupoAtendimento: GrupoAtendimentoVO = new GrupoAtendimentoVO;
  public isLoading: boolean;

  public displayModalDeletarGrupoAtendimento: boolean;

  public idItem = '';
  public msgs: Message[] = [];

  constructor(private deletarGrupoAtendimentoService: DeletarGrupoAtendimentoService,
              public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
  }

  deletarGrupoAtendimento() {
    this.isLoading = true;
    this.deletarGrupoAtendimentoService.deletarGrupoAtendimento(this.idItem).subscribe(
      response => {
        const mensagem: any = response;
        if (!isNullOrUndefined(response) && mensagem.success) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogDeletarGrupoAtendimento();
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(mensagem.message);
          this.hideDialogDeletarGrupoAtendimento();
        }
      },
      erro => {
        this.serviceUtils.showError('Erro ao Tentar Excluir');
        this.hideDialogDeletarGrupoAtendimento();
        this.isLoading = false;
        console.log(erro);
      });
  }

  recuperarGrupoAtendimento(idItem: string) {
    this.isLoading = true;
    this.deletarGrupoAtendimentoService.recuperarGrupoAtendimento(idItem).subscribe(
      data => {
        this.tipoGrupoAtendimento = data;
        this.isLoading = false;

        setTimeout(() => {
          $('select').formSelect();
        }, 1);
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  showDialogDeletaGrupoAtendimento(idItem) {
    this.idItem = idItem;
    this.recuperarGrupoAtendimento(idItem);
    this.displayModalDeletarGrupoAtendimento = true;
  }

  hideDialogDeletarGrupoAtendimento() {
    this.idItem = '';
    this.displayModalDeletarGrupoAtendimento = false;
    this.respostaCriaAtualiza.emit(1);
  }

  hideDialogDeletar() {
    this.idItem = '';
    this.displayModalDeletarGrupoAtendimento = false;
  }

}
