import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// PrimeNg
import { Message } from 'primeng/components/common/api';
// VO
import { EnderecoPessoaVO } from './../../../../../base/vo/endereco-pessoa';
import { Utils } from './../../../../../base/util/utils';
import { DeletarEnderecoPessoaService } from './deletar-endereco-pessoa.service';

declare var M: any;

@Component({
  selector: 'app-deletar-endereco-pessoa',
  templateUrl: './deletar-endereco-pessoa.component.html',
  styleUrls: ['./deletar-endereco-pessoa.component.css'],
  providers: [DeletarEnderecoPessoaService]
})
export class DeletarEnderecoPessoaComponent implements OnInit {

  @Input() refazerBusca;
  @Output() respostaCriaAtualiza = new EventEmitter;
  @Output() respostaEnderecoDeleta = new EventEmitter();

  public EnderecoPessoa = new EnderecoPessoaVO;
  public isLoading: boolean;
  public displayModalDeletarEnderecoPessoa: boolean;
  public editEndereco: boolean;
  public confirmaDelete: boolean;
  public idItem: string = "";
  public msgs: Message[] = [];
  public listEnderecos: Array<any> = [];

  constructor(public deletarEnderecoPessoaService: DeletarEnderecoPessoaService, public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
  }

  showDialogDeletaEnderecoPessoa(Item) {
    this.EnderecoPessoa = Item;
    this.displayModalDeletarEnderecoPessoa = true;
  }

  hideDialogDeletarEnderecoPessoa() {
    this.idItem = "";
    this.displayModalDeletarEnderecoPessoa = false;
    this.respostaCriaAtualiza.emit(1);
  }

  confirmarDelete() {
    this.confirmaDelete = true;
    this.displayModalDeletarEnderecoPessoa = false;
    this.respostaEnderecoDeleta.emit(this.EnderecoPessoa);
    this.respostaCriaAtualiza.emit(1);
  }

  // todo : verificar necessidade de manter os dois métodos , fazendo a mesma coisa
  deletarEnderecoPessoa() {
    this.confirmaDelete = true;
    this.displayModalDeletarEnderecoPessoa = false;
    this.respostaEnderecoDeleta.emit(this.EnderecoPessoa);
    this.respostaCriaAtualiza.emit(1);
  }
}
