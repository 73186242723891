import { Component, OnInit, ViewChild } from '@angular/core';

// PrimeNg
import { Message } from 'primeng/components/common/api';

// Service
import { DetalhaGrupoAtendimentoService } from './detalha-grupo-atendimento.service';

@Component({
  selector: 'app-detalha-grupo-atendimento',
  templateUrl: './detalha-grupo-atendimento.component.html',
  styleUrls: ['./detalha-grupo-atendimento.component.css']
})
export class DetalhaGrupoAtendimentoComponent implements OnInit {

  @ViewChild('modalNovoGrupoAtenUsuario') modalNovoGrupoAtenUsuarioVC: any;
  
  public detalheItem: any;
  public isLoading: boolean;
  public visibleForm: boolean;
  public idItem = '';

  public msgs: Message[] = [];

  public displayModalDetalhaGrupoAtendimento: boolean;

  constructor(public detalhaGrupoAtendimentoService: DetalhaGrupoAtendimentoService) { }

  ngOnInit() {
  }

  recuperarDetalheGrupoAtendimento(idItem: string) {
    this.isLoading = true;
    this.detalhaGrupoAtendimentoService.recuperarGrupoAtendimento(idItem).subscribe(
      data => {
        if (this.displayModalDetalhaGrupoAtendimento) {
          setTimeout(this.modalNovoGrupoAtenUsuarioVC.center.bind(this.modalNovoGrupoAtenUsuarioVC), 0);
        }
        this.detalheItem = data;
        this.isLoading = false;
      },
      erro => {
        console.log(erro);
        this.isLoading = false;
      }
    );
  }

  showDialogDetalhaItem(id) {
    this.idItem = id;
    this.recuperarDetalheGrupoAtendimento(this.idItem);
    this.displayModalDetalhaGrupoAtendimento = true;
  }

  hideDialogDetalhaItem() {
    this.displayModalDetalhaGrupoAtendimento = false;
  }

}
