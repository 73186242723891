import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { EnderecoPessoaVO } from './../../../../../base/vo/endereco-pessoa';
import { PesquisaCidadesComponent } from '../../pesquisa-cidade/pesquisa-cidade.component';
import { Utils } from './../../../../../base/util/utils';
import { CidadeVO } from './../../../../../base/vo/cidade';
import { EstadoVO } from './../../../../../base/vo/estado';
import { NovoEnderecoPessoaService } from './novo-endereco-pessoa.service';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-novo-endereco-pessoa',
  templateUrl: './novo-endereco-pessoa.component.html',
  styleUrls: ['./novo-endereco-pessoa.component.css']
})
export class NovoEnderecoPessoaComponent implements OnInit {

  @Input() endereco: EnderecoPessoaVO;
  @Input() cidade: CidadeVO;
  @Input() estado: EstadoVO;
  @Output() respostaEnderecoCadastrado = new EventEmitter();
  @Output() respostaEnderecoAtualiza = new EventEmitter();

  @ViewChild('pesquisaCidades')
  public pesquisaCidadesComponent: PesquisaCidadesComponent;

  public idItem: any;
  public idCity: number;
  public visibleForm: boolean;
  public isLoading: boolean;
  public editEnderecoPessoa: boolean;
  public displayModalNovoEnderecoPessoa: boolean;
  public listaTipoEndereco: any;
  public listaEstados: any;
  public listaCidade: any;
  public Cidade: any;
  public Logradouro: string;
  public nomeCidade: any = '';
  public cep: string;
  public numero: string;
  public bairro: string;
  public complemento: string;
  public controleCidade: boolean = true;
  public tipoEndereco: any;
  public Estado: any;
  public isErro: any;
  public enderecoMemoria: boolean;
  public validaEventButton: boolean;
  public isCEPValido: boolean = true;
  public isTipoEnderecoValido: boolean = true;
  public isEstadoValido: boolean = true;
  public isCidadeValido: boolean = true;
  public isLogradouroValido: boolean = true;
  public isNumeroValido: boolean = true;
  public isBairroValido: boolean = true;
  public pesquisaCidadeRealizada: boolean;
  public nome: any = '';
  public listaConsultaCep: any;
  public loadingEndereco: boolean;
  public msgs: any;

  constructor(public novoEnderecoPessoaService: NovoEnderecoPessoaService, public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
    this.carregarTipoEndereco();
    this.carregarEstado();
    if (!this.idItem) {
    }
  }

  showForm() {
    setTimeout(() => {
      $('select').formSelect();
    }, 1000);

    this.visibleForm = true;

    setTimeout(function () {
      $('html, body').animate({
      }, 1000);
    }, 1);
  }

  showDialogNovoEnderecoPessoa(idItem) {
    this.limpaForm();
    this.displayModalNovoEnderecoPessoa = true;
    if (idItem) {
      this.endereco = new EnderecoPessoaVO;
      this.idItem = idItem;
      this.idCity = idItem.idCidade;
      this.endereco = this.idItem;
      this.editEnderecoPessoa = true;
      if (this.enderecoMemoria) {
      } else {
        this.carregarEndereco(idItem);
      }
    } if (!idItem) {
      this.idItem = '';
      this.editEnderecoPessoa = false;
      this.limpaForm();
    }
    this.carregarTipoEndereco();
    this.showForm();
    setTimeout(() => {
      $('select').formSelect();
    }, 1);

  }

  carregarEstado() {
    this.novoEnderecoPessoaService.recuperaEstados().subscribe(
      data => {
        this.listaEstados = data;
      },
      erro => {
        this.isErro = true;
        this.listaEstados = null;
      }
    );
  }

  carregarCidades(idEstado) {
    console.log(idEstado);
    this.novoEnderecoPessoaService.recuperarCidades(idEstado).subscribe(
      data => {
        this.listaCidade = data;
        this.controleCidade = false;
      },
      erro => {
        this.isErro = true;
        this.listaCidade = null;
      }
    );
    setTimeout(() => {
      $('select').formSelect();
    }, 800);
  }

  carregarTipoEndereco() {

    if (!this.editEnderecoPessoa) {
      this.novoEnderecoPessoaService.recuperaTipoEnderecoAtivo().subscribe(
        data => {
          this.listaTipoEndereco = data;
          this.carregaJQuery();
        },
        erro => {
          this.isErro = true;
          this.listaTipoEndereco = null;
        }
      );
    } else {
      this.novoEnderecoPessoaService.recuperaTipoEnderecoTodos().subscribe(
        data => {
          this.listaTipoEndereco = data;
        },
        erro => {
          this.isErro = true;
          this.listaTipoEndereco = null;
        }
      );
    }
  }

  atribui() {
    if (!this.editEnderecoPessoa) {
      this.cidade = this.Cidade;
      this.endereco.nomeCidade = this.nomeCidade;
    } else {
      this.endereco.nomeCidade = this.nomeCidade;
    }
    let i: number;
    for (i = 0; i < this.listaTipoEndereco.length; i++) {
      if (this.listaTipoEndereco[i].id == this.tipoEndereco) {
        this.endereco.nomeTipoEndereco = this.listaTipoEndereco[i].descricao;
      }

    }
    if (!isNullOrUndefined(this.Cidade)) {
      if (!isNullOrUndefined(this.Cidade.id)) {
        this.endereco.idCidade = this.Cidade.id;

      } else if (!isNullOrUndefined(this.idCity)) {
        this.endereco.idCidade = this.idCity;
      }
    } else if (!isNullOrUndefined(this.idCity)) {
      this.endereco.idCidade = this.idCity;
    }
    this.endereco.idTipoEndereco = this.tipoEndereco;
    this.endereco.logradouro = this.Logradouro;
    this.endereco.cep = this.cep;
    this.endereco.numero = this.numero;
    this.endereco.bairro = this.bairro;
    this.endereco.complemento = this.complemento;
  }

  enviarEndereco() {
    this.validaEventButton = true;
    if (this.validaDados()) {
      this.atribui();
      this.endereco.padrao = 2;
      this.respostaEnderecoCadastrado.emit(this.endereco);
      this.displayModalNovoEnderecoPessoa = false;
      this.endereco = new EnderecoPessoaVO();
    }
  }

  atualizarEndereco() {
    this.validaEventButton = true;
    if (this.validaDados()) {
      this.atribui();
      this.respostaEnderecoAtualiza.emit(this.endereco);
      this.displayModalNovoEnderecoPessoa = false;
      this.limpaForm();
      this.endereco = new EnderecoPessoaVO();
    }
  }

  hideDialogNovoEndereco() {
    this.displayModalNovoEnderecoPessoa = false;
    this.limpaForm();
    this.limpaPlaceHolder();
  }

  validaDados(): boolean {
    if (this.validaEventButton) {
      const isValido = new Array<any>();
      isValido.push(this.isCEPValido = !this.serviceUtils.isStringNullOrBlank(this.cep));
      isValido.push(this.isLogradouroValido = !this.serviceUtils.isStringNullOrBlank(this.Logradouro));
      isValido.push(this.isBairroValido = !this.serviceUtils.isStringNullOrBlank(this.bairro));
      isValido.push(this.isCidadeValido = !this.serviceUtils.isStringNullOrBlank(this.nomeCidade, true));
      isValido.push(this.isNumeroValido = !this.serviceUtils.isStringNullOrBlank(this.numero, true));
      isValido.push(this.isTipoEnderecoValido = !isNullOrUndefined(this.tipoEndereco));

      return !isValido.some(function isAnyFalse(element) { return (element === false); });
    }
  }

  limpaForm() {
    this.endereco = new EnderecoPessoaVO();
    this.cidade = new CidadeVO();
    this.estado = new EstadoVO();
    this.Cidade = null;
    this.tipoEndereco = null;
    this.Estado = null;
    this.Logradouro = '';
    this.cep = '';
    this.numero = '';
    this.bairro = '';
    this.complemento = '';
    this.isCEPValido = true;
    this.isTipoEnderecoValido = true;
    this.isEstadoValido = true;
    this.isCidadeValido = true;
    this.isLogradouroValido = true;
    this.isNumeroValido = true;
    this.isBairroValido = true;
    this.pesquisaCidadeRealizada = true;
    this.limpaPlaceHolder();
  }

  carregarEndereco(item) {
    if (this.editEnderecoPessoa) {
      this.cidade = new CidadeVO;
      this.pesquisaCidadeRealizada = true;
      this.nomeCidade = item.nomeCidade;
      this.cidade.id = item.idCidade;
    } else {
      this.nomeCidade = item.cidade.nome;
      this.Cidade.id = item.cidade.id;
    }
    this.tipoEndereco = item.idTipoEndereco;
    this.cep = item.cep;
    this.Logradouro = item.logradouro;
    this.bairro = item.bairro;
    this.numero = item.numero;
    this.complemento = item.complemento;
  }

  atriubuiValores(item) {
    this.Cidade = item;
    this.endereco.nomeEstado = item.nomeEstado;
    this.nomeCidade = this.Cidade.nome;
    this.pesquisaCidadeRealizada = true;
  }

  limpaPlaceHolder() {
    this.nomeCidade = '';
    this.pesquisaCidadeRealizada = false;
  }

  showDialogPesquisaCidade() {
    this.isCidadeValido = true;
    this.pesquisaCidadesComponent.showDialogPesquisaPessoa();
  }

  consultaCep(cep) {
    this.cep = cep;
    if (this.cep) {
      this.loadingEndereco = true;
      this.novoEnderecoPessoaService.consultaCep(this.cep).subscribe(
        data => {
          this.listaConsultaCep = data;
          this.Cidade = new CidadeVO;
          this.cidade.id = this.idCity;
          this.Estado = new EstadoVO;
          if (!isNullOrUndefined(this.listaConsultaCep)) {
            this.nomeCidade = this.listaConsultaCep.nomeCidade;
            this.Logradouro = this.listaConsultaCep.logradouro;
            this.bairro = this.listaConsultaCep.bairro;
            this.Cidade.id = this.listaConsultaCep.idCidade;
            this.endereco.nomeEstado = this.listaConsultaCep.nomeEstado;
          } else {
            this.msgs = this.serviceUtils.showInfo('Não foi possível consultar os dados de endereço. Por favor insira os dados manualmente.');
          }
          this.loadingEndereco = false;
        },
        erro => {
          this.loadingEndereco = false;
          this.isErro = true;
          this.listaConsultaCep = null;
          this.msgs = this.serviceUtils.showError('');
          console.log(erro);
        }
      );
    }
  }

  private carregaJQuery() {
    setTimeout(function () {
      M.AutoInit();
      $('.dropdown-trigger').dropdown({ "coverTrigger": false });
      $('select').formSelect();
    }, 1);
  }

}
