import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import Swal from 'sweetalert2';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { JwtHelper } from 'angular2-jwt';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/toPromise';
import { isNullOrUndefined } from 'util';
import { RequestOptionsArgs } from '@angular/http';
export class BaseService {
  private headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  constructor(public http: HttpClient) { }

  resp: string;

  getToken() {
    const jwtHelper: JwtHelper = new JwtHelper;
    let token = localStorage.getItem("jwt");
    if (isNullOrUndefined(token)) {
      token = "";
    }
    return new HttpHeaders().set('Token', token);
  }

  verificaToken() {

    const jwtHelper: JwtHelper = new JwtHelper;
    let token = localStorage.getItem("jwt");

    if (token && !jwtHelper.isTokenExpired(token)) {
      return true;
    }

    Swal.fire({
      title: 'Sua sessão expirou!',
      type: 'info',
      html:
        'Você pode fazer um novo login ' +
        'ou informar sua senha novamente.',
      input: 'password',
      footer: '<a href="/">Ir para a página de login</a>',
      allowOutsideClick: false,
      inputPlaceholder: 'Informe sua senha',
      allowEscapeKey: false,
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off'
      },
      confirmButtonText: 'Login',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {

        const email = JSON.parse(localStorage.getItem('maxilaborUser'));

        const credentials = { "email": email.email, "senha": login };

        this.http
          .post(environment.baseUrl + "login-token", JSON.stringify(credentials), {
            headers: new HttpHeaders({ "Content-Type": "application/json" })
          })
          .map((response: any) => response)
          .subscribe(response => {
            token = (<any>response).token;
            localStorage.setItem("jwt", token);

            Swal.close();
            Swal.fire({
              title: 'Bem vindo de volta!',
              type: 'success',
              confirmButtonText: 'Ok'
            });

          }, err => {
            Swal.showValidationMessage('Senha inválida!');
          });

        return false;

      },
    });
  }

  handleErrorLogin(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    if (errorMessage !== "") {
      Swal.showValidationMessage(errorMessage);
    }

    return throwError(errorMessage);
  }

  get(url: string) {

    if (!this.bypassTimeoutTokenSpecialAddress(url)) {
      if (!this.verificaToken()) {
        return null;
      } else {
        this.refreshToken();
      }
    }

    if (this.bypassConexaoSpecialAddress(url)) {
      return this.http.get(url).map((response: any) => response);
    }

    const myheader = this.getToken();

    const a = this.http.get(url, { headers: myheader , observe: 'response'} );
    const xBody = a.map((response: any) => response.body);
    const xHeader = a.map((response: any) => response.headers);

  return xBody;
  }

  getFile(url: string) {

    if (!this.bypassTimeoutTokenSpecialAddress(url)) {

      if (!this.verificaToken()) {
        return null;
      } else {
        this.refreshToken();
      }

    }

    const myheader = this.getToken();

    return this.http.get(url, { responseType: "blob", headers: myheader })
      .map((response: any) => {
        return response;
      })
      .pipe(
        catchError(this.handleError)
      );
  }

  post(url: string, body: any, headers?: HttpHeaders) {
    const header = headers || this.headers;
    
    if (!this.bypassTimeoutTokenSpecialAddress(url)) {

      if (!this.verificaToken()) {
        return null;
      } else {
        this.refreshToken();
      }

    }

    const myheader = this.getToken();
    
    return this.http.post(url, body, { headers: myheader })
      .map((response: any) => response)
      .pipe(
        catchError(this.handleError)
    );
  }

  put(url: string, body: any, headers?: HttpHeaders) {

    if (!this.bypassTimeoutTokenSpecialAddress(url)) {

      if (!this.verificaToken()) {
        return null;
      } else {
        this.refreshToken();
      }

    }

    const myheader = this.getToken();

    return this.http.put(url, body, { headers: myheader } )
      .map((response: any) => response)
      .pipe(
        catchError(this.handleError)
      );
  }

  delete(url: string, body: any, headers?: HttpHeaders) {

    if (!this.bypassTimeoutTokenSpecialAddress(url)) {

      if (!this.verificaToken()) {
        return null;
      } else {
        this.refreshToken();
      }

    }

    const myheader = this.getToken();

    return this.http.delete(url, { headers: myheader })
      .map((response: any) => response)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteArray(url: string, body: any, headers?: HttpHeaders){
    
    if (!this.bypassTimeoutTokenSpecialAddress(url)) {

      if (!this.verificaToken()) {
        return null;
      } else {
        this.refreshToken();
      }

    }

    const myheader = this.getToken();
    return this.http.request('DELETE', url, { headers: myheader, body: body }).map((response: any) => response)
    .pipe(
      catchError(this.handleError)
    );

  }

  bypassTimeoutTokenSpecialAddress(url: string) {
    if (url.endsWith("esqueci-senha")) { return true; }
    if (url.endsWith("trocarSenha")) { return true; }
  }

  bypassConexaoSpecialAddress(url: string) {
    const urlBalanca = this.recuperaUrlBalanca(url);
    if (!isNullOrUndefined(urlBalanca)) {
      if (environment.urlBalanca === urlBalanca) { return true; }
    }

    return false;
  }

  private recuperaUrlBalanca(url: string) {
    if (isNullOrUndefined(url)) {
      return null;
    } else {
      if (url.length > 0) {
        return this.montaUrlBalanca(url);
      } else {
        return null;
      }
    }
  }

  private montaUrlBalanca(url: string) {
    const splitedUrl = url.split('/');
    if (splitedUrl.length >= 3) {
      const urlBalanca = (splitedUrl[0].toString() + '//' + splitedUrl[2].toString() + '/');
      return urlBalanca;
    }
    return null;
  }

  handleError(error) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    if (error.status === 404 || error.status === 0) {
      Swal.fire({
        title: 'Falha de Conexão de Internet!',
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        allowEscapeKey: false,
        html:
          ' Por favor, verifique sua conexão (Erro ' + error.status + '). <br> ' +
          ' É necessário retornar a página inicial. ',
        // text: 'Por favor, verifique sua conexão (Erro ' + error.status + ')',
        type: 'error',
        footer: '<a href="/">Ir para a página de login</a>'
        // confirmButtonText: 'Ok'
      });
    } else {
      window.alert(errorMessage);
    }

    return throwError(errorMessage);
  }

  private                refreshToken() {
    const token = localStorage.getItem("jwt");
    this.http.get(environment.baseUrl + "refresh-token?tokenString=" + token.toString())
      .subscribe(
        response => {
          const novoToken = (<any>response).token;
          localStorage.setItem("jwt", novoToken);
        }, err => {
          console.log(err);
        }
      );
  }

}
