import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../base/util/utils';

// Vo
import { StatusSolicitacaoVO } from '../../../base/vo/status-solicitacao';
import { StatusSolicitacaoService } from './Status-solicitacao.service';

// Component
import { DeletarStatusSolicitacaoComponent } from './deletar-status-solicitacao/deletar-status-solicitacao.component';
import { DetalhaStatusSolicitacaoComponent } from './detalha-status-solicitacao/detalha-status-solicitacao.component';
import { NovoStatusSolicitacaoComponent } from './novo-status-solicitacao/novo-status-solicitacao.component';
import { CancelaStatusSolicitacaoComponent } from './cancela-status-solicitacao/cancela-status-solicitacao.component';

// PrimeNg
import { Message } from 'primeng/components/common/api';

interface FiltroStatusSolicitacao {
  Codigo: string;
  Descricao: string;
  status: number;

}

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-status-solicitacao',
  templateUrl: './status-solicitacao.component.html',
  styleUrls: ['./status-solicitacao.component.css']
})
export class StatusSolicitacaoComponent implements OnInit {

  @ViewChild('novoStatusSolicitacao')
  public novoStatusSolicitacao: NovoStatusSolicitacaoComponent;

  @ViewChild('detalhaStatusSolicitacao')
  public detalhaStatusSolicitacao: DetalhaStatusSolicitacaoComponent;

  @ViewChild('deletaStatusSolicitacao')
  public deletaStatusSolicitacao: DeletarStatusSolicitacaoComponent;

  @ViewChild('cancelaStatusSolicitacao')
  public cancelaStatusSolicitacao: CancelaStatusSolicitacaoComponent;

  @Input() refazerBusca: any;

  public visibleForm: boolean;
  public displayModal: boolean;

  public activeAutocompleteCodigo: boolean;
  public activeAutocompleteDescricao: boolean;

  public listaStatusSolicitacao: any;

  public isLoading: boolean;
  public numberOfRecords = 0;
  public resultFound: boolean;
  public resultNotFound: boolean;
  public actualpage = 0;
  public salvaPagina = 0;
  public msgs: Message[] = [];
  public listarStatus: any;

  public Codigo = '';
  public Descricao = '';
  public status: number;
  public itemAtualizado: boolean;

  public tipoStatusSolicitacao: StatusSolicitacaoVO;
  public filtros: FiltroStatusSolicitacao;
  public listaCodigos: any;
  public listaDescricao: any;

  public idIStatusItemCancela: number;
  public isItensBuscados: boolean;

  readonly STATUS_SOLICITACAO_CODIGO_NOVA = "001";
  readonly STATUS_SOLICITACAO_CODIGO_CANCELADA = "002";
  readonly STATUS_SOLICITACAO_CODIGO_FINALIZADA = "003";

  constructor(private statusSolicitacaoService: StatusSolicitacaoService, public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
    this.carregaJQuery();
    this.recuperaStatus();
    this.tipoStatusSolicitacao = new StatusSolicitacaoVO;
    this.filtros = {} as FiltroStatusSolicitacao;
  }

  buscarStatusSolicitacao() {
    this.isItensBuscados = true;
    this.populaFiltros();
    this.recuperaStatusSolicitacao(1);
  }

  buscarCodigo(event) {
    this.statusSolicitacaoService.recuperarCodigo(event.query).subscribe(
      data => {
        this.listaCodigos = data.map(function (el) {
          return el.codigo;
        }).filter(this.serviceUtils.onlyUnique);
      },
      erro => {
        console.log(erro);
      }
    );
  }

  buscarDescricao(event) {
    this.statusSolicitacaoService.recuperarDescricoes(event.query).subscribe(
      data => {
        this.listaDescricao = data.map(element => element.descricao).filter(this.serviceUtils.onlyUnique);
      },
      erro => {
        console.log(erro);
      }
    );
  }

  refazBusca() {
    if (this.refazBusca && this.isItensBuscados) {
      this.buscarStatusSolicitacao();
    }
  }

  public recuperaStatusSolicitacao(page: number) {
    if (page <= 10) {
      this.actualpage = 1;
    }

    this.isLoading = true;
    this.statusSolicitacaoService.recuperarStatusSolicitacao(this.filtros, page).subscribe(
      data => {
        this.listaStatusSolicitacao = data;
        if (!isNullOrUndefined(this.listaStatusSolicitacao) && this.listaStatusSolicitacao.length > 0) {
          this.numberOfRecords = this.listaStatusSolicitacao[0].numberOfRows;
          this.resultFound = true;
          this.resultNotFound = false;
          this.isLoading = false;
          this.salvaPagina = page;
        } else {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
        }
      },
      erro => {
        this.resultFound = false;
        this.resultNotFound = true;
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  paginate(event) {
    this.recuperaStatusSolicitacao(event.page + 1);
    this.actualpage = event.first;
  }

  populaFiltros() {
    this.filtros.Codigo = this.Codigo;
    this.filtros.Descricao = this.Descricao;
    this.filtros.status = this.status;
  }

  limpaCodigo() {
    this.Codigo = null;
  }

  limpaDescricao() {
    this.Descricao = null;
  }

  hideDialog() {
    this.idIStatusItemCancela = null;
    this.displayModal = false;
  }

  showDialogNovoItem(idItem) {
    this.novoStatusSolicitacao.showDialogNovoItem(idItem);
  }

  showDialogDetalhaItem(idItem) {
    this.detalhaStatusSolicitacao.showDialogDetalhaItem(idItem);
  }

  showDialogDeletarItem(idItem) {
    this.deletaStatusSolicitacao.showDialogDeletaStatusSolicitacao(idItem);
  }

  showDialogStatusItem(idItem) {
    this.cancelaStatusSolicitacao.showDialogStatusItem(idItem);
  }

  recuperaStatus() {
    this.statusSolicitacaoService.listaStatus().subscribe(
      data => {
        this.listarStatus = data;
        this.carregaJQuery();
      },
      erro => {
        console.log(erro);
      }
    );
  }
  
  permiteEdicao(statusSolicitacao: any): boolean {
    if (
        statusSolicitacao.codigo === this.STATUS_SOLICITACAO_CODIGO_NOVA
        || statusSolicitacao.codigo === this.STATUS_SOLICITACAO_CODIGO_CANCELADA
        || statusSolicitacao.codigo === this.STATUS_SOLICITACAO_CODIGO_FINALIZADA
      ) {
        return false;
    } else {
      return true;
    }
    
  }

  private carregaJQuery() {
    setTimeout(function () {
      M.AutoInit();
      $('.dropdown-trigger').dropdown({ "coverTrigger": false });
      $('select').formSelect();
    }, 2);
  }
}
