import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { BaseService } from '../../../base/util/base.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class TipoSolicitacaoService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    recuperarTipoSolicitacao(filtros: any, page: number) {
        let urlDestino = environment.baseUrl + 'tipo-solicitacao';
        urlDestino += this.parametrosUrl(filtros, page, 10);
        return this.get(urlDestino);
    }

    parametrosUrl(filtros: any, page: number, pageSize: number): string {

        let url = '?page=' + page + '&pageSize=' + pageSize;

        if (!isNullOrUndefined(filtros.Codigo)) {
            url = url + '&codigo=' + filtros.Codigo;
        }
        if (!isNullOrUndefined(filtros.Descricao)) {
            url = url + '&Descricao=' + filtros.Descricao;
        }
        if (!isNullOrUndefined(filtros.status)) {
            url = url + '&Status=' + filtros.status;
        }


        return url;
    }

    recuperarCodigo(codigo: string) {
        let urlDestino = environment.baseUrl + 'tipo-solicitacao?page=1&pageSize=5';
        let nomeRamo: string;
        if (!isNullOrUndefined(codigo)) {
            nomeRamo = '&codigo=' + codigo;
            urlDestino += nomeRamo;
        }
        return this.get(urlDestino);
    }

    recuperarDescricoes(descricao: string) {
        let urlDestino = environment.baseUrl + 'tipo-solicitacao?page=1&pageSize=5';
        let descricaoRamo: string;
        if (!isNullOrUndefined(descricao)) {
            descricaoRamo = '&descricao=' + descricao;
            urlDestino += descricaoRamo;
        }
        return this.get(urlDestino);
    }

    cancelaTipoSolicitacao(TipoSolicitacao) {
        const urlDestino = environment.baseUrl + 'tipo-solicitacao';
        return this.put(urlDestino, TipoSolicitacao);
    }

    recuperarRelatorioExcel(filtros: any, pageSize: number) {
        let urlDestino = environment.baseUrl + 'tipo-solicitacao/excel';
        urlDestino += this.parametrosUrl(filtros, 1, pageSize);
        return this.getFile(urlDestino);
    }

    listaStatus() {
        const urlDestino = environment.baseUrl + 'enum/tipo-solicitacao/status';
        return this.get(urlDestino);
    }
}
