import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../../../base/util/base.service';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class PesquisaCidadesService extends BaseService {

  constructor(public http: HttpClient) {
    super(http);
  }

  recuperarContatoPessoa(filtros: any,  page) {
    let urlDestino = environment.baseUrl + 'Cidades';
    urlDestino += this.parametrosUrl(filtros,  page, 10);
    return this.get(urlDestino);
  }
  parametrosUrl(filtros: any, page, pageSize): string {

    let url = '?page=' + page + '&pageSize=' + pageSize;

    if (!isNullOrUndefined(filtros.nome)) {
      url = url + '&nome=' + filtros.nome;
    }
    if (!isNullOrUndefined(filtros.idEstado)) {
      url = url + '&idEstado=' + filtros.idEstado;
    }

    return url;
  }


  recuperarNome(cod: string) {
    let urlDestino = environment.baseUrl + 'Cidades?page=1&pageSize=5';
    let codItem: string;
    if (!isNullOrUndefined(cod)) {
        codItem = '&nome=' + cod;
        urlDestino += codItem;
    }
    return this.get(urlDestino);
  }

  recuperaEstado() {
    return this.get(environment.baseUrl + "estados");
  }

  recuperaEstadoByID(id) {
    const urlDestino = environment.baseUrl + '/estados/' + id;
    return this.get(urlDestino);
  }
}
