import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../base/util/base.service';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
@Injectable()
export class NovoStatusSolicitacaoService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  enviarStatusSolicitacao(statusSolicitacao) {
    return this.post(environment.baseUrl + 'status-solicitacao', statusSolicitacao);
  }

  recuperarStatusSolicitacao(id: string) {
    let urlDestino = environment.baseUrl + 'status-solicitacao';
    let idItem: string;
    if (!isNullOrUndefined(id)) {
        idItem = '/' + id;
        urlDestino += idItem;
    }
    return this.get(urlDestino);
}

  atualizarStatusSolicitacao(statusSolicitacao) {
    const urlDestino = environment.baseUrl + 'status-solicitacao';
    return this.put(urlDestino, statusSolicitacao);
  }

}
