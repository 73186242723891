import { SolicitacaoInteracaoVO } from './solicitacao-interacao';

export class SolicitacaoVO {
    idPessoa: number;
    idAmostra: number;
    solicitacaoInteracao = new SolicitacaoInteracaoVO();
    id: number;
    protocolo: string;
    idStatusSolicitacao: number;
    idTipoSolicitacao: number;
    idOrigemSolicitacao: number;
    idSolicitacaoWorkflow: number;
    idSolicitacaoWorkflowEtapa: number;
}
/*
class SolicitacaoInteracao {
    id: number;
    idSolicitacao: number;
    idSolicitacaoWorkflowEtapa: number;
    texto: string;
    listaDeArquivos = new Array<ArquivoVO>();
}
*/
