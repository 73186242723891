import { trigger, transition, animate, style } from '@angular/animations';
import { Router, NavigationStart } from '@angular/router';
import { Component, OnInit } from '@angular/core';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
  animations: [
    trigger('animateStatus', [
      transition(':enter', [
        style({ 'margin-left': '-100%' }),
        animate(300)
      ]),
      transition(':leave', [
        animate(300, style({ 'margin-left': '-100%' }))
      ]),
      // state('*', style({ 'position': 'absolute' })),
    ])
  ]
})
export class SidenavComponent implements OnInit {

  public menuState: string = '';

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if ($('.sidenav').length > 0) {
          M.Sidenav.getInstance($('.sidenav')).close();
          this.menuState = '';
        }
      }
    });
  }

  ngOnInit() {
    $('.sidenav').sidenav();
  }

  changeMenu(curMenuState: string) {
    this.menuState = curMenuState;
  }

}
