import { Component, OnInit, ViewChild } from '@angular/core';
import { isNullOrUndefined } from 'util';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-help-service',
  templateUrl: './help-service.component.html',
  styleUrls: ['./help-service.component.css']
})
export class HelpServiceComponent implements OnInit {
  public visibleScreen: string = 'minhasSolicitacoes';
  public solicitante: boolean;
  public tipoAtendimento: number = 0;
  public menuSolicitacoes: boolean;
  public displayModalNovaSolicitacao: boolean;
  public buscou: boolean;
  public statusSolicitacao: any;
  public protocolo: any;
  public documento: any;
  public areaDestino: any;
  public protReferencia: any;

  constructor() { }

  ngOnInit() {
    this.openScreen('minhasSolicitacoes');

    M.AutoInit();
    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }

  openSolicitacoes() {
    this.menuSolicitacoes = true;
  }

  showDialogNovaSolicitacao() {
    this.displayModalNovaSolicitacao = true;
  }

  buscaSolicitante() {
    this.buscou = true;
  }

  openScreen(menu: string) {
    this.visibleScreen = menu;
  }

}
