import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(public router: Router) {
    router.events.subscribe((val) => {
      this.rota = window.location.href.split("?")[0].split("/").pop();
      this.rota = "/" + this.rota;
    });
   }

  public rota: string;

  ngOnInit() {

    if (this.detectIE()) {
      this.router.navigate(["/unsupported"]);
    }
    this.rota = window.location.href.split("?")[0].split("/").pop();
    this.rota = "/" + this.rota;
  }

  private detectIE() {
    let ua = window.navigator.userAgent;

    // IE 10 or older
    let msie = ua.indexOf('MSIE ');
    if (ua.indexOf('MSIE ') > 0) {
      if (parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) <= 10) {
        return true;
      }
    }

    // IE 11
    let trident = ua.indexOf('Trident/');
    if (trident > 0) {
      let rv = ua.indexOf('rv:');
      if (parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) === 11) {
        return true;
      }
    }

    // Safari
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') == -1) {
        return true;
      }
    }

    // other browser
    return false;
  }
}
