import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'mascaraCpf'
})
export class CpfPipe implements PipeTransform{
   cpfFormatado: string;

    transform(cpf: string){
        if(cpf){            
            if(cpf.length === 11){
               this.cpfFormatado = cpf.substring(0,3).concat(".").concat(cpf.substring(3,6))                                      
                                      .concat(".").concat(cpf.substring(6,9)).concat("-")
                                      .concat(cpf.substring(9,11))
            } 
        }

        return this.cpfFormatado;
    }
}