import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../../base/util/utils';

// Service
import { StatusOrigemSolicitacaoService } from './status-origem-solicitacao.service';

// VO
import { OrigemSolicitacaoVO } from '../../../../base/vo/origem-solicitacao';

// PrimeNg
import { Message } from 'primeng/components/common/api';

declare var M: any;

@Component({
  selector: 'app-status-origem-solicitacao',
  templateUrl: './status-origem-solicitacao.component.html',
  styleUrls: ['./status-origem-solicitacao.component.css']
})
export class StatusOrigemSolicitacaoComponent implements OnInit {

  @Input() refazerBusca: any;
  @Output() respostaCriaAtualiza = new EventEmitter;
  @Output() MessageDialog = new EventEmitter();

  public tipoOrigemSolicitacao: OrigemSolicitacaoVO;

  public isLoading: boolean;
  public displayModalStatusOrigemSolicitacao: boolean;

  public idItem = '';
  public msgs: Message[] = [];

  constructor(private statusOrigemSolicitacaoService: StatusOrigemSolicitacaoService,
              public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
    this.tipoOrigemSolicitacao = new OrigemSolicitacaoVO();
  }

  inativarItem() {
    this.tipoOrigemSolicitacao.status = 1;
    this.isLoading = true;
    this.statusOrigemSolicitacaoService.inativaOrigemSolicitacao(this.tipoOrigemSolicitacao).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogStatusItem();
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(response.message);
        }
        this.isLoading = false;
      },
      error => {
        this.msgs = this.serviceUtils.showError('Erro ao tentar inativar');
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  ativarItem() {
    this.tipoOrigemSolicitacao.status = 2;
    this.isLoading = true;
    this.statusOrigemSolicitacaoService.reativaOrigemSolicitacao(this.tipoOrigemSolicitacao).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogStatusItem();
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(response.message);
        }
        this.isLoading = false;
      },
      error => {
        this.msgs = this.serviceUtils.showError('Erro ao tentar ativar');
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  recuperarOrigemSolicitacao(idItem: string) {
    this.isLoading = true;
    this.statusOrigemSolicitacaoService.recuperarOrigemSolicitacao(idItem).subscribe(
      data => {
        this.tipoOrigemSolicitacao = data;
        this.isLoading = false;
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      });
  }

  showDialogStatusItem(idItem) {
    this.idItem = idItem;
    this.recuperarOrigemSolicitacao(idItem);
    this.displayModalStatusOrigemSolicitacao = true;
  }

  hideDialogStatusItem() {
    this.idItem = '';
    this.displayModalStatusOrigemSolicitacao = false;
    this.isLoading = false;
    this.respostaCriaAtualiza.emit(1);
  }
}
