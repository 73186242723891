import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { ContatoPessoaVO } from '../../../../../base/vo/contato-pessoa';
import { Utils } from '../../../../../base/util/utils';
import { NovoContatoService } from './novo-contato-component.service';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-novo-contato-component',
  templateUrl: './novo-contato-component.component.html',
  styleUrls: ['./novo-contato-component.component.css']
})
export class NovoContatoComponent implements OnInit {

  @Input() contato: ContatoPessoaVO;

  @Output() respostaContatoCadastrado = new EventEmitter();
  @Output() respostaContatoAtualiza = new EventEmitter();

  public idItem: any;

  public visibleForm: boolean;
  public isLoading: boolean;
  public editContatoPessoa: boolean;
  public displayModalNovoContatoPessoa: boolean;
  public listaTipoContato: any;
  public telefoneCelular: string;
  public nomeCidade: any = '';
  public telefoneFixo: string;
  public observacao: string;
  public email: string;
  public telefoneFax: string;
  public tipoContato: any;
  public isErro: any;
  public contatoMemoria: boolean;
  public validaEventButton: boolean;

  public isTipoContatoValido: boolean = true;
  public isNomeValido: boolean = true;
  public isTelefoneInvalido: boolean = true;
  public isTelefoneFixoValido: boolean = true;
  public isTelefoneFaxValido: boolean = true;
  public isTelefoneCelularValido: boolean = true;
  public isEmailValido: boolean = true;

  public nome: any = '';
  public listaConsultaCep: any;
  public loadingContato: boolean;
  public msgs: any;

  constructor(public novoContatoPessoaService: NovoContatoService, public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
  }

  showForm() {
    setTimeout(() => {
      $('select').formSelect();
    }, 1000);

    this.visibleForm = true;

    setTimeout(function () {
      $('html, body').animate({
      }, 1000);
    }, 1);
  }

  showDialogNovoContatoPessoa(idItem) {
    this.limpaForm();
    this.displayModalNovoContatoPessoa = true;
    if (idItem) {
      this.contato = new ContatoPessoaVO;
      this.idItem = idItem;

      this.contato = this.idItem;
      this.editContatoPessoa = true;
      if (this.contatoMemoria) {
      } else {
        this.carregarContato(idItem);
      }
    } if (!idItem) {
      this.idItem = '';
      this.editContatoPessoa = false;
      this.limpaForm();
    }
    this.carregarTipoContato();
    this.showForm();
    setTimeout(() => {
      $('select').formSelect();
    }, 1);

  }

  carregarTipoContato() {
    if (!this.editContatoPessoa) {
      this.novoContatoPessoaService.recuperaTipoContatoAtivo().subscribe(
        data => {
          this.listaTipoContato = data;
          this.carregaJQuery();
        },
        erro => {
          this.isErro = true;
          this.listaTipoContato = null;
        }
      );
    } else {
      this.novoContatoPessoaService.recuperaTipoContatoTodos().subscribe(
        data => {
          this.listaTipoContato = data;
        },
        erro => {
          this.isErro = true;
          this.listaTipoContato = null;
        }
      );
    }
  }

  atribui() {
    let i: number;
    for (i = 0; i < this.listaTipoContato.length; i++) {
      if (this.listaTipoContato[i].id == this.tipoContato) {
        this.contato.descricaoTipoContato = this.listaTipoContato[i].descricao;
      }

    }
    this.contato.nome = this.nome;
    this.contato.idTipoContato = this.tipoContato;
    this.contato.telefoneCelular = this.telefoneCelular;
    this.contato.telefoneFixo = this.telefoneFixo;
    this.contato.telefoneFax = this.telefoneFax;
    this.contato.observacao = this.observacao;
    this.contato.email = this.email;
  }

  enviarContato() {
    this.validaEventButton = true;
    if (this.validaDados()) {
      this.atribui();
      this.respostaContatoCadastrado.emit(this.contato);
      this.displayModalNovoContatoPessoa = false;
      this.contato = new ContatoPessoaVO();
    }
  }

  atualizarContato() {
    this.validaEventButton = true;
    if (this.validaDados()) {
      this.atribui();
      this.respostaContatoAtualiza.emit(this.contato);
      this.displayModalNovoContatoPessoa = false;
      this.limpaForm();
      this.contato = new ContatoPessoaVO();
    }
  }

  hideDialogNovoContato() {
    this.displayModalNovoContatoPessoa = false;
    this.limpaForm();
  }


  validaDados(): boolean {
    if (this.validaEventButton) {
      const isValido = new Array<any>();
      if (!this.editContatoPessoa) {

        isValido.push(this.isNomeValido = !this.serviceUtils.isStringNullOrBlank(this.nome, true));
        isValido.push(this.isTipoContatoValido = !isNullOrUndefined(this.tipoContato));

        if (!this.serviceUtils.isStringNullOrBlank(this.email)) {
          isValido.push(this.isEmailValido = this.serviceUtils.validaEmails(this.email));
        }

        isValido.push(this.isTelefoneInvalido = !this.serviceUtils.isStringNullOrBlank(this.telefoneFixo, true) || !this.serviceUtils.isStringNullOrBlank(this.telefoneFax, true) || !this.serviceUtils.isStringNullOrBlank(this.telefoneCelular, true));

        if (this.isTelefoneInvalido) {
          if (this.telefoneFixo) {
            isValido.push(this.isTelefoneFixoValido = this.telefoneFixo.length === 10);
          }
          if (this.telefoneCelular) {
            isValido.push(this.isTelefoneCelularValido = this.telefoneCelular.length === 11);
          }
          if (this.telefoneFax) {
            isValido.push(this.isTelefoneFaxValido = this.telefoneFax.length === 10);
          }
        }
        if (!this.isTelefoneInvalido) {
          this.telefoneFixo = '';
        }
        if (!this.isTelefoneInvalido) {
          this.telefoneFax = '';
        }
        if (!this.isTelefoneInvalido) {
          this.telefoneCelular = '';
        }
        return !isValido.some(function isAnyFalse(element) { return (element === false); });
      } else {
        isValido.push(this.isNomeValido = !this.serviceUtils.isStringNullOrBlank(this.nome, true));
        if (!this.serviceUtils.isStringNullOrBlank(this.email)) {
          isValido.push(this.isEmailValido = this.serviceUtils.validaEmails(this.email));
        }

        isValido.push(this.isTelefoneInvalido = !this.serviceUtils.isStringNullOrBlank(this.telefoneFixo, true) || !this.serviceUtils.isStringNullOrBlank(this.telefoneFax, true) || !this.serviceUtils.isStringNullOrBlank(this.telefoneCelular, true));
        isValido.push(this.isTipoContatoValido = !isNullOrUndefined(this.tipoContato));

        if (this.isTelefoneInvalido) {
          if (this.telefoneFixo) {
            isValido.push(this.isTelefoneFixoValido = this.telefoneFixo.length === 10);
          }
          if (this.telefoneCelular) {
            isValido.push(this.isTelefoneCelularValido = this.telefoneCelular.length === 11);
          }
          if (this.telefoneFax) {
            isValido.push(this.isTelefoneFaxValido = this.telefoneFax.length === 10);
          }
        }

        if (!this.isTelefoneInvalido) {
          this.telefoneFixo = '';
        }
        if (!this.isTelefoneInvalido) {
          this.telefoneFax = '';
        }
        if (!this.isTelefoneInvalido) {
          this.telefoneCelular = '';
        }
        if (!this.nome) {
          this.nome= '';
        }
        return !isValido.some(function isAnyFalse(element) { return (element === false); });
    }
  }
  }
  
  limpaForm()
  {
    this.contato = new ContatoPessoaVO();
    this.tipoContato = null;
    this.telefoneCelular = '';
    this.telefoneFixo = '';
    this.telefoneFax = '';
    this.observacao = '';
    this.email = '';
    this.nome = '';
    this.isTipoContatoValido = true;
    this.isNomeValido = true;
    this.isTelefoneInvalido = true;
    this.isTelefoneCelularValido = true;
    this.isTelefoneFaxValido = true;
    this.isTelefoneFixoValido = true;
    this.isEmailValido = true;
  }

  carregarContato(item) {
    this.tipoContato = item.idTipoContato;
    this.telefoneFixo = item.telefoneFixo;
    this.telefoneFax = item.telefoneFax;
    this.telefoneCelular = item.telefoneCelular;
    this.nome = item.nome;
    this.email = item.email;
    this.observacao = item.observacao;
  }

  private carregaJQuery() {
    setTimeout(function () {
      M.AutoInit();
      $('.dropdown-trigger').dropdown({ "coverTrigger": false });
      $('select').formSelect();
    }, 1);
  }
  
}
