export class DadosTipoPessoaVO {
    constructor(
        public idPessoa?: number,

        public nome?: string,
        public tipoDocumento?: number,
        public tipoPessoa?: number,

        public CPF?: string,
        public RNE?: string,
        public RG?: string,
        public orgaoExpedidor?: string,
        public dataEmissao?: Date,

        public nomeFantasia?: string,
        public inscricaoMunicipal?: string,
        public inscricaoEstadual?: string,
        public CNPJ?: string,
        public emailFiscal?: string,
        public doadorAnonimo?: boolean
    ) {}
}
