import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { EnumService } from './../../../../base/enum/enum.service';
import { Utils } from './../../../../base/util/utils';
import { PesquisaCidadesService } from './pesquisa-cidade.service';

interface FiltrosPesquisaCidade {
  nome: string;
  idEstado: number;
  nomeEstado: string;
}

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-pesquisa-cidade',
  templateUrl: './pesquisa-cidade.component.html',
  styleUrls: ['./pesquisa-cidade.component.css'],
  providers: [PesquisaCidadesService]
})
export class PesquisaCidadesComponent implements OnInit {

  @ViewChild('input')
  public input: any;

  public idEstado: number = 0;
  public nome: any = '';
  public nomeEstado: any;
  public listaEstado: any;
  public listaCidades: any;
  public listaNomes: any;
  public todos: any = [{ valor: 0, nome: "Todos" }];
  public salvaPagina: number = 0;
  public numberOfRecords: number = 0;
  public actualpage: number = 0;
  public filtros: FiltrosPesquisaCidade;
  public displayModalPesquisaCidade: boolean;
  public resultFound: boolean;
  public resultNotFound: boolean;
  public isLoading: boolean = true;
  public isLoadingSpinner: boolean = true;

  constructor(private pesquisaCidadesService: PesquisaCidadesService, private enumService: EnumService, public serviceUtils: Utils) { }
  @Output() respostaComponent: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    M.AutoInit();
    this.populaEstado();
    this.filtros = {} as FiltrosPesquisaCidade;
  }

  buscaCidade() {
    this.populaFiltros();
    this.pesquisaCidades(1);
  }

  populaFiltros() {
    this.filtros.nome = this.nome;
    this.filtros.idEstado = this.idEstado;
    this.filtros.nomeEstado = this.nomeEstado;
  }

  itemSelecionado(item) {
    this.respostaComponent.emit(item);
    this.displayModalPesquisaCidade = false;
    this.limpaForm();

  }

  atribuiModel(event) {
    this.nome = this.input.nativeElement.value;
    // Função que corrige problema com o autocomplete do google chrome.
  }

  populaEstado() {
    this.pesquisaCidadesService.recuperaEstado().subscribe(
      data => {
        this.listaEstado = data;
      }
    );
  }

  paginate(event) {
    this.pesquisaCidades(event.page + 1);
    this.actualpage = event.first;
  }

  public pesquisaCidades(page: number) {
    if (page <= 10) {
      this.actualpage = 1;
    }
    this.isLoading = true;
    this.pesquisaCidadesService.recuperarContatoPessoa(this.filtros, page).subscribe(
      data => {
        this.listaCidades = data;
        if (!isNullOrUndefined(this.listaCidades) && this.listaCidades.length > 0) {
          this.numberOfRecords = this.listaCidades[0].numberOfRows;
          this.resultFound = true;
          this.resultNotFound = false;
          this.isLoading = false;
        } else {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
        }
      },
      erro => {
        this.resultFound = false;
        this.resultNotFound = true;
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  buscarNome(event) {
    this.pesquisaCidadesService.recuperarNome(event).subscribe(
      data =>
        this.listaNomes = data
    );
  }

  limpaForm() {
    this.nome = '';
    this.idEstado = 0;
    this.listaCidades = null;
    this.resultFound = false;
    this.resultNotFound = false;
  }

  showDialogPesquisaPessoa() {
    this.limpaForm();
    this.filtros.idEstado = this.idEstado;
    this.isLoading = false;
    this.isLoadingSpinner = false;
    setTimeout(() => {
      $('select').formSelect();
    }, 10);

    this.displayModalPesquisaCidade = true;
  }
}

