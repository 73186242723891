import { Component, OnInit } from '@angular/core';

// PrimeNg
import { Message } from 'primeng/components/common/api';

// Service
import { DetalhaTipoSolicitacaoService } from './detalha-tipo-solicitacao.service';

@Component({
  selector: 'app-detalha-tipo-solicitacao',
  templateUrl: './detalha-tipo-solicitacao.component.html',
  styleUrls: ['./detalha-tipo-solicitacao.component.css']
})
export class DetalhaTipoSolicitacaoComponent implements OnInit {

  public detalheItem: any;
  public isLoading: boolean;
  public visibleForm: boolean;
  public idItem = '';

  public msgs: Message[] = [];

  public displayModalDetalhaTipoSolicitacao: boolean;

  constructor(public detalhaTipoSolicitacaoService: DetalhaTipoSolicitacaoService) { }

  ngOnInit() {
  }

  recuperarDetalheTipoSolicitacao(idItem: string) {
    this.isLoading = true;
    this.detalhaTipoSolicitacaoService.recuperarTipoSolicitacao(idItem).subscribe(
      data => {
        this.detalheItem = data;
        this.isLoading = false;
      },
      erro => {
        console.log(erro);
        this.isLoading = false;
      }
    );
  }

  showDialogDetalhaItem(id) {
    this.idItem = id;
    this.recuperarDetalheTipoSolicitacao(this.idItem);
    this.displayModalDetalhaTipoSolicitacao = true;
  }

  hideDialogDetalhaItem() {
    this.displayModalDetalhaTipoSolicitacao = false;
  }

}
