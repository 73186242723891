import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { EnumService } from './../../../../base/enum/enum.service';
import { Utils } from './../../../../base/util/utils';
import { PesquisaPessoasService } from './pesquisa-pessoas.service';

interface FiltrosPesquisaPessoa {
  documento: string;
  nome: string;
  tipoDocumento: number;
  tipoPessoa: string;
  idUnidadeColeta: number;
}

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-pesquisa-pessoas',
  templateUrl: './pesquisa-pessoas.component.html',
  styleUrls: ['./pesquisa-pessoas.component.css'],
  providers: [PesquisaPessoasService]
})
export class PesquisaPessoasComponent implements OnInit {

  public tipoDocumento: number;
  public tipoPessoa: string;
  public documento: string;
  public nome: string = '';
  public nomeCampo: string = 'Nome / Razão Social';
  public idUnidadeColeta: number;
  public ehCliente: boolean;
  public ehDoador: boolean;
  public ehColetor: boolean;
  public ehTestemunha: boolean;
  public ehContrato: boolean;
  public ehVendedor: boolean;
  public ehUnidadeColeta: boolean;
  public ehUnidadeColetaComContrato: boolean;
  public ehClienteComContrato: boolean;
  public ehFornecedor: boolean;
  public ehFornecedorComContrato: boolean;
  public listaTipoDocumento: any;
  public listaTipoDocumentoFisica: any;
  public listaTipoDocumentoJuridica: any;
  public listaDocumentoTodos: any;
  public listaTipoPessoa: any;
  public listaTipoPessoaFisica: any;
  public listaPessoas: any;
  public listaNomes: any;
  public salvaPagina: number = 0;
  public numberOfRecords: number = 0;
  public actualpage: number = 0;
  public filtros: FiltrosPesquisaPessoa;
  public displayModalPesquisaPessoa: boolean;
  public resultFound: boolean;
  public resultNotFound: boolean;
  public isLoading: boolean = true;
  public isLoadingSpinner: boolean = true;
  public pessoaSelecionada: any;
  public checked: boolean = false;

  // veriavel de controle para trazer ativo
  public isActive: boolean;

  public desTipoPessoa: boolean = false;
  public desTipoDocumento: boolean = false;

  constructor(private pesquisaPessoasService: PesquisaPessoasService, private enumService: EnumService, public serviceUtils: Utils) { }

  @Output() respostaComponent: EventEmitter<any> = new EventEmitter();
  @Input() pessoaFisica: boolean;

  ngOnInit() {
    M.AutoInit();
    this.tipoDocumento = null;
    this.tipoPessoa = null;
    this.tipoPessoa = null;
    this.populaTipoPessoaEnum();
    this.populaTipoDocumentoEnum();
    this.filtros = {} as FiltrosPesquisaPessoa;
    this.serviceUtils.carregaJQuery();
  }

  verificaPessoaFisica() {
    if (!this.pessoaFisica) {
      this.tipoPessoa = '1';
    } else if (this.ehContrato) {
      this.tipoPessoa = '1';
      this.tipoDocumento = 1;
    } else {
      this.tipoPessoa = '2';
      this.removePessoaJuridica();
    }
    this.verificaTipoPessoa();
  }

  buscaPessoa() {
    this.populaFiltros();
    this.pesquisaPessoa(1);
  }

  hideDialog() {
    this.displayModalPesquisaPessoa = false;
    this.limpaForm();
    this.pessoaSelecionada = null;
  }

  populaFiltros() {
    this.filtros.nome = this.nome;
    this.filtros.documento = this.documento;
    this.filtros.tipoDocumento = this.tipoDocumento;
    this.filtros.tipoPessoa = this.tipoPessoa;
    if (this.checked) {
      this.filtros.idUnidadeColeta = this.idUnidadeColeta;
    } else {
      this.filtros.idUnidadeColeta = undefined;
    }
  }

  verificaTipoPessoa() {
    this.documento = '';
    this.nome = '';
    // this.tipoDocumento = null;
    switch (this.tipoPessoa) {
      case '2': {
        // this.tipoDocumento = null;
        this.listaTipoDocumento = this.listaTipoDocumentoFisica;
        if (!isNullOrUndefined(this.listaTipoDocumentoFisica) && (this.tipoDocumento == 1 || isNullOrUndefined(this.tipoDocumento))) { this.tipoDocumento = this.listaTipoDocumentoFisica[0].valor; }
        this.serviceUtils.carregaJQuery();
        setTimeout(() => {
          $('select').formSelect();
        }, 100);
        break;
      }
      case '1': {
        this.tipoDocumento = null;
        this.listaTipoDocumento = this.listaTipoDocumentoJuridica;
        if (!isNullOrUndefined(this.listaTipoDocumentoJuridica)) { this.tipoDocumento = this.listaTipoDocumentoJuridica[0].valor; }
        setTimeout(() => {
          $('select').formSelect();
        }, 1);
        break;
      } default: {
        this.listaTipoDocumento = this.listaTipoDocumentoFisica.concat(this.listaTipoDocumentoJuridica);
        this.tipoDocumento = null;
        setTimeout(() => {
          $('select').formSelect();
        }, 1);
        break;
      }
    }
    this.serviceUtils.carregaJQuery();
    setTimeout(() => {
      $('select').formSelect();
    }, 1);

    if (this.tipoPessoa === '1' || this.tipoDocumento === 1) {
      this.nomeCampo = "Razão social";
    } else if (this.tipoPessoa === '2' || this.tipoDocumento === 2 || this.tipoDocumento === 3) {
      this.nomeCampo = "Nome";
    } else {
      this.nomeCampo = "Nome / Razão Social";
    }

  }

  private removePessoaJuridica() {
    let i: number;
    const aux = new Array<any>();
    for (i = 0; i < this.listaTipoPessoa.length; i++) {
      if (this.listaTipoPessoa[i].valor !== 1) {
        aux.push(this.listaTipoPessoa[i]);
      }
    }
    this.listaTipoPessoaFisica = aux;
  }

  private removeCNJP() {
    let i: number;
    const aux = new Array<any>();
    for (i = 0; i < this.listaTipoDocumento.length; i++) {
      if (this.listaTipoDocumento[i].valor !== 1) {
        aux.push(this.listaTipoDocumento[i]);
      }
    }
    this.listaTipoDocumentoFisica = aux;
  }

  private removeCPFeRNE() {
    let i: number;
    const aux = new Array<any>();
    for (i = 0; i < this.listaTipoDocumento.length; i++) {
      if (this.listaTipoDocumento[i].valor === 1) {
        aux.push(this.listaTipoDocumento[i]);
      }
    }
    this.listaTipoDocumentoJuridica = aux;
  }

  itemSelecionado(item: any) {
    this.pessoaSelecionada = item;
    this.verificaTipoDeBusca();
  }

  verificaTipoDeBusca() {
    if (this.ehCliente) {
      this.buscaCliente(this.pessoaSelecionada.id);
    } else if (this.ehVendedor) {
      this.buscaVendedor(this.pessoaSelecionada.id);
    } else if (this.ehUnidadeColeta ) {
      this.buscaUnidadeColeta(this.pessoaSelecionada.id);
    } else if (this.ehUnidadeColetaComContrato) {
      this.buscaUnidadeColetaComContrato(this.pessoaSelecionada.id);
    } else if (this.ehClienteComContrato) {
      this.buscaClienteComContrato(this.pessoaSelecionada.id);
    } else {
      this.emitPessoaSelecionada();
    }
  }

  emitPessoaSelecionada() {
    this.respostaComponent.emit(this.pessoaSelecionada);
    this.hideDialog();
  }

  buscaCliente(idCliente: number) {
    this.pesquisaPessoasService.recuperarClienteId(idCliente).subscribe(response => {
      this.respostaComponent.emit(response);
      this.hideDialog();
    }, error => {
      this.serviceUtils.showError(error);
      console.log(error);
    });
  }

  buscaVendedor(idVendedor: number) {
    this.pesquisaPessoasService.recuperarVendedorId(idVendedor).subscribe(response => {
      this.respostaComponent.emit(response);
      this.hideDialog();
    }, error => {
      this.serviceUtils.showError(error);
      console.log(error);
    });
  }

  buscaUnidadeColeta(idUnidadeColeta: number) {
    this.pesquisaPessoasService.recuperarUnidadeColetaId(idUnidadeColeta).subscribe(response => {
      this.respostaComponent.emit(response);
      this.hideDialog();
    }, error => {
      this.serviceUtils.showError(error);
      console.log(error);
    });
  }

  buscaUnidadeColetaComContrato(idUnidadeColeta: number) {
    this.pesquisaPessoasService.recuperarUnidadeColetaComContratoId(idUnidadeColeta).subscribe(response => {
      this.respostaComponent.emit(response);
      this.hideDialog();
    }, error => {
      this.serviceUtils.showError(error);
      console.log(error);
    });
  }

  buscaClienteComContrato(idCliente: number) {
    this.pesquisaPessoasService.recuperarClienteComContratoId(idCliente).subscribe(response => {
      this.respostaComponent.emit(response);
      this.hideDialog();
    }, error => {
      this.serviceUtils.showError(error);
      console.log(error);
    });
  }

  populaTipoDocumentoEnum() {
    this.enumService.recuperaTipoDocumentoPessoa().subscribe(
      data => {
        this.listaTipoDocumento = data;
        this.removeCPFeRNE();
        this.removeCNJP();
        this.isLoadingSpinner = false;
        this.serviceUtils.carregaJQuery();
      });
  }

  populaTipoPessoaEnum() {
    this.enumService.recuperaTipoPessoa().subscribe(
      data => {
        this.listaTipoPessoa = data;
        this.verificaPessoaFisica();
      }
    );
  }

  paginate(event) {
    this.pesquisaPessoa(event.page + 1);
    this.actualpage = event.first;
  }

  public pesquisaPessoa(page: number) {
    if (page <= 10) {
      this.actualpage = 1;
    }
    this.isLoading = true;
    if ((!this.ehCliente) && (!this.ehDoador) && (!this.ehColetor) && (!this.ehTestemunha) && (!this.ehVendedor) && (!this.ehUnidadeColeta) && (!this.ehClienteComContrato) && (!this.ehUnidadeColetaComContrato)) {
      this.pesquisaPessoasService.recuperarContatoPessoa(this.filtros, page).subscribe(
        data => {
          this.listaPessoas = data;
          if (!isNullOrUndefined(this.listaPessoas) && this.listaPessoas.length > 0) {
            this.numberOfRecords = this.listaPessoas[0].numberOfRows;
            this.resultFound = true;
            this.resultNotFound = false;
            this.isLoading = false;
          } else {
            this.resultFound = false;
            this.resultNotFound = true;
            this.isLoading = false;
          }
        },
        erro => {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
          console.log(erro);
        }
      );
    } if (this.ehCliente) {
      this.pesquisaPessoasService.recuperarContatoCliente(this.filtros, page).subscribe(
        data => {
          this.listaPessoas = data;
          if (!isNullOrUndefined(this.listaPessoas) && this.listaPessoas.length > 0) {
            this.numberOfRecords = this.listaPessoas[0].numberOfRows;
            this.resultFound = true;
            this.resultNotFound = false;
            this.isLoading = false;
          } else {
            this.resultFound = false;
            this.resultNotFound = true;
            this.isLoading = false;
          }
        },
        erro => {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
          console.log(erro);
        }
      );
    }
    if (this.ehDoador) {
      this.pesquisaPessoasService.recuperarContatoDoador(this.filtros, page).subscribe(
        data => {
          this.listaPessoas = data;
          if (!isNullOrUndefined(this.listaPessoas) && this.listaPessoas.length > 0) {
            this.numberOfRecords = this.listaPessoas[0].numberOfRows;
            this.resultFound = true;
            this.resultNotFound = false;
            this.isLoading = false;
          } else {
            this.resultFound = false;
            this.resultNotFound = true;
            this.isLoading = false;
          }
        },
        erro => {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
          console.log(erro);
        }
      );
    }
    if (this.ehColetor) {
      this.pesquisaPessoasService.recuperarContatoColetor(this.filtros, page).subscribe(
        data => {
          this.listaPessoas = data;
          if (!isNullOrUndefined(this.listaPessoas) && this.listaPessoas.length > 0) {
            this.numberOfRecords = this.listaPessoas[0].numberOfRows;
            this.resultFound = true;
            this.resultNotFound = false;
            this.isLoading = false;
          } else {
            this.resultFound = false;
            this.resultNotFound = true;
            this.isLoading = false;
          }
        },
        erro => {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
          console.log(erro);
        }
      );
    }
    if (this.ehTestemunha) {
      this.pesquisaPessoasService.recuperarContatoTestemunha(this.filtros, page).subscribe(
        data => {
          this.listaPessoas = data;
          if (!isNullOrUndefined(this.listaPessoas) && this.listaPessoas.length > 0) {
            this.numberOfRecords = this.listaPessoas[0].numberOfRows;
            this.resultFound = true;
            this.resultNotFound = false;
            this.isLoading = false;
          } else {
            this.resultFound = false;
            this.resultNotFound = true;
            this.isLoading = false;
          }
        },
        erro => {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
          console.log(erro);
        }
      );
    }
    if (this.ehVendedor) {
      this.pesquisaPessoasService.recuperarContratoVendedor(this.filtros, page).subscribe(
        data => {
          this.listaPessoas = data;
          if (!isNullOrUndefined(this.listaPessoas) && this.listaPessoas.length > 0) {
            this.numberOfRecords = this.listaPessoas[0].numberOfRows;
            this.resultFound = true;
            this.resultNotFound = false;
            this.isLoading = false;
          } else {
            this.resultFound = false;
            this.resultNotFound = true;
            this.isLoading = false;
          }
        },
        erro => {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
          console.log(erro);
        }
      );
    }
    if (this.ehUnidadeColeta) {
      this.pesquisaPessoasService.recuperarUnidadeColeta(this.filtros, page, this.isActive).subscribe(
        data => {
          this.listaPessoas = data;
          if (!isNullOrUndefined(this.listaPessoas) && this.listaPessoas.length > 0) {
            this.numberOfRecords = this.listaPessoas[0].numberOfRows;
            this.resultFound = true;
            this.resultNotFound = false;
            this.isLoading = false;
          } else {
            this.resultFound = false;
            this.resultNotFound = true;
            this.isLoading = false;
          }
        },
        erro => {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
          console.log(erro);
        }
      );
    }
    if (this.ehUnidadeColetaComContrato) {
      this.pesquisaPessoasService.recuperarUnidadeColetaComContrato(this.filtros, page, this.isActive).subscribe(
        data => {
          this.listaPessoas = data;
          if (!isNullOrUndefined(this.listaPessoas) && this.listaPessoas.length > 0) {
            this.numberOfRecords = this.listaPessoas[0].numberOfRows;
            this.resultFound = true;
            this.resultNotFound = false;
            this.isLoading = false;
          } else {
            this.resultFound = false;
            this.resultNotFound = true;
            this.isLoading = false;
          }
        },
        erro => {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
          console.log(erro);
        }
      );
    }
    if (this.ehClienteComContrato) {
      this.pesquisaPessoasService.recuperarClienteComContrato(this.filtros, page, this.isActive).subscribe(
        data => {
          this.listaPessoas = data;
          console.log(data);
          if (!isNullOrUndefined(this.listaPessoas) && this.listaPessoas.length > 0) {
            this.numberOfRecords = this.listaPessoas[0].numberOfRows;
            this.resultFound = true;
            this.resultNotFound = false;
            this.isLoading = false;
          } else {
            this.resultFound = false;
            this.resultNotFound = true;
            this.isLoading = false;
          }
        },
        error => {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
          console.log(error);
        }
      );
    }
  }

  buscarNome(event) {
    if ((!this.ehCliente) && (!this.ehDoador) && (!this.ehColetor) && (!this.ehTestemunha)) {
      this.pesquisaPessoasService.recuperarNome(event).subscribe(
        data =>
          this.listaNomes = data
      );
    } if (this.ehCliente) {
      this.pesquisaPessoasService.recuperarNomeCliente(event).subscribe(
        data =>
          this.listaNomes = data
      );
    }
    if (this.ehDoador) {
      this.pesquisaPessoasService.recuperarNomeDoador(event).subscribe(
        data =>
          this.listaNomes = data
      );
    }
    if (this.ehColetor) {
      this.pesquisaPessoasService.recuperarNomeColetor(event).subscribe(
        data =>
          this.listaNomes = data
      );
    }
    if (this.ehTestemunha) {
      this.pesquisaPessoasService.recuperarNomeTestemunha(event).subscribe(
        data =>
          this.listaNomes = data
      );
    }
    if (this.ehVendedor) {
      this.pesquisaPessoasService.recuperarNomeVendedor(event).subscribe(
        data =>
          this.listaNomes = data
      );
    }
  }

  limpaForm() {
    this.nome = '';
    this.documento = '';
    this.tipoDocumento = null;
    this.nomeCampo = 'Nome / Razão Social';
    this.tipoPessoa = null;
    this.listaPessoas = null;
    this.resultFound = false;
    this.resultNotFound = false;
    this.isLoading = true;
    this.idUnidadeColeta = null;
    this.checked = false;
  }

  showDialogPesquisaPessoa() {
    this.limpaForm();
    if (this.ehTestemunha || this.ehColetor || this.ehDoador) {
      this.tipoPessoa = "2";
      this.verificaTipoPessoa();
    } else if (this.ehContrato || this.ehUnidadeColeta || this.ehClienteComContrato || this.ehUnidadeColetaComContrato) {
      this.tipoPessoa = "1";
      if (this.ehUnidadeColeta || this.ehUnidadeColetaComContrato) {
        this.desTipoPessoa = true;
        this.desTipoDocumento = true;
      }
      this.verificaTipoPessoa();
    }
    this.populaTipoDocumentoEnum();
    this.filtros.tipoDocumento = this.tipoDocumento;
    this.isLoading = false;
    setTimeout(() => {
      $('select').formSelect();
    }, 100);

    this.displayModalPesquisaPessoa = true;
  }

  populaUnidadeColeta(idUnidadeColeta: number) {
      this.idUnidadeColeta = idUnidadeColeta;
      this.checked = true;
  }

  recuperaClienteId(clienteId: number) {
    return this.pesquisaPessoasService.recuperarClienteId(clienteId);
  }

  limpaNomeDoc() {
    this.documento = '';
    this.nome = '';
    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }

  btnClick() {
    if (this.checked) {
      this.checked = false;
    } else {
      this.checked = true;
    }
  }

  verificaContrato() {
    if (this.ehContrato) {
      return true;
    } else {
      return false;
    }
  }
}

