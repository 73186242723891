import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'mascaraCnpj'
})
export class CnpjPipe implements PipeTransform{
   cnpjFormatado: string;

    transform(cnpj: string){
        if(cnpj){            
            if(cnpj.length === 14){
               this.cnpjFormatado = cnpj.substring(0,2).concat(".").concat(cnpj.substring(2,5))                                      
                                      .concat(".").concat(cnpj.substring(5,8)).concat("/")
                                      .concat(cnpj.substring(8,12)).concat("-").concat(cnpj.substring(12,14))
            } 
        }

        return this.cnpjFormatado;
    }
}