import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { formatDate } from "@angular/common";

// Service
import { DetalhesSolicitacaoService } from './detalhes-solicitacao.service';
import { ZSUploadService } from '../../../../base/zsFrameWork/zs-upload/zs-upload.service';

// VOs
import { SolicitacaoGetVO } from '../../../../base/vo/Solicitacoes/solicitacao-get';
import { isNullOrUndefined } from 'util';

// Components
import { DetalhesInteracaoComponent } from './detalhes-interacao/detalhes-interacao.component';

@Component({
  selector: 'app-detalhes-solicitacao',
  templateUrl: './detalhes-solicitacao.component.html',
  styleUrls: ['./detalhes-solicitacao.component.css']
})
export class DetalhesSolicitacaoComponent implements OnInit {

  @Output() emiteEventoVoltar: EventEmitter<any> = new EventEmitter();
  @Input() idSolicitacao: number;
  @ViewChild('detalheInteracao') public detalheInteracao: DetalhesInteracaoComponent;
  public protocolo: string;
  public solicitacaoExistente: SolicitacaoGetVO;
  public isLoading: boolean;
  public listaDeInteracoesDaSolicitacao = new Array<any>();
  public existeInteracao: boolean;
  public listaDeArquivosAnexos = new Array<any>();

  //#region Dados Pessoa
  public nomeDoSolicitante: string;
  public pesquisaNomeSolicitanteRealizada: boolean;
  public nomeTipoDocumento: string;
  public documento: string;
  public emailFiscal: string;
  //#endregion

  constructor(private detalhesSolicitacaoService: DetalhesSolicitacaoService,
    private uploadService: ZSUploadService) { }

  ngOnInit() {
    this.recuperaDadosSolicitacaoPorId();
  }

  public downloadArquivo(arquivo: any) {
    this.uploadService.download(arquivo);
  }

  public exibirDetalhesInteracao(interacao: any) {
    this.detalheInteracao.show(interacao, this.protocolo);
  }

  public voltar() {
    this.emiteEventoVoltar.emit();
  }

  private recuperaDadosSolicitacaoPorId() {
    this.isLoading = true;
    this.detalhesSolicitacaoService.recuperaDadosSolicitacaoPorId(this.idSolicitacao).subscribe(
      resultado => {
        this.solicitacaoExistente = Object.assign(new SolicitacaoGetVO(), resultado);
        this.listaDeInteracoesDaSolicitacao = this.solicitacaoExistente.listaDeInteracoesDaSolicitacao;
        this.protocolo = this.solicitacaoExistente.protocolo;
        console.log(this.solicitacaoExistente);
        this.verificaSeExisteInteracao();
        this.isLoading = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  private verificaSeExisteInteracao() {
    if (isNullOrUndefined(this.listaDeInteracoesDaSolicitacao)) {
      this.existeInteracao = false;
    } else {
      if (this.listaDeInteracoesDaSolicitacao.length === 0) {
        this.existeInteracao = false;
      } else {
        this.existeInteracao = true;
        this.formataDataDeCriacaoDaInteracao();
        this.carregaListaDeArquivosAnexos();
      }
    }
  }

  private formataDataDeCriacaoDaInteracao() {
    this.listaDeInteracoesDaSolicitacao.map(interacao => {
      interacao.dataCriacao = formatDate(interacao.dataCriacao, 'dd/MM/yyyy', 'en-US');
    });
  }

  private carregaListaDeArquivosAnexos() {
    this.listaDeInteracoesDaSolicitacao.forEach(interacao => {
      interacao.listaDeArquivo.forEach(arquivo => {
        this.listaDeArquivosAnexos.push(arquivo);
      });
    });
  }

}
