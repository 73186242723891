import {Pipe, PipeTransform} from "@angular/core";
import { isNullOrUndefined } from 'util';

@Pipe({
    name: 'mascaraPercentual'
})
export class PercentualPipe implements PipeTransform {
   valorFormatado: string;
   re: RegExp = /.*\..*/;

    transform(valor: string) {
        if (!isNullOrUndefined(valor)) {
            this.valorFormatado = this.formataValorMonetario(valor.toString());
        }
        return  this.valorFormatado + "%";
    }

    formataValorMonetario(valor: string): string {
        let splitValor: any;
        // Troca o ponto pela virgula
        if (this.re.test(valor)) {
            valor = valor.replace(/\./gi, ",");
        } else {
            // Não encontrou ponto, insere os centavos como 00
            valor += ",00";
        }

        splitValor = valor.split(",");
        if (!isNullOrUndefined(splitValor[1])) {
            if (splitValor[1].length == 1) {
                valor += "0";
            }
        }
        return valor;
    }
}
