import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../base/util/base.service';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class DetalhesSolicitacaoService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    recuperaDadosSolicitacaoPorId(idSolicitacao: number) {
        const urlDestino = environment.baseUrl + "solicitacao/" + idSolicitacao.toString();
        return this.get(urlDestino);
    }

}
