import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';

import { BaseService } from './../../../base/util/base.service';
import { environment } from './../../../../environments/environment';

@Injectable()
export class LaudoServices extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    public recuperarTiposMatriz() {
        const urlDestino = environment.baseUrl + "tipoMatrizExames";
        return this.get(urlDestino);
    }

    public recuperarTiposResultadoLaudos() {
        const urlDestino = environment.baseUrl + "enum/laudos/resultados";
        return this.get(urlDestino);
    }

    public recuperarStatusLaudos() {
        const urlDestino = environment.baseUrl + "enum/laudos/status";
        return this.get(urlDestino);
    }

    public recuperarStatusResultadoRelatorioMedico() {
        const urlDestino = environment.baseUrl + "/enum/laudos/Status/Resultados/RelatorioMedico";
        return this.get(urlDestino);
    }

    public recuperarStatusRelatorioMedico() {
        const urlDestino = environment.baseUrl + "/enum/laudos/Status/RelatorioMedico";
        return this.get(urlDestino);
    }


    public recuperarIdiomaImpressaoDroga() {
        const urlDestino = environment.baseUrl + "enum/idioma-impressao-droga";
        return this.get(urlDestino);
    }

    public recuperarLaudo(id: number,) {
        const urlDestino = environment.baseUrl + "laudosWS/" + id;
        return this.get(urlDestino);
    }

    public recuperarLaudos(filtros: any, page: number, pageSize: number) {
        let urlDestino = environment.baseUrl + "laudosWS";
        urlDestino += this.parametrosUrl(filtros, page, pageSize);
        console.log(urlDestino);
        return this.get(urlDestino);
    }

    public recuperarRelatorioLaudos(filtros: any) {
        let urlDestino = environment.baseUrl + "laudosWS/laudos/excel";
        urlDestino += this.parametrosUrl(filtros, 0, 0);
        return this.getFile(urlDestino);
    }

    public baixarRelatorioLaudoPdf(idLaudo: number, isTimbrado: boolean, isAssinado: boolean, idIdiomaImpressaoDroga: number = 1) {
        const urlDestino = environment.baseUrl + "laudosWS/pdf?idLaudo=" + idLaudo
                                               + "&isTimbrado=" + isTimbrado
                                               + "&isAssinado=" + isAssinado
                                               + "&idIdiomaImpressaoDroga=" + idIdiomaImpressaoDroga;
        return this.getFile(urlDestino);
    }

    public BaixarRelatorioMedicoPdf(laudoLiberarRelatorioMedico: any) {
        const urlDestino = environment.baseUrl + "relatorioMedicoWS/pdf?idLaudo=" + laudoLiberarRelatorioMedico.laudo.id + "&resultadoPrevioConferecia=" + laudoLiberarRelatorioMedico.resultado;
        return this.getFile(urlDestino);
    }

    public LiberarRelatorioMedico(Laudo) {
        const urlDestino = environment.baseUrl + "/laudosWS/liberar/medico" ;
        return this.put(urlDestino, Laudo);
    }

    public liberarLaudo(listaLiberarItens: any,
                        listaNaoLiberarItens: any,
                        marcarTodosItens: boolean,
                        filtrosBusca: any,
                        idRevisor: number,
                        idTecnico: number,
                        idRespAssinaturaUm: number,
                        idRespAssinaturaDois: number,
                        idiomaImpressaDroga: number) {
        const strJson = JSON.stringify(
            {
                listaLiberarItens: listaLiberarItens.map(elemento => elemento.id),
                listaNaoLiberarItens: listaNaoLiberarItens.map(elemento => elemento.id),
                marcarTodosItens: marcarTodosItens,
                codigoAmostra: filtrosBusca.codigoAmostra,
                idMatrizExame: filtrosBusca.idMatrizExame,
                idTipoResultado: filtrosBusca.idTipoResultado,
                idStatus: filtrosBusca.idStatus,
                idUnidadeColeta: filtrosBusca.idUnidadeColeta,
                idDoador: filtrosBusca.idDoador,
                IdRevisor: idRevisor,
                IdTecnico: idTecnico,
                IdRespAssinaturaUm: idRespAssinaturaUm,
                IdRespAssinaturaDois: idRespAssinaturaDois,
                IdiomaImpressaDroga: idiomaImpressaDroga,
                periodoIni: filtrosBusca.periodoIni,
                periodoFim: filtrosBusca.periodoFim
            });
        const body = JSON.parse(strJson);
        return this.put((environment.baseUrl + "laudosWS/liberar"), body);
    }

    public salvarObservacao(idConferenciaConfirmatoria: number, observacao: string) {
        const strJson = JSON.stringify(
            {
                id: idConferenciaConfirmatoria,
                observacao: observacao
            });
        const body = JSON.parse(strJson);
        return this.put((environment.baseUrl + "laudoWS/observacao"), body);
    }

    private parametrosUrl(filtros: any, page: number, pageSize: number): string {
        let url = "?page=" + page + "&pageSize=" + pageSize;

        if (!isNullOrUndefined(filtros.codigoAmostra)) {
            url = url + "&codigoAmostra=" + filtros.codigoAmostra;
        }
        if (filtros.idMatrizExame > 0) {
            url = url + "&idMatrizExame=" + filtros.idMatrizExame;
        }
        if (filtros.idTipoResultado > 0) {
            url = url + "&idResultado=" + filtros.idTipoResultado;
        }
        if (filtros.idStatus > 0) {
            url = url + "&idStatus=" + filtros.idStatus;
        }
        if (filtros.idRelarioMedico > 0) {
            url = url + "&statusRelatorioMedico=" + filtros.idRelarioMedico;
        }
        if (!isNullOrUndefined(filtros.idUnidadeColeta)) {
            url = url + "&idUnidadeColeta=" + filtros.idUnidadeColeta;
        }
        if (!isNullOrUndefined(filtros.idDoador)) {
            url = url + "&idDoador=" + filtros.idDoador;
        }
        if (!isNullOrUndefined(filtros.periodoIni)) {
            url = url + "&periodoIni=" + filtros.periodoIni;
        }
        if (!isNullOrUndefined(filtros.periodoFim)) {
            url = url + "&periodoFim=" + filtros.periodoFim;
        }
        if (!isNullOrUndefined(filtros.codigoLote)) {
            url = url + "&codigoLote=" + filtros.codigoLote;
        }
        if (!isNullOrUndefined(filtros.idCliente)) {
            url = url + "&idCliente=" + filtros.idCliente;
        }
        return url;
    }

    public reabrirLaudo(laudo: any) {
        const strJson = JSON.stringify({ id: laudo.id});
        const body = JSON.parse(strJson);
        return this.put((environment.baseUrl + "laudosWS/reabrir"), body);
    }

}
