export class EnderecoPessoaVO {
    constructor(
        public id?: number,
        public idPessoa?: number,
        public logradouro?: string,
        public complemento?: string,
        public bairro?: string,
        public cep?: string,
        public numero?: string,
        public idCidade?: number,
        public nomeCidade?: string,
        public idTipoEndereco?: number,
        public padrao?: number,
        public nomeEstado?: string,
        public nomeTipoEndereco?: string,
        public descricaoPadrao?: string
    ) {}
}
