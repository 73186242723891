import { isNullOrUndefined } from 'util';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../base/util/base.service';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class DetalheWorkflowService extends BaseService {
    constructor(public http: HttpClient) {
        super(http);
    }

    recuperarWorkflowDetalhe(idworkflow: string) {
        let urlDestino = environment.baseUrl + "solicitacao-workflow";
        let idItem: string;
        if (!isNullOrUndefined(idworkflow)) {
            idItem = "/" + idworkflow;
            urlDestino += idItem;
        }
        return this.get(urlDestino);
    }
}

