import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef  } from '@angular/core';
import { Message } from 'primeng/components/common/message';

// Services
import { NovaSolicitacaoService } from './nova-solicitacao.service';
import { Utils } from '../../../../base/util/utils';

// Components
import { PesquisaPessoasComponent } from '../../../pessoas/pessoas-data/pesquisa-pessoas/pesquisa-pessoas.component';
import { SolicitacaoInteracaoNovoDocumentoComponent } from '../solicitacao-interacao-novo-documento/solicitacao-interacao-novo-documento.component';

// VOs
import { SolicitacaoVO } from '../../../../base/vo/Solicitacoes/solicitacao';
import { SolicitacaoInteracaoVO } from '../../../../base/vo/Solicitacoes/solicitacao-interacao';
import { SolicitacaoGetVO } from '../../../../base/vo/Solicitacoes/solicitacao-get';
import { ArquivoVO } from '../../../../base/vo/arquivo';

import { isNullOrUndefined } from 'util';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-nova-solicitacao',
  templateUrl: './nova-solicitacao.component.html',
  styleUrls: ['./nova-solicitacao.component.css']
})
export class NovaSolicitacaoComponent implements OnInit {

  // Mensagens de retorno
  public msgs: Message[] = [];

  @Output() emiteEventoVoltar: EventEmitter<any> = new EventEmitter();
  @Input() idSolicitacao: number;
  @ViewChild('pesquisaPessoa') public pesquisaPessoa: PesquisaPessoasComponent;
  @ViewChild('solicitacaoInteracaoNovoDocumento') public solicitacaoInteracaoNovoDocumento: SolicitacaoInteracaoNovoDocumentoComponent;

  // Viewchilds usadas para limpar valor da input da select salvo pelo navegador
  @ViewChild('elementRefDepartamento') elementRefDepartamento: ElementRef;
  // this.elementRefDiaFechamento.nativeElement.value = '';

  public listaDeArquivosAnexados: any;
  public listaArquivos: Array<ArquivoVO>;
  // Post
  private novaSolicitacaoInteracao: SolicitacaoVO;
  private novaInteracao: SolicitacaoInteracaoVO;
  public solicitacaoExistente: SolicitacaoGetVO;
  public descricaoEtapaAtual: string;
  public idEtapaAtual: number;
  public isNovaSolicitacao: boolean = true;
  public isLoading: boolean;
  public desabilitarSelecaoDeGrupoAtendimento: boolean = true;
  public desabilitarSelecaoDeProximasEtapas: boolean = true;

  //#region Dados Pessoa
  public nomeDoSolicitante: string;
  public pesquisaNomeSolicitanteRealizada: boolean;
  public nomeTipoDocumento: string;
  public documento: string;
  public tipoDocumento: number;
  public emailFiscal: string;
  public emailSetado: boolean;
  //#endregion

  //#region Solicitação
  public idSolicitante: number;
  public isSolicitanteValido: boolean = true;
  public idOrigemSolicitacao: number;
  public isOrigemSolicitacaoValido: boolean = true;
  public idTipoSolicitacao: number;
  public isTipoSolicitacaoValido: boolean = true;
  public idWorkflowSolicitacao: number = 0;
  public isWorkflowSolicitacaoValido: boolean = true;
  public idProximaEtapa: number;
  public isProximaEtapaValida: boolean = true;
  public idDepartamento: number;
  public isDepartamentoValido: boolean = true;
  public interacao: string;
  public isInteracaoValida: boolean = true;
  //#endregion

  //#region Dropdowns
  public listaDeOrigemDaSolicitacao: any;
  public listaDeTipoDaSolicitacao: any;
  public listaDeWorkflowDaSolicitacao: any;
  public listaDeEtapasWorkflow: any;
  public listaProximasEtapas: any;
  public listaGruposAtendimentos: any;
  //#endregion

  public tituloBotaoEncaminhar: string = "Encaminhar";

  constructor(private novaSolicitacaoService: NovaSolicitacaoService, public utils: Utils) { }

  ngOnInit() {
    this.verificaAcao();
    this.carregaJQuery();
  }

  public showDialogPesquisaSolicitante() {
    this.isSolicitanteValido = true;
    this.pesquisaPessoa.showDialogPesquisaPessoa();
  }

  public limparPlaceHolderPesquisaSolicitante() {
    this.nomeDoSolicitante = null;
    this.nomeTipoDocumento = null;
    this.documento = null;
    this.tipoDocumento = null;
    this.emailFiscal = null;
    this.idSolicitante = null;
    this.pesquisaNomeSolicitanteRealizada = false;
    this.emailSetado = false;
  }

  public anexarArquivo() {
    if (isNullOrUndefined(this.listaArquivos)) {
      this.listaArquivos = new Array<ArquivoVO>();
    }
    this.solicitacaoInteracaoNovoDocumento.showDialogNovoArquivo();
  }

  public encaminhar() {
    if (this.validaDadosSolicitacaoInteracao()) {      
      this.populaNovaSolicitacaoInteracao();
      this.encaminharSolicitacao();
    }
  }

  public setDadosPessoa(dadosPessoa: any) {
    this.nomeDoSolicitante = dadosPessoa.nome;
    this.nomeTipoDocumento = dadosPessoa.nomeTipoDocumento;
    this.documento = this.formataDocumento(dadosPessoa.documento, dadosPessoa.tipoDocumento);
    this.tipoDocumento = dadosPessoa.tipoDocumento;
    this.emailFiscal = dadosPessoa.emailFiscal;
    this.idSolicitante = dadosPessoa.id;

    this.pesquisaNomeSolicitanteRealizada = true;
    this.emailSetado = true;
  }

  public callbackNovoArquivo(event: ArquivoVO) {
    this.listaArquivos.push(event);
  }

  public removerArquivo(index: number) {
    this.listaArquivos.splice(index, 1);
  }

  public buscarEtapasDoWorkflow() {
    this.limpaProximasEtapas();
    this.limpaGruposAtendimento();
    this.recuperaEtapasDoWorkflow();
  }

  private validaDadosSolicitacaoInteracao(): boolean {
    const isValido = new Array<any>();

    if (this.isNovaSolicitacao) {
      isValido.push(this.isOrigemSolicitacaoValido = !isNullOrUndefined(this.idOrigemSolicitacao));
      isValido.push(this.isTipoSolicitacaoValido = !isNullOrUndefined(this.idTipoSolicitacao));
      isValido.push(this.isWorkflowSolicitacaoValido = !isNullOrUndefined(this.idWorkflowSolicitacao) && this.idWorkflowSolicitacao !== 0);
      isValido.push(this.isSolicitanteValido = !isNullOrUndefined(this.idSolicitante));
    }
    if (!this.isUtlimaEtapa()) {
      isValido.push(this.isProximaEtapaValida = !isNullOrUndefined(this.idProximaEtapa));

      if (this.proximaEtapaPossuiGruposdeAtendimento()) {
        isValido.push(this.isDepartamentoValido = !isNullOrUndefined(this.idDepartamento));
      }      
    }
    isValido.push(this.isInteracaoValida = !this.utils.isStringNullOrBlank(this.interacao));

    return !isValido.some(function isAnyFalse(element) { return (element === false); });
  }

  public carregaListaGruposAtendimentos(idEtapa: any) {
    this.limpaGruposAtendimento();
    this.listaDeEtapasWorkflow.forEach(etapa => {
      if (etapa.id == idEtapa) {
        this.listaGruposAtendimentos = etapa.gruposSelecionados;
        if (this.listaGruposAtendimentos.length > 0) {
          this.desabilitarSelecaoDeGrupoAtendimento = false;
        } else {
          this.desabilitarSelecaoDeGrupoAtendimento = true;
        }
        this.carregaJQuery();
      }
    });
  }

  private isUtlimaEtapa(): boolean {
    if (isNullOrUndefined(this.listaProximasEtapas)) {      
      return true;
    } else {
      if (this.listaProximasEtapas.length === 0) {
        return true;
      }
      return false;
    }
  }

  private proximaEtapaPossuiGruposdeAtendimento(): boolean {
    if (isNullOrUndefined(this.listaGruposAtendimentos)) {
      return false;
    } else {
      if (this.listaGruposAtendimentos.length === 0) {
        return false;
      }      
      return true;
    }
  }

  private verificaAcao() {
    this.isLoading = true;
    if (!isNullOrUndefined(this.idSolicitacao)) {
      // É uma solicitação já existe
      this.isNovaSolicitacao = false;
      this.recuperaSolicitacao();

    } else {
      // É uma nova solicitação
      this.isNovaSolicitacao = true;
      this.carregaDropdowns();
      this.descricaoEtapaAtual = '-';
    }
  }

  private populaNovaSolicitacaoInteracao() {
    if (this.isNovaSolicitacao) {
      this.populaSolicitacao();
    } else {
      this.populaInteracao();
    }
  }

  private populaSolicitacao() {
    this.novaSolicitacaoInteracao = new SolicitacaoVO();
    this.novaSolicitacaoInteracao.idOrigemSolicitacao = this.idOrigemSolicitacao;
    this.novaSolicitacaoInteracao.idTipoSolicitacao = this.idTipoSolicitacao;
    this.novaSolicitacaoInteracao.idSolicitacaoWorkflow = this.idWorkflowSolicitacao;
    this.novaSolicitacaoInteracao.idSolicitacaoWorkflowEtapa = this.idProximaEtapa;
    this.novaSolicitacaoInteracao.idPessoa = this.idSolicitante;

    this.novaInteracao = new SolicitacaoInteracaoVO();
    this.novaInteracao.idGrupoAtendimento = this.idDepartamento;
    this.novaInteracao.idSolicitacaoWorkflowEtapa = this.idProximaEtapa;
    this.novaInteracao.texto = this.interacao;
    this.novaInteracao.IsUltimaInteracao = false;
    this.novaInteracao.listaDeArquivos = this.listaArquivos;

    this.novaSolicitacaoInteracao.solicitacaoInteracao = this.novaInteracao;
  }

  private populaInteracao() {
    this.novaInteracao = new SolicitacaoInteracaoVO();
    this.novaInteracao.idSolicitacao = this.idSolicitacao;
    this.novaInteracao.idGrupoAtendimento = this.idDepartamento;
    if (this.isUtlimaEtapa()) {
      this.novaInteracao.idSolicitacaoWorkflowEtapa =this.idEtapaAtual;
      this.novaInteracao.IsUltimaInteracao = true;
    } else {
      this.novaInteracao.idSolicitacaoWorkflowEtapa = this.idProximaEtapa;
      this.novaInteracao.IsUltimaInteracao = false;
    }    
    this.novaInteracao.texto = this.interacao;
    this.novaInteracao.listaDeArquivos = this.listaArquivos;
  }

  private encaminharSolicitacao() {
    if (this.isNovaSolicitacao) {
      this.insereNovaSolicitacao();
    } else {
      this.insereNovaSolicitacaoInteracao();
    }
  }

  private insereNovaSolicitacao() {
    this.isLoading = true;
    this.novaSolicitacaoService.insereNovaSolicitacao(this.novaSolicitacaoInteracao).subscribe(
      resultado => {
        if (resultado.success) {
          this.msgs = this.utils.showSuccess();
          this.isLoading = false;
          this.voltar(true);
        } else {
          if (!isNullOrUndefined(resultado.data.length)) {
            this.msgs = this.utils.showError(resultado.data[0].message);
          } else {
            this.msgs = this.utils.showError(resultado.message);
          }
          this.isLoading = false;
        }
      }, error => {
        console.log(error);
      }
    );
  }

  private insereNovaSolicitacaoInteracao() {
    this.isLoading = true;
    this.novaSolicitacaoService.insereNovaSolicitacaoInteracao(this.novaInteracao).subscribe(
      resultado => {
        if (resultado.success) {
          this.msgs = this.utils.showSuccess();
          this.isLoading = false;
          this.voltar(true);
        } else {
          if (!isNullOrUndefined(resultado.data.length)) {
            this.msgs = this.utils.showError(resultado.data[0].message);
          } else {
            this.msgs = this.utils.showError(resultado.message);
          }
          this.isLoading = false;
        }
      }, error => {
        console.log(error);
      }
    );
  }

  private carregaListaDeProximasEtapas(proximasEtapas: any) {
    this.listaProximasEtapas = proximasEtapas.filter(this.isEtapaAtiva);
    this.carregaJQuery();
  }

  private isEtapaAtiva(etapa: any) {
    const ETAPA_ATIVA = 1;
    return etapa.statusEtapa == ETAPA_ATIVA;
  }

  private selecionaEtapaAtualDaNovaSolicitacao() {
    this.descricaoEtapaAtual = this.listaDeEtapasWorkflow[0].nome;
    this.carregaListaDeProximasEtapas(this.listaDeEtapasWorkflow[0].sequenciasSelecionadas);
  }

  private selecionaEtapaAtualDaSolicitacaoExistente() {
    const index = this.listaDeEtapasWorkflow.findIndex(etapa => etapa.id == this.idEtapaAtual);
    this.descricaoEtapaAtual = this.listaDeEtapasWorkflow[index].nome;
    this.carregaListaDeProximasEtapas(this.listaDeEtapasWorkflow[index].sequenciasSelecionadas);
  }

  private selecionaEtapaAtual() {
    if (!isNullOrUndefined(this.listaDeEtapasWorkflow)) {
      if (this.listaDeEtapasWorkflow.length > 0) {
        if (this.isNovaSolicitacao) {
          this.selecionaEtapaAtualDaNovaSolicitacao();
        } else {
          this.selecionaEtapaAtualDaSolicitacaoExistente();
        }
      }
    }
  }

  private recuperaEtapasDoWorkflow() {
    this.novaSolicitacaoService.recuperaEtapasDoWorkflow(this.idWorkflowSolicitacao).subscribe(
      resultado => {
        this.listaDeEtapasWorkflow = resultado;
        this.selecionaEtapaAtual();
        if (this.isUtlimaEtapa()) {
          this.desabilitarSelecaoDeProximasEtapas = true;
          this.tituloBotaoEncaminhar = "Finalizar";
        } else {
          this.desabilitarSelecaoDeProximasEtapas = false;
          this.tituloBotaoEncaminhar = "Encaminhar";
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  private recuperaOrigensDaSolicitacao() {
    this.novaSolicitacaoService.recuperaOrigensDaSolicitacao().subscribe(
      resultado => {
        this.listaDeOrigemDaSolicitacao = resultado;
        this.carregaJQuery();
      },
      error => {
        console.log(error);
      }
    );
  }

  private recuperaTipoDaSolicitacao() {
    this.novaSolicitacaoService.recuperaTipoDaSolicitacao().subscribe(
      resultado => {
        this.listaDeTipoDaSolicitacao = resultado;
        this.carregaJQuery();
        this.isLoading = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  public buscaWorkflow() {
    this.idWorkflowSolicitacao = 0;
    this.descricaoEtapaAtual = '-';
    this.listaDeWorkflowDaSolicitacao = [];
    this.limpaProximasEtapas();
    this.limpaGruposAtendimento();
    this.recuperaListaDeWorkflows();
  }

  private recuperaListaDeWorkflows() {
    this.novaSolicitacaoService.recuperaListaDeWorkflows(this.idTipoSolicitacao).subscribe(
      resultado => {
        this.listaDeWorkflowDaSolicitacao = resultado;
        this.carregaJQuery();
        // this.isLoading = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  private recuperaSolicitacao() {
    this.novaSolicitacaoService.recuperaDadosSolicitacaoPorId(this.idSolicitacao).subscribe(
      resultado => {
        this.solicitacaoExistente = Object.assign(new SolicitacaoGetVO(), resultado);
        this.idWorkflowSolicitacao = this.solicitacaoExistente.idSolicitacaoWorkflow;
        this.nomeTipoDocumento = this.solicitacaoExistente.descricaoTipoDocumentoDoSolicitante;
        this.documento = this.solicitacaoExistente.documento;
        this.emailFiscal = this.solicitacaoExistente.emailDoSolicitante;
        this.idEtapaAtual = this.solicitacaoExistente.idEtapaSolicitacaoWorkflow;
        this.emailSetado = true;
        this.recuperaEtapasDoWorkflow();
      },
      error => {
        console.log(error);
      }
    );
  }

  private limpaProximasEtapas() {
    this.idProximaEtapa = null;
    this.listaProximasEtapas = null;
  }

  private limpaGruposAtendimento() {
    this.idDepartamento = null;
    this.listaGruposAtendimentos = null;
    this.isDepartamentoValido = true;
  }

  private voltar(sucesso: boolean) {
    this.emiteEventoVoltar.emit(sucesso);
  }

  private carregaDropdowns() {
    this.recuperaOrigensDaSolicitacao();
    this.recuperaTipoDaSolicitacao();
    // this.recuperaListaDeWorkflows();
  }

  private limpaSelectDepartamento() {
    if (!isNullOrUndefined(this.elementRefDepartamento)) {
      this.elementRefDepartamento.nativeElement.selectedIndex = -1 ;
    }
  }

  private carregaJQuery() {
    setTimeout(function () {
      /**
       * Não remover o metodo limpaSelectDepartamento e nem .bind(this).
       * Motivo: Quando o usuário selecionava a etapa a select de departamento
       * era populada com os departamentos da etapa, porem ficava poluida com o
       * primeiro valor da lista de departamentos. Foi preciso transformar a select
       * de departamento em uma viewChild para limpar manualmente o campo.
       */
      this.limpaSelectDepartamento();
      M.AutoInit();
      $('.dropdown-trigger').dropdown({ "coverTrigger": false });
      $('select').formSelect();
    }.bind(this), 1);
  }

  private formataDocumento(documento?: string, tipoDocumento?: number): string {
    let documentoFormatado = '';

    if (isNullOrUndefined(documento) || isNullOrUndefined(tipoDocumento)) {
      return documentoFormatado;
    } else {
      if (documento.length == 0) {
        return documentoFormatado;
      }
    }

    if (tipoDocumento == 1) {
      if (documento.length === 14) {
        documentoFormatado = documento.substring(0, 2).concat(".").concat(documento.substring(2, 5))
          .concat(".").concat(documento.substring(5, 8)).concat("/")
          .concat(documento.substring(8, 12)).concat("-").concat(documento.substring(12, 14));
      }
    } else if (tipoDocumento == 2) {
      if (documento.length === 11) {
        documentoFormatado = documento.substring(0, 3).concat(".").concat(documento.substring(3, 6))
          .concat(".").concat(documento.substring(6, 9)).concat("-")
          .concat(documento.substring(9, 11));
      }
    } else {
      documentoFormatado = documento;
    }
    return documentoFormatado;
  }

}
