export class SolicitacaoGetVO {
  id: number;
  protocolo: string;
  idDomOrigemSolitacao: number;
  descricaoDomOrigemSolicitacao: string;
  idDomTipoSolicitacao: number;
  descricaoDomTipoSolicitcao: string;
  idStatusSolicitacao: number;
  descricaoStatusSolicitacao: string;
  idSolicitacaoWorkflow: number;
  descricaoSolicitacaoWorkflow: string;
  idEtapaSolicitacaoWorkflow: number;
  descricaoEtapaSolicitacaoWorkflow: string;
  idPessoa: number;
  nomeDoSolicitante: string;
  descricaoTipoDocumentoDoSolicitante: string;
  documento: string;
  emailDoSolicitante: string;
  usuarioCriacao: string;
  dataCriacao: string;
  usuarioAlteracao: string;
  dataAlteracao: string;
  usuarioCancelamento: string;
  dataCancelamento: string;
  listaDeInteracoesDaSolicitacao: ListaDeInteracoesDaSolicitacao[];
}

interface ListaDeInteracoesDaSolicitacao {
  id: number;
  dataCriacao: string;
  texto: string;
  idProximaEtapa: number;
  descProximaEtapa: string;
  idGrupoAtendimento: number;
  descGrupoAtendimento: string;
  idUsuarioCriacao: number;
  nomeUsuarioCriacao: string;
  listaDeArquivo: ListaDeArquivo[];
}

interface ListaDeArquivo {
  id: number;
  idSolicitacaoInteracao: number;
  idArquivoAnexo: string;
  descArquivo: string;
  nomeUsuarioCriacao: string;
  dataCriacao: string;
  nomeUsuarioAlteracao: string;
  dataAlteracao: string;
  nomeUsuarioCancelamento: string;
  dataCancelamento: string;
  numberOfRows: number;
  extensao: string;
  mimeType: string;
}
