import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './../../../base/util/base.service';
import { environment } from './../../../../environments/environment';

import { Observable } from 'rxjs/Observable';

@Injectable({
    providedIn: 'root'
})
export class ZSUploadService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    public download(arquivo): void {
        const uri = environment.baseUrl + 'arquivos-anexo/' + arquivo.idArquivoAnexo;
        this.getFile(uri).subscribe(x => {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            const newBlob = new Blob([x], { type: arquivo.mimeType });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);

            const link = document.createElement('a');
            link.href = data;
            link.download = "download." + arquivo.extensao;
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        });
    }
}
