import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../base/util/base.service';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';

@Injectable()
export class StatusWorkflowService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    inativaWorkflow(workflow) {
        const urlDestino = environment.baseUrl + "solicitacao-workflow/Cancel";
        return this.put(urlDestino, workflow)
    }

    reativaWorkflow(workflow) {
        const urlDestino = environment.baseUrl + "solicitacao-workflow/Reactive";
        return this.put(urlDestino, workflow);
    }

    recuperarWorkflow(id: string) {
        let urlDestino = environment.baseUrl + "solicitacao-just-workflow";
        let idItem: string;
        if (!isNullOrUndefined(id)) {
          idItem = "/" + id;
          urlDestino += idItem;
        }
        return this.get(urlDestino);
    }
}
