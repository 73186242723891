import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'mascaraDocumento'
})
export class DocumentoPipe implements PipeTransform{
    documentoFormatado: string;

    transform(documento: string){
        if(documento){             
            if(documento.length === 11){
               this.documentoFormatado = documento.substring(0,3).concat(".").concat(documento.substring(3,6))                                      
                                      .concat(".").concat(documento.substring(6,9)).concat("-")
                                      .concat(documento.substring(9,11))
            } 
            if(documento.length === 14){
               this.documentoFormatado = documento.substring(0,2).concat(".").concat(documento.substring(2,5))                                      
                                      .concat(".").concat(documento.substring(5,8)).concat("/")
                                      .concat(documento.substring(8,12)).concat("-").concat(documento.substring(12,14))
            } 
        }

        return this.documentoFormatado;
    }
}