import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../../base/util/utils';

// Service
import { DeletarOrigemSolicitacaoService } from './deletar-origem-solicitacao.service';

// VO
import { OrigemSolicitacaoVO } from '../../../../base/vo/origem-solicitacao';

// PrimeNg
import { Message } from 'primeng/components/common/api';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-deletar-origem-solicitacao',
  templateUrl: './deletar-origem-solicitacao.component.html',
  styleUrls: ['./deletar-origem-solicitacao.component.css']
})
export class DeletarOrigemSolicitacaoComponent implements OnInit {

  @Input() refazerBusca: any;
  @Output() respostaCriaAtualiza = new EventEmitter;

  public tipoOrigemSolicitacao: OrigemSolicitacaoVO = new OrigemSolicitacaoVO;
  public isLoading: boolean;

  public displayModalDeletarOrigemSolicitacao: boolean;

  public idItem = '';
  public msgs: Message[] = [];

  constructor(private deletarOrigemSolicitacaoService: DeletarOrigemSolicitacaoService,
              public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
  }

  deletarOrigemSolicitacao() {
    this.isLoading = true;
    this.deletarOrigemSolicitacaoService.deletarOrigemSolicitacao(this.idItem).subscribe(
      response => {
        const mensagem: any = response;
        if (!isNullOrUndefined(response) && mensagem.success) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogDeletarOrigemSolicitacao();
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(mensagem.message);
          this.hideDialogDeletarOrigemSolicitacao();
        }
      },
      erro => {
        this.serviceUtils.showError('Erro ao Tentar Excluir');
        this.hideDialogDeletarOrigemSolicitacao();
        this.isLoading = false;
        console.log(erro);
      });
  }

  recuperarOrigemSolicitacao(idItem: string) {
    this.isLoading = true;
    this.deletarOrigemSolicitacaoService.recuperarOrigemSolicitacao(idItem).subscribe(
      data => {
        this.tipoOrigemSolicitacao = data;
        this.isLoading = false;

        setTimeout(() => {
          $('select').formSelect();
        }, 1);
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  showDialogDeletaOrigemSolicitacao(idItem) {
    this.idItem = idItem;
    this.recuperarOrigemSolicitacao(idItem);
    this.displayModalDeletarOrigemSolicitacao = true;
  }

  hideDialogDeletarOrigemSolicitacao() {
    this.idItem = '';
    this.displayModalDeletarOrigemSolicitacao = false;
    this.respostaCriaAtualiza.emit(1);
  }

}
