import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { BaseService } from '../../../base/util/base.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class GrupoAtendimentoService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }
    recuperarGrupoAtendimento(filtros: any, page: number) {
        let urlDestino = environment.baseUrl + 'grupo-atendimento';
        urlDestino += this.parametrosUrl(filtros, page, 10);
        console.log('URL DESTINY: ' + urlDestino);
        return this.get(urlDestino);
    }
    parametrosUrl(filtros: any, page: number, pageSize: number): string {

        let url = '?page=' + page + '&pageSize=' + pageSize;

        if (!isNullOrUndefined(filtros.Nome)) {
            url = url + '&nome=' + filtros.Nome;
        }
        if (!isNullOrUndefined(filtros.status)) {
            url = url + '&Status=' + filtros.status;
        }


        return url;
    }
    recuperarNomes(nome: string) {
        let urlDestino = environment.baseUrl + 'grupo-atendimento?page=1&pageSize=5';
        let nomeRamo: string;
        if (!isNullOrUndefined(nome)) {
            nomeRamo = '&nome=' + nome;
            urlDestino += nomeRamo;
        }
        return this.get(urlDestino);
    }
    cancelaGrupoAtendimento(GrupoAtendimento) {
        const urlDestino = environment.baseUrl + 'grupo-atendimento';
        return this.put(urlDestino, GrupoAtendimento);
    }
    listaStatus() {
        const urlDestino = environment.baseUrl + 'enum/origem-solicitacao/status';
        return this.get(urlDestino);
    }
}
