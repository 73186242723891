import { Injectable } from "@angular/core";
import { BaseService } from "../../../../base/util/base.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { isNullOrUndefined } from "util";

@Injectable()
export class GerenciaWorkflowService extends BaseService {
    constructor(public http: HttpClient) {
        super(http);
    }

    recuperarWorkflow(idworkflow: string) {
        let urlDestino = environment.baseUrl + "solicitacao-workflow";
        let idItem: string;
        if (!isNullOrUndefined(idworkflow)) {
            idItem = "/" + idworkflow;
            urlDestino += idItem;
        }
        return this.get(urlDestino);
    }

    recuperarGrupoAtendimento(status: number) {
        return this.get(environment.baseUrl + "grupo-atendimento?status=" + status);
    }

    recuperarTipoSolicitacao(status: number) {
        return this.get(environment.baseUrl + "tipo-solicitacao?status="+ status);
    }

    editarWorkflow(workflow: any) {
        return this.put((environment.baseUrl + "solicitacao-workflow"), workflow);
    }

    criarWorkflow(workflow: any) {
        return this.post((environment.baseUrl + "solicitacao-workflow"), workflow);
    }
}
