import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Message } from 'primeng/components/common/api';
import { isNullOrUndefined } from 'util';
import { EnumService } from './../../../../../base/enum/enum.service';
import { CalendarioService } from './../../../../../base/util/calendario.service';
import { Utils } from './../../../../../base/util/utils';
import { DadosTipoPessoaComponent } from './../../../pessoas-config/dados-tipo-pessoa/dados-tipo-pessoa.component';
import { DoadorVO } from './../../../../../base/vo/doador';
import { NovoDoadorService } from './novo-doador.service';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-novo-doador',
  templateUrl: './novo-doador.component.html',
  styleUrls: ['./novo-doador.component.css'],
  providers: [NovoDoadorService]
})
export class NovoDoadorComponent implements OnInit {

  @Input() doador: DoadorVO;
  @Input() IdItem: number;

  @Input() refazerBusca: any;
  @Output() respostaCriaAtualiza = new EventEmitter;
  @Output() doadorChange = new EventEmitter<DoadorVO>();

  @ViewChild('novoDoadorDialog') novoDoadorDialogVC: any;

  @ViewChild('dadosTipoPessoa')
  private dadosTipoPessoa: DadosTipoPessoaComponent;

  public isErro: boolean;
  public calendarLocale: any;
  public visibleForm: boolean;
  public isLoading: boolean;
  public displayModalNovoDoador: boolean;
  public validaEventButton: boolean;
  public idItem: any = '';
  public listaSexo: any;
  public editDoador: boolean;
  public isNomePaiValido: boolean = true;
  public isNomeMaeValido: boolean = true;
  public isSexoValido: boolean = true;
  public isDataNascimentoValido: boolean = true;
  public isDataValidadeCnhValido: boolean = true;
  public ValidaDadosDnv: boolean;
  public isDataValidadeValido: boolean = true;
  public dataNascimento: Date;
  public anoCorrente: Date = new Date();
  public msgs: Message[] = [];

  constructor(private calendarioService: CalendarioService, private enumService: EnumService,
    public serviceUtils: Utils, private novoDoadorService: NovoDoadorService) { }

  ngOnInit() {
    M.AutoInit();
    this.carregarSexo();
    if (!this.idItem) {
      this.limparForm();
      this.dadosTipoPessoa.limparForm();
    }

    this.calendarLocale = this.calendarioService.getLocaleCalendar();
    if (!this.doador) {
      this.doador = new DoadorVO();
    }

  }

  showForm() {
    this.visibleForm = true;

    setTimeout(function () {
      $('html, body').animate({
      }, 1000);
    }, 1);

  }

  showDialogNovoItem(idItem) {
    this.limparForm();
    this.dadosTipoPessoa.limparForm();
    this.dadosTipoPessoa.escondeTipoPessoa = true;
    this.dadosTipoPessoa.pf = true;
    this.dadosTipoPessoa.getDisplayTipo();
    if (idItem) {
      this.idItem = idItem;
      this.editDoador = true;
      this.dadosTipoPessoa.edit = true;
      this.recuperarDoador(idItem);
    } if (!idItem) {
      this.idItem = '';
      this.editDoador = false;
      this.doador = new DoadorVO();

    }
    this.displayModalNovoDoador = true;

    if (this.novoDoadorDialogVC) {
      setTimeout(this.novoDoadorDialogVC.center.bind(this.novoDoadorDialogVC), 0);
    }

    this.showForm();
    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }

  enviarDoador() {
    this.validaEventButton = true;
    this.atribuiDados();
    this.ValidaDadosDnv = true;
    this.validaDados();
    this.dadosTipoPessoa.validaDados();
    if ((this.dadosTipoPessoa.validaDados()) && (this.validaDados())) {
      this.novoDoadorService.enviarDoador(this.doador).subscribe(response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.doador.id = response.data.id;
          this.validaEventButton = false;
          this.doadorChange.emit(this.doador);
          this.hideDialogNovoDoador();
        } else {
          if (!isNullOrUndefined(response.data[0])) {
            this.msgs = this.serviceUtils.showError(response.data[0].message);
          } else {
            this.msgs = this.serviceUtils.showError(response.message);
          }
        }
        this.isLoading = false;
        this.dadosTipoPessoa.isLoading = false;
      },
        error => {
          this.msgs = this.serviceUtils.showError('');
          this.isLoading = false;
          this.dadosTipoPessoa.isLoading = false;
          console.log(error);
        });
    }
  }


  atribuiDados() {
    this.dadosTipoPessoa.contadorValidacao = true;
    if (this.dadosTipoPessoa.validaDados()) {
      if (!this.dadosTipoPessoa.edit) {
        this.doador.id = this.dadosTipoPessoa.dadosContato.idPessoa;
      }
      this.doador.nome = this.dadosTipoPessoa.dadosContato.nome;
      this.doador.tipoPessoa = this.dadosTipoPessoa.dadosContato.tipoPessoa;
      this.doador.tipoDocumento = this.dadosTipoPessoa.dadosContato.tipoDocumento;
      if (this.dadosTipoPessoa.dadosContato.tipoDocumento == 2) { this.doador.documento = this.dadosTipoPessoa.dadosContato.CPF; }
      if (this.dadosTipoPessoa.dadosContato.tipoDocumento == 3) { this.doador.documento = this.dadosTipoPessoa.dadosContato.RNE; }
      this.doador.rg = this.dadosTipoPessoa.dadosContato.RG;
      this.doador.rgOrgaoExpedidor = this.dadosTipoPessoa.dadosContato.orgaoExpedidor;
      this.doador.dataEmissaoRG = this.dadosTipoPessoa.dadosContato.dataEmissao;
    }
  }

  atualizarDoador() {
    this.atribuiDados();
    this.ValidaDadosDnv = true;
    this.validaEventButton = true;
    this.validaDados();
    this.dadosTipoPessoa.validaDados();
    if ((this.dadosTipoPessoa.validaDados()) && (this.validaDados())) {
      this.novoDoadorService.atualizaDoador(this.doador).subscribe(
        response => {
          if (response.success && !isNullOrUndefined(response.data)) {
            this.msgs = this.serviceUtils.showSuccess();
            this.doadorChange.emit(this.doador);
            this.hideDialogNovoDoador(); // o hide limpa o this.doador
            this.validaEventButton = false;
            this.respostaCriaAtualiza.emit(1);
          } else {
            if (!isNullOrUndefined(response.data[0])) {
              this.msgs = this.serviceUtils.showError(response.data[0].message);
            } else {
              this.msgs = this.serviceUtils.showError(response.message);
            }
          }
          this.isLoading = false;
          this.dadosTipoPessoa.isLoading = false;
        },
        error => {
          this.msgs = this.serviceUtils.showError('');
          this.isLoading = false;
          this.dadosTipoPessoa.isLoading = false;
          console.log(error);
        }
      );
    }
  }

  carregarSexo() {
    this.enumService.recuperaSexoPessoa().subscribe(
      data => {
        this.listaSexo = data;
      },
      erro => {
        this.isErro = true;
        this.listaSexo = null;
      }
    );
  }

  limparForm() {
    this.doador = new DoadorVO();
    this.doador.sexo = null;
    setTimeout(() => {
      $('select').formSelect();
    }, 1);
    this.dadosTipoPessoa.limparForm();
    this.dadosTipoPessoa.contadorValidacao = false;
    this.dadosTipoPessoa.isNomeValido = true;
    this.dadosTipoPessoa.isRgValido = true;
    this.dadosTipoPessoa.isDataEmissaoValido = true;
    this.dadosTipoPessoa.isOrgaoExpedidorValido = true;
    this.dadosTipoPessoa.isTipoPessoaValido = true;
    this.dadosTipoPessoa.isTipoDocumentoValido = true;
    this.dadosTipoPessoa.isCpfValido = true;
    this.dadosTipoPessoa.isRneValido = true;
    this.dadosTipoPessoa.isCnpjValido = true;
    this.dadosTipoPessoa.contadorValidacao = false;
    this.isDataNascimentoValido = true;
    this.isDataValidadeCnhValido = true;
    this.isNomeMaeValido = true;
    this.isNomePaiValido = true;
    this.isSexoValido = true;
    this.ValidaDadosDnv = false;
  }

  formatarData(data: string): string {
    let splitString;
    let dataFormatada;
    splitString = data.split('/');
    dataFormatada = (splitString[1] + '/' + splitString[0] + '/' + splitString[2]);
    return dataFormatada;
  }

  recuperarDoador(idItem: string) {
    this.isLoading = true;
    this.dadosTipoPessoa.isLoading = true;
    this.novoDoadorService.recuperarDoador(idItem).subscribe(
      data => {
        this.doador = data;
        setTimeout(() => {
          $('select').formSelect();
        }, 1);

        if (this.novoDoadorDialogVC) {
          setTimeout(this.novoDoadorDialogVC.center.bind(this.novoDoadorDialogVC), 0);
        }
        
        this.carregaDadosGerais();
        this.isLoading = false;
        this.dadosTipoPessoa.isLoading = false;
      },
      erro => {
        this.isLoading = false;
        this.dadosTipoPessoa.isLoading = false;
        console.log(erro);
      }
    );
  }

  carregaDadosGerais() {
    this.dadosTipoPessoa.dadosContato.nome = this.doador.nome;
    this.dadosTipoPessoa.dadosContato.tipoDocumento = this.doador.tipoDocumento;
    if (this.doador.tipoDocumento == 2) {
      this.dadosTipoPessoa.dadosContato.CPF = this.doador.documento;
    }
    if (this.doador.tipoDocumento == 3) {
      this.dadosTipoPessoa.dadosContato.RNE = this.doador.documento;
    }
    this.dadosTipoPessoa.dadosContato.RG = this.doador.rg;
    this.dadosTipoPessoa.dadosContato.orgaoExpedidor = this.doador.rgOrgaoExpedidor;
    this.dadosTipoPessoa.dadosContato.dataEmissao = this.doador.dataEmissaoRG;
  }

  hideDialogNovoDoador() {
    this.limparForm();
    this.dadosTipoPessoa.limparForm();
    this.displayModalNovoDoador = false;
    this.validaEventButton = false;
    this.respostaCriaAtualiza.emit(1);

  }

  hideDialogAtualizaDoador() {
    this.limparForm();
    this.dadosTipoPessoa.limparForm();
    this.displayModalNovoDoador = false;
  }

  validaDados(): boolean {
    if (this.ValidaDadosDnv) {
      const isValido = new Array<any>();
      //isValido.push(this.isNomePaiValido = !this.serviceUtils.isStringNullOrBlank(this.doador.nomePai));
      //isValido.push(this.isNomeMaeValido = !this.serviceUtils.isStringNullOrBlank(this.doador.nomeMae));
      isValido.push(this.isSexoValido = !isNullOrUndefined(this.doador.sexo));
      // if (new Date(this.doador.dataNascimento) <= new Date()) {
      //   isValido.push(this.isDataNascimentoValido = !isNullOrUndefined(this.doador.dataNascimento));
      // } else {
      //   isValido.push(this.isDataNascimentoValido = false);
      // }
      if (!isNullOrUndefined(this.doador.dataValidade)) {
        if (new Date(this.doador.dataValidade) <= new Date(this.doador.dataNascimento)) {
          isValido.push(this.isDataValidadeCnhValido = false);
        }
      }
      return !isValido.some(function isAnyFalse(element) { return (element === false); });
    }
  }

}
