import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'mascaraRg'
})
export class RgPipe implements PipeTransform{
   rgFormatado: string;

    transform(rg: string){
        if(rg){            
            if(rg.length === 10){
               this.rgFormatado = rg.substring(0,2).concat(".").concat(rg.substring(2,5))                                      
                                      .concat(".").concat(rg.substring(5,8)).concat("-")
                                      .concat(rg.substring(8,10))
            } 
            if(rg.length === 9){
                this.rgFormatado = rg.substring(0,2).concat(".").concat(rg.substring(2,5))                                      
                                       .concat(".").concat(rg.substring(5,8)).concat("-")
                                       .concat(rg.substring(8,9))
             } 
        }

        return this.rgFormatado;
    }
}