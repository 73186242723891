import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'customDecimalFormat'
})
export class CustomDecimalFormat implements PipeTransform {
    decimalFormatado: string;

    transform(decimalInput: number) {

        // Variaveis;
        var decimalString = "";
        var ponto
        var ehInteiro

        // Prepara o decimal para trabalhar como string + popula variaveis de controle;
        decimalString = decimalInput.toString();
        ponto = decimalString.indexOf('.') != -1 ? decimalString.indexOf('.') : decimalString.length;
        ehInteiro = decimalString.indexOf('.') == -1 ? true : false;
        decimalString = decimalString.replace('.', '');

        if (decimalString.length > 0) {

            this.decimalFormatado = decimalString.substring(0, ponto).concat(",").concat(decimalString.substring((ponto), (ponto + 2)))

            if (ehInteiro) {
                this.decimalFormatado = this.decimalFormatado.concat("00")
            }
            else if (this.decimalFormatado.length <= 3) {
                this.decimalFormatado = this.decimalFormatado.concat("0")
            }
        }

        return this.decimalFormatado;
    }
}