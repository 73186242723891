export class ContatoPessoaVO {

    constructor(
        public id?: number,
        public idPessoa?: number,
        public nome?: string,
        public email?: string,
        public telefoneFixo?: string,
        public telefoneFax?: string,
        public telefoneCelular?: string,
        public observacao?: string,
        public status?: number,
        public descricaoStatus?: string,
        public idTipoContato?: number,
        public descricaoTipoContato?: string,
        public nomeUsuarioCriacao?: string,
        public dataCriacao?: Date,
        public nomeUsuarioAlteracao?: string,
        public dataAlteracao?: Date,
        public nomeUsuarioCancelamento?: string,
        public dataCancelamento?: Date,
        public numberOfRows?: number,
        public nomePessoa?: string,
        public tipoPessoa?: number
    ) {}
}
