import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Utils } from './../../../../../base/util/utils';
import { isNullOrUndefined } from 'util';

// VO
import { GrupoAtendimentoUsuarioVO } from './../../../../../base/vo/grupo-atendimento-usuario';

// Service
import { NovoGrupoAtendimentoUsuarioService } from './novo-grupo-atendimento-usuario.service';

// PrimeNg
import { Message } from 'primeng/components/common/api';

// Component

interface FiltroGrupoAtendimentoUsuario {
  Nome: string;
  Status: number; // caso seja implementado a opção de filtro pelo status futuramente
  idGrupoAtendiento: number;
}

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-novo-grupo-atendimento-usuario',
  templateUrl: './novo-grupo-atendimento-usuario.component.html',
  styleUrls: ['./novo-grupo-atendimento-usuario.component.css']
})
export class NovoGrupoAtendimentoUsuarioComponent implements OnInit {

  @ViewChild('modalNovoGrupoAtenUsuario') modalNovoGrupoAtenUsuarioVC: any;

  @Output() respostaCadastrado = new EventEmitter();


  @Input() refazerBusca: any;

  public visibleForm: boolean;
  public displayModalNovoGrupoAtenUsuario: boolean;

  public activeAutocompleteNome: boolean;
  public activeAutocompleteDescricao: boolean;

  public listaGrupoAtendimentoUsuario: any;

  public isLoading: boolean;
  public numberOfRecords = 0;
  public resultFound: boolean;
  public resultNotFound: boolean;
  public actualpage = 0;
  public salvaPagina = 0;
  public msgs: Message[] = [];
  public listarStatus: any;

  public editItem: boolean;
  public teste: any;

  public Nome = '';




  public grupoAtendimentoUsuario: Array<GrupoAtendimentoUsuarioVO>;
  public filtros: FiltroGrupoAtendimentoUsuario;
  public listaNomes: any;

  public isItensBuscados: boolean;

  constructor(private grupoAtendimentoUsuarioService: NovoGrupoAtendimentoUsuarioService, public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
    this.filtros = {} as FiltroGrupoAtendimentoUsuario;
    this.grupoAtendimentoUsuario = Object.assign(new Array<GrupoAtendimentoUsuarioVO>(), this.grupoAtendimentoUsuario);
    this.recuperaGrupoAtendimentoUsuario(1);
  }

  showDialog(item?: Array<GrupoAtendimentoUsuarioVO>, idGrupo?: number) {
    if (isNullOrUndefined(item)) {
      this.editItem = false;
    } else {
      this.editItem = true;
      this.grupoAtendimentoUsuario = item;
      this.filtros.idGrupoAtendiento = idGrupo;
    }
    this.recuperaGrupoAtendimentoUsuario(1);
    this.displayModalNovoGrupoAtenUsuario = true;
  }
  buscarGrupoAtendimentoUsuario() {
    this.isItensBuscados = true;
    this.populaFiltros();
    this.recuperaGrupoAtendimentoUsuario(1);
  }

  buscarNomes(event) {
    this.grupoAtendimentoUsuarioService.recuperarNomes(event.query).subscribe(
      data => {
        this.listaNomes = data.map(function (el) {
          return el.nome;
        }).filter(this.serviceUtils.onlyUnique);
      },
      erro => {
        console.log(erro);
      }
    );
  }


  refazBusca(respostaNovoItem) {
    if (this.refazBusca && this.isItensBuscados) {
      this.buscarGrupoAtendimentoUsuario();
    }
  }

  public recuperaGrupoAtendimentoUsuario(page: number) {
    if (page <= 1) {
      this.actualpage = 1;
    }
    this.isLoading = true;
    this.grupoAtendimentoUsuarioService.recuperarGrupoAtendimentoUsuario(this.filtros, page).subscribe(
      data => {
        if (this.displayModalNovoGrupoAtenUsuario) {
          setTimeout(this.modalNovoGrupoAtenUsuarioVC.center.bind(this.modalNovoGrupoAtenUsuarioVC), 0);
        }
        this.listaGrupoAtendimentoUsuario = data;
        if (!isNullOrUndefined(this.listaGrupoAtendimentoUsuario) && this.listaGrupoAtendimentoUsuario.length > 0) {
          this.numberOfRecords = this.listaGrupoAtendimentoUsuario[0].numberOfRows;
          this.resultFound = true;
          this.resultNotFound = false;
          this.isLoading = false;
          this.salvaPagina = page;
        } else {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
        }
      },
      erro => {
        this.resultFound = false;
        this.resultNotFound = true;
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  paginate(event) {
    this.recuperaGrupoAtendimentoUsuario(event.page + 1);
    this.actualpage = event.first;
  }

  populaFiltros() {
    this.filtros.Nome = this.Nome;
    this.filtros.Status = 1;

  }

  limpaNome() {
    this.Nome = null;
  }
  //#region CloseDialogs
  hideDialog(controle?) {
    if (controle == 1) {
      this.respostaCadastrado.emit(1);
    }
    this.Nome = null;
    this.filtros.Nome = null;
    this.displayModalNovoGrupoAtenUsuario = false;

  }

  //#endregion
  //#region CheckBox
  private verificaItemChecked(item: any) {
    if (!isNullOrUndefined(this.grupoAtendimentoUsuario.find)) {
      if (this.grupoAtendimentoUsuario.find(elemento => elemento.idUsuario == item.id) != undefined) {
        return true;
      } else {
        return false;
      }
    }
  }
  marcarItem(item: any) {

    const x = Object.assign(new Array<GrupoAtendimentoUsuarioVO>(), item);
    x.idUsuario = x.id;
    x.id = null;

    if (this.grupoAtendimentoUsuario.some(elemento => elemento.idUsuario == item.id)) {
      // Se o item já existir, remove
      this.grupoAtendimentoUsuario.forEach((element, index) => {
        if (element.idUsuario == x.idUsuario) {
          this.grupoAtendimentoUsuario.splice(index, 1);
        }
      });
    } else {
      // Se não insere
      this.grupoAtendimentoUsuario.push(x);
    }
  }

  //#endregion

  //#region EventEmitter
  enviarGrupoAtendimento() {
    this.respostaCadastrado.emit(this.grupoAtendimentoUsuario);
    this.hideDialog();
  }
  atualizarGrupoAtendimento() {
    this.respostaCadastrado.emit(this.grupoAtendimentoUsuario);
    this.hideDialog();
  }

  //#endregion

  //#region exibe Apenas Ativos e selecionados
  verificaExibicao(obj) {
    if (this.grupoAtendimentoUsuario.length > 0) {
      let control = false;
      if (obj.status == 1) {
        control = true;
      } else if (this.grupoAtendimentoUsuario.find(elemento => elemento.idUsuario == obj.id) != undefined) {
        control = true;
      }

      return control;
    }
  }
  //#endregion
}


