import { BaseService } from "../../../../base/util/base.service";
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from "util";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DeletarWorkflowService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    deletarWorkflow(idItem) {
        const urlDestino = environment.baseUrl + "solicitacao-workflow";
        return this.delete(urlDestino + "?id=" + idItem, "");
    }

    recuperarWorkflow(id: string) {
        let urlDestino = environment.baseUrl + "solicitacao-just-workflow";
        let idItem: string;
            if (!isNullOrUndefined(id)) {
                idItem = "/" + id;
                urlDestino += idItem;
            }
        return this.get(urlDestino);
    }

}
