import { Component, ElementRef, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CalendarioService } from '../../util/calendario.service';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'app-date-time',
    templateUrl: './zs-datetime.component.html',
    styleUrls: ['./zs-datetime.css'],
    animations: [
        trigger('overlayAnimation', [
            state('visible', style({
                transform: 'translateY(0)',
                opacity: 1
            })),
            state('visibleTouchUI', style({
                transform: 'translate(-50%,-50%)',
                opacity: 1
            })),
            transition('void => visible', [
                style({ transform: 'translateY(5%)', opacity: 0 }),
                animate('225ms ease-out')
            ]),
            transition('visible => void', [
                animate(('195ms ease-in'),
                    style({
                        opacity: 0,
                        transform: 'translateY(5%)'
                    }))
            ]),
            transition('void => visibleTouchUI', [
                style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)' }),
                animate('225ms ease-out')
            ]),
            transition('visibleTouchUI => void', [
                animate(('195ms ease-in'),
                    style({
                        opacity: 0,
                        transform: 'translate3d(-50%, -40%, 0) scale(0.9)'
                    }))
            ])
        ])
    ],
    host: {
        '[class.ui-inputwrapper-filled]': 'filled',
        '[class.ui-inputwrapper-focus]': 'focus'
    }

})

export class ZsDateTimeComponent implements OnInit, OnDestroy, ControlValueAccessor {

    @Input() icon: string = 'pi pi-calendar';
    @Input() type = 'text';
    @Input() slotChar =  '__/__/____'; // 'dd/mm/yyyy hh:mm';
    @Input() autoClear = true;
    @Input() style: string;
    @Input() inputId: string;
    @Input() styleClass: string;
    @Input() placeholder: string;
    @Input() size: number;
    @Input() maxlength: number;
    @Input() tabindex: string;
    @Input() ariaLabel: string;
    @Input() ariaRequired: boolean;
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() unmask: boolean;
    @Input() name: string;
    @Input() required: boolean;
    @Input() characterPattern = '[A-Za-z]';
    @Input() autoFocus: boolean;
    @ViewChild('input') inputViewChild: ElementRef;

    @Output() onComplete: EventEmitter<any> = new EventEmitter();
    @Output() onFocus: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();

    input: HTMLInputElement;
    filled: boolean;
    defs: any;
    tests: any[];
    partialPosition: any;
    firstNonMaskPos: number;
    lastRequiredNonMaskPos: any;
    len: number;
    oldVal: string;
    buffer: any;
    defaultBuffer: string;
    focusText: string;
    caretTimeoutId: any;
    androidChrome: boolean;
    focus: boolean;
    value: any;
    _mask: string = "dd/MM/yyyy";
    ticksTo1970: number;
    calendarLocale: any;

    @Input() desabilitarCalendario: boolean = false;
    @Input() calendarioSuperior: boolean = false;
    @Input() habilitarHora: boolean = false;

    _dataValida: boolean = true;
    @Input() set dataValida(value: boolean) {
        this._dataValida = value;
    }
    get dataValida(): boolean {
        return this._dataValida;
    }

    @Input() hourFormat = '24';
    @Input() showTime: boolean = false;
    @Input() showSeconds = false;

    private _allowDay: boolean;

    _bindingValue: Date;
    @Input() set bindingValue(value: Date) {

        this._bindingValue = value;
            this.bindingValueChange.emit(this._bindingValue);

        if (isNullOrUndefined(this._bindingValue)) {
            if (this.focusText != "" || isNullOrUndefined( this.focusText) && this.isReady) {
                this.isReady = false;
            }
            this.value = null;
            this.inputViewChild.nativeElement.value = null;
        } else {

            this.isReady = true;

            this.value = value;

            this.initMask();

            const data = this.formatDateTime(new Date(value));

            this.buffer[0] = data[0];
            this.buffer[1] = data[1];

            this.buffer[3] = data[3];
            this.buffer[4] = data[4];

            this.buffer[6] = data[6];
            this.buffer[7] = data[7];
            this.buffer[8] = data[8];
            this.buffer[9] = data[9];

            if (this.habilitarHora) {
                this.buffer[11] = data[11];
                this.buffer[12] = data[12];

                this.buffer[14] = data[14];
                this.buffer[15] = data[15];
            }

            this.writeBuffer();
        }

    }
    get bindingValue(): Date {
        return this._bindingValue;
    }
    @Output() bindingValueChange = new EventEmitter<Date>();

    _dataCalendario: Date = new Date();
    @Input() set dataCalendario(value: Date) {

        this.isReady = true;

        this._dataCalendario = value;

        const data = this.formatDateTime(new Date(value));

        this.buffer[0] = data[0];
        this.buffer[1] = data[1];
        this.buffer[3] = data[3];
        this.buffer[4] = data[4];
        this.buffer[6] = data[6];
        this.buffer[7] = data[7];
        this.buffer[8] = data[8];
        this.buffer[9] = data[9];

        if (this.habilitarHora) {
            this.buffer[11] = data[11];
            this.buffer[12] = data[12];

            this.buffer[14] = data[14];
            this.buffer[15] = data[15];
        }

        this.writeBuffer();

        this.writeValue(data);

        this.updateDatabindingValue();

        this._calendarFocused = true;

    }
    get dataCalendario(): Date {
        return this._dataCalendario;
    }

    isReady: boolean;

    _zlabel: string;
    @Input() set zlabel(value: string) {
        this._zlabel = value;
    }
    get zlabel(): string {
        return this._zlabel;
    }

    // Calendar
    overlayVisible: boolean;

    onModelChange: Function = () => { };
    onModelTouched: Function = () => { };

    constructor(
        private CalendarioService: CalendarioService
    ) { }

    ngOnInit() {

        this.calendarLocale = this.CalendarioService.getLocaleCalendar();

        if (this.habilitarHora === true) {
            this._mask = "dd/MM/yyyy hh:mm";
        } else {
            this._mask = "dd/MM/yyyy";
        }

        this.initMask();

        if (this.value === undefined || this.value == null) {

        } else {

            const data = this.formatDateTime(new Date(this.value));

            this.buffer[0] = data[0];
            this.buffer[1] = data[1];
            this.buffer[3] = data[3];
            this.buffer[4] = data[4];
            this.buffer[6] = data[6];
            this.buffer[7] = data[7];
            this.buffer[8] = data[8];
            this.buffer[9] = data[9];

            if (this.habilitarHora) {
                this.buffer[11] = data[11];
                this.buffer[12] = data[12];

                this.buffer[14] = data[14];
                this.buffer[15] = data[15];
            }

            this.writeBuffer();
        }

    }

    @Input() get mask(): string {
        return this._mask;
    }

    set mask(val: string) {
        this._mask = val;

        this.initMask();
        this.writeValue('');
        this.onModelChange(this.value);
    }

    initMask() {
        this.tests = [];
        this.partialPosition = this.mask.length;
        this.len = this.mask.length;
        this.firstNonMaskPos = null;
        this.defs = {
            'd': '[0-9]',
            'D': '[0-9]',
            'm': '[0-9]',
            'M': '[0-9]',
            'y': '[0-9]',
            'Y': '[0-9]',
            'h': '[0-9]',
            'H': '[0-9]',
            's': '[0-9]',
            'S': '[0-9]'
        };

        const maskTokens = this.mask.split('');

        for (let i = 0; i < maskTokens.length; i++) {
            const c = maskTokens[i];
            if (c === '?') {
                this.len--;
                this.partialPosition = i;
            } else if (this.defs[c]) {
                this.tests.push(new RegExp(this.defs[c]));
                if (this.firstNonMaskPos === null) {
                    this.firstNonMaskPos = this.tests.length - 1;
                }
                if (i < this.partialPosition) {
                    this.lastRequiredNonMaskPos = this.tests.length - 1;
                }
            } else {
                this.tests.push(null);
            }
        }

        this.buffer = [];
        for (let i = 0; i < maskTokens.length; i++) {
            const c = maskTokens[i];
            if (c !== '?') {
                if (this.defs[c]) {
                    this.buffer.push(this.getPlaceholder(i));
                } else {
                    this.buffer.push(c);
                }
            }
        }
        this.defaultBuffer = this.buffer.join('');
    }


    registerOnChange(fn: Function): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onModelTouched = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
    }

    caret(first?: number, last?: number) {
        let range, begin, end;

        if (!this.inputViewChild.nativeElement.offsetParent || this.inputViewChild.nativeElement !== document.activeElement) {
            return;
        }

        if (typeof first === 'number') {
            begin = first;
            end = (typeof last === 'number') ? last : begin;
            if (this.inputViewChild.nativeElement.setSelectionRange) {
                this.inputViewChild.nativeElement.setSelectionRange(begin, end);
            } else if (this.inputViewChild.nativeElement['createTextRange']) {
                range = this.inputViewChild.nativeElement['createTextRange']();
                range.collapse(true);
                range.moveEnd('character', end);
                range.moveStart('character', begin);
                range.select();
            }
        } else {
            if (this.inputViewChild.nativeElement.setSelectionRange) {
                begin = this.inputViewChild.nativeElement.selectionStart;
                end = this.inputViewChild.nativeElement.selectionEnd;
            } else if (document['selection'] && document['selection'].createRange) {
                range = document['selection'].createRange();
                begin = 0 - range.duplicate().moveStart('character', -100000);
                end = begin + range.text.length;
            }

            return { begin: begin, end: end };
        }
    }

    isCompleted(): boolean {

        for (let i = this.firstNonMaskPos; i <= this.lastRequiredNonMaskPos; i++) {
            if (this.tests[i] && this.buffer[i] === this.getPlaceholder(i)) {
                return false;
            }
        }

        return true;
    }

    getPlaceholder(i: number) {
        if (i < this.slotChar.length) {
            return this.slotChar.charAt(i);
        }
        return this.slotChar.charAt(0);
    }

    seekNext(pos) {
        while (++pos < this.len && !this.tests[pos]) { }
        return pos;
    }

    seekPrev(pos) {
        while (--pos >= 0 && !this.tests[pos]) { }
        return pos;
    }

    shiftL(begin: number, end: number) {
        let i, j;

        if (begin < 0) {
            return;
        }

        for (i = begin, j = this.seekNext(end); i < this.len; i++) {
            if (this.tests[i]) {
                if (j < this.len && this.tests[i].test(this.buffer[j])) {
                    this.buffer[i] = this.buffer[j];
                    this.buffer[j] = this.getPlaceholder(j);
                } else {
                    break;
                }

                j = this.seekNext(j);
            }
        }
        this.writeBuffer();
        this.caret(Math.max(this.firstNonMaskPos, begin));
    }

    shiftR(pos) {
        let i, c, j, t;

        for (i = pos, c = this.getPlaceholder(pos); i < this.len; i++) {
            if (this.tests[i]) {
                j = this.seekNext(i);
                t = this.buffer[i];
                this.buffer[i] = c;
                if (j < this.len && this.tests[j].test(t)) {
                    c = t;
                } else {
                    break;
                }
            }
        }
    }

    handleAndroidInput(e) {
        const curVal = this.inputViewChild.nativeElement.value;
        const pos = this.caret();
        if (this.oldVal && this.oldVal.length && this.oldVal.length > curVal.length) {

            // delete ou backspace
            this.checkVal(true);
            while (pos.begin > 0 && !this.tests[pos.begin - 1]) {
                pos.begin--;
            }
            if (pos.begin === 0) {
                while (pos.begin < this.firstNonMaskPos && !this.tests[pos.begin]) {
                    pos.begin++;
                }
            }

            setTimeout(() => {
                this.caret(pos.begin, pos.begin);
                this.updateModel(e);
                if (this.isCompleted()) {
                    this.onComplete.emit();
                }
            }, 0);
        } else {
            this.checkVal(true);
            while (pos.begin < this.len && !this.tests[pos.begin]) {
                pos.begin++;
            }

            setTimeout(() => {
                this.caret(pos.begin, pos.begin);
                this.updateModel(e);
                if (this.isCompleted()) {
                    this.onComplete.emit();
                }
            }, 0);
        }
    }

    onInputBlur(e) {
        this.focus = false;

        if (!this.bindingValue || !this.isCompleted()) {
            this.isReady = false;
            this._bindingValue = undefined;
            this.bindingValueChange.emit(undefined);
        }

        this.onModelTouched();
        this.checkVal();
        this.updateFilledState();

        this.onBlur.emit(e);
        this.calendarDelay(200);

    }

    onKeyDown(e) {
        if (this.readonly) {
            return;
        }
        this.isReady = true;

        let k = e.which || e.keyCode, pos, begin, end;
        this.oldVal = this.inputViewChild.nativeElement.value;

        // backspace, delete, escape
        if (k === 8 || k === 46) {
            pos = this.caret();
            begin = pos.begin;
            end = pos.end;

            if (end - begin === 0) {
                begin = k !== 46 ? this.seekPrev(begin) : (end = this.seekNext(begin - 1));
                end = k === 46 ? this.seekNext(end) : end;
            }

            this.clearBuffer(begin, end);
            this.shiftL(begin, end - 1);
            this.updateModel(e);
            e.preventDefault();

            this.clearBuffer(0, this.buffer.length);
            this.shiftL(0,  this.buffer.length - 1);
            this._bindingValue = undefined;
            this.inputViewChild.nativeElement.value = "";
            this.inputViewChild.nativeElement.selectionStart = 0;
            this.isReady = true;
            this.inputViewChild.nativeElement.selectionEnd = 0;
            this.bindingValueChange.emit(undefined);
            this.onInputFocus(e);

        } else if (k === 13) { // enter
            this.onInputBlur(e);
            this.updateModel(e);
        } else if (k === 27) { // escape
            this.inputViewChild.nativeElement.value = this.focusText;
            this.caret(0, this.checkVal());
            this.updateModel(e);
            e.preventDefault();
        }
    }

    onKeyPress(e) {
        if (this.readonly) {
            return;
        }

        const maskTokens = this.mask.split('');

        let k = e.which || e.keyCode, pos = this.caret(), p, c, next, completed;

        if (e.ctrlKey || e.altKey || e.metaKey || k < 32  || (k > 34 && k < 41)) {// Ignore
            return;
        } else if ( k && k !== 13 ) {
            if (pos.end - pos.begin !== 0) {
                this.clearBuffer(pos.begin, pos.end);
                this.shiftL(pos.begin, pos.end - 1);
            }

            p = this.seekNext(pos.begin - 1);
            if (p < this.len) {
                c = String.fromCharCode(k);
                if (this.tests[p].test(c)) {
                    this.shiftR(p);

                    // Valida Dia
                    if (this.buffer[0] !== 'd' && this.buffer[1] !== 'd') {
                        const dia = Number(this.buffer[0] + this.buffer[1]);
                        if (dia > 31) {
                            this.buffer[0] = '3';
                            this.buffer[1] = '1';
                        }
                    }

                    // Valida Mês
                    if (this.buffer[3] !== 'm' && this.buffer[4] !== 'm') {
                        const mes = Number(this.buffer[3] + this.buffer[4]);
                        if (mes > 12) {
                            this.buffer[0] = '1';
                            this.buffer[1] = '2';
                        }
                    }

                    // Valida Ano
                    if (this.buffer[6] !== 'y' && this.buffer[7] !== 'y') {
                        const ano = Number(this.buffer[6] + this.buffer[7]);
                        if (ano > 21) {
                            this.buffer[6] = '2';
                            this.buffer[7] = '1';
                        } else if (ano < 19) {
                            this.buffer[6] = '1';
                            this.buffer[7] = '9';
                        }
                    }


                    if (this.habilitarHora) {

                        // hh
                        if (p === 11) {
                            if (c > 2) {
                                this.buffer[p] = '0';
                                p += 1;
                            }
                        }
                        if (p === 12) {
                            if (this.buffer[p - 1] === '2') {
                                if (c > '3') {
                                    c = '3';
                                }
                            }
                        }

                        // mm
                        if (p === 14) {
                            if (c > 5) {
                                this.buffer[p] = '0';
                                p += 1;
                            }
                        }

                    }


                    // Valida dd ----------------------------------------------------------------------

                    if (maskTokens[p] === 'd') {
                        // se estiver no primeiro d
                        if ((p + 1) < (this.buffer.length - 1)) {
                            if (maskTokens[p + 1] === 'd') {
                                if (c > '3') {
                                    this.buffer[p] = '0';
                                    p += 1;
                                }
                            }
                        }

                        if (p > 0) {
                            // se estiver no segundo d
                            if (maskTokens[p - 1] === 'd') {
                                if (this.buffer[p - 1] === '3' && c !== '0') { c = '1'; }
                                if (this.buffer[p - 1] === '0' && c === '0') { c = '1'; }
                            }
                        }
                    }

                    // Valida MM ----------------------------------------------------------------------

                    if (maskTokens[p] === 'M') {
                        // se estiver no primeiro d
                        if ((p + 1) < (this.buffer.length - 1)) {
                            if (maskTokens[p + 1] === 'M') {
                                if (c > '1') {
                                    this.buffer[p] = '0';
                                    p += 1;
                                }
                            }
                        }

                        if (p > 0) {
                            // se estiver no segundo d
                            if (maskTokens[p - 1] === 'M') {
                                if (this.buffer[p - 1] === '1' && c > '2') { c = '2'; }
                                if (this.buffer[p - 1] === '0' && c === '0') { c = '1'; }
                            }
                        }
                    }

                    // Valida yyyy --------------------------------------------------------------------

                    if (p === 6) {
                        if (c !== '1') {
                            c = '2';
                        }
                    }

                    if (p === 7) {
                        const ano = Number(this.buffer[6] + c);
                        if (ano > 21) {
                            this.buffer[6] = '2';
                            c = '1';
                        } else if (ano < 19) {
                            this.buffer[6] = '1';
                            c = '9';
                        }
                    }


                    this.buffer[p] = c;
                    this.writeBuffer();
                    next = this.seekNext(p);

                        this.caret(next);
                    if (pos.begin <= this.lastRequiredNonMaskPos) {
                         completed = this.isCompleted();
                     }
                }
            }
            e.preventDefault();
        }

        this.updateModel(e);

        this.updateFilledState();

        this.updateDatabindingValue();

        if (completed) {
            this.onComplete.emit();
        }
    }

    clearBuffer(start, end) {
        let i;
        for (i = start; i < end && i < this.len; i++) {
            if (this.tests[i]) {
                this.buffer[i] = this.getPlaceholder(i);
            }
        }
    }

    writeBuffer() {
        this.inputViewChild.nativeElement.value = this.buffer.join('');
    }

    checkVal(allow?: boolean) {

        let test = this.inputViewChild.nativeElement.value,
            lastMatch = -1,
            i,
            c,
            pos;

        for (i = 0, pos = 0; i < this.len; i++) {
            if (this.tests[i]) {
                this.buffer[i] = this.getPlaceholder(i);
                while (pos++ < test.length) {
                    c = test.charAt(pos - 1);
                    if (this.tests[i].test(c)) {
                        this.buffer[i] = c;
                        lastMatch = i;
                        break;
                    }
                }
                if (pos > test.length) {
                    this.clearBuffer(i + 1, this.len);
                    break;
                }
            } else {
                if (this.buffer[i] === test.charAt(pos)) {
                    pos++;
                }
                if (i < this.partialPosition) {
                    lastMatch = i;
                }
            }
        }
        if (allow) {
            this.writeBuffer();
        } else if (lastMatch + 1 < this.partialPosition) {
            if (this.autoClear || this.buffer.join('') === this.defaultBuffer) {
                if (this.inputViewChild.nativeElement.value) { this.inputViewChild.nativeElement.value = ''; }
                this.clearBuffer(0, this.len);
            } else {
                this.writeBuffer();
            }
        } else {
            this.writeBuffer();
            this.inputViewChild.nativeElement.value = this.inputViewChild.nativeElement.value.substring(0, lastMatch + 1);
        }
        return (this.partialPosition ? i : this.firstNonMaskPos);
    }

    private _calendarFocused: boolean = false;
    onCalendarFocus(event) {
        if (event !== undefined) { this._calendarFocused = true; }
    }

    onInputFocus(event) {
        if (this.readonly) {
            return;
        }

        this.focus = true;
        this.overlayVisible = !this.desabilitarCalendario;
        this.isReady = true;

        clearTimeout(this.caretTimeoutId);
        let pos;

        this.focusText = this.inputViewChild.nativeElement.value;

        pos = this.checkVal();

        this.caretTimeoutId = setTimeout(() => {
            if (this.inputViewChild.nativeElement !== document.activeElement) {
                return;
            }
            this.writeBuffer();
            if (pos === this.mask.replace('?', '').length) {
                this.caret(0, pos);
            } else {
                this.caret(pos);
            }
        }, 10);

        this.onFocus.emit(event);
    }

    onInput(event) {

        if (this.androidChrome) {
            this.handleAndroidInput(event);
        } else {
            this.handleInputChange(event);
        }
    }

    handleInputChange(event) {
        if (this.readonly) {
            return;
        }

        setTimeout(() => {
            const pos = this.checkVal(true);
            this.caret(pos);
            this.updateModel(event);
            if (this.isCompleted()) {
                this.onComplete.emit();
            }
        }, 0);
    }

    getUnmaskedValue() {
        const unmaskedBuffer = [];
        for (let i = 0; i < this.buffer.length; i++) {
            const c = this.buffer[i];
            if (this.tests[i] && c !== this.getPlaceholder(i)) {
                unmaskedBuffer.push(c);
            }
        }

        return unmaskedBuffer.join('');
    }

    updateModel(e) {

        // Valor que vai para o objeto relacionado (json)

        const updatedValue = this.unmask ? this.getUnmaskedValue() : e.target.value;
        if (updatedValue !== null || updatedValue !== undefined) {
            this.value = updatedValue;
            this.onModelChange(this.value);
        }
    }

    writeValue(value: any): void {

        // Mostra a data quando vem do componente

        this.value = value;

        if (this.inputViewChild.nativeElement) {
            if (this.value === undefined || this.value == null) {
                this.inputViewChild.nativeElement.value = '';
            } else {
                this.inputViewChild.nativeElement.value = this.value;
            }

            this.checkVal();
            this.focusText = this.inputViewChild.nativeElement.value;
            this.updateFilledState();
        }

    }


    updateFilledState() {
        this.filled = this.inputViewChild.nativeElement && this.inputViewChild.nativeElement.value !== '';
    }

    ngOnDestroy() {
    }

    // Calendar
    onButtonClick() {
        if (!this.overlayVisible) {
            this.showOverlay();
        } else {
            this.overlayVisible = false;
        }
    }

    showOverlay() {
        this.overlayVisible = true;
    }

    onDatePickerClick(event) {

    }

    updateDatabindingValue() {
        // new Date(ano, mês, dia, hora, minuto, segundo, milissegundo);
        if (this.isCompleted()) {
            const dia = Number(this.buffer[0] + this.buffer[1]);
            const mes = Number(this.buffer[3] + this.buffer[4]);
            const ano = Number(this.buffer[6] + this.buffer[7] + this.buffer[8] + this.buffer[9]);

            const hora = Number(this.buffer[11] + this.buffer[12]);
            const minuto = Number(this.buffer[14] + this.buffer[15]);

            if (this.habilitarHora) {
                this.bindingValue = new Date(ano, mes - 1, dia, hora, minuto, 0, 0);
            } else {
                this.bindingValue = new Date(ano, mes - 1, dia, 0, 0, 0, 0);
            }
        }
    }

    async calendarDelay(ms: number) {
        await new Promise(
            resolve => setTimeout(() => resolve(), ms)).then(() => {
                if (this._calendarFocused === false) {
                    this.overlayVisible = false;
                } else {
                    this._calendarFocused = false;

                    this.inputViewChild.nativeElement.focus();
                }
            }
            );

    }

    // Ported from jquery-ui datepicker formatDate

    formatDateTime(date) {
        let formattedValue = null;
        formattedValue = this.formatDate(date, "dd/mm/yy");
        formattedValue += ' ' + this.formatTime(date);
        return formattedValue;
    }

    formatDate(date, format) {
        if (!date) {
            return '';
        }

        let iFormat;
        const lookAhead = (match) => {
            const matches = (iFormat + 1 < format.length && format.charAt(iFormat + 1) === match);
            if (matches) {
                iFormat++;
            }
            return matches;
        },
            formatNumber = (match, value, len) => {
                let num = '' + value;
                if (lookAhead(match)) {
                    while (num.length < len) {
                        num = '0' + num;
                    }
                }
                return num;
            },
            formatName = (match, value, shortNames, longNames) => {
                return (lookAhead(match) ? longNames[value] : shortNames[value]);
            };
        let output = '';
        let literal = false;

        if (date) {
            for (iFormat = 0; iFormat < format.length; iFormat++) {
                if (literal) {
                    if (format.charAt(iFormat) === '\'' && !lookAhead('\'')) {
                        literal = false;
                    } else {
                        output += format.charAt(iFormat);
                    }
                } else {
                    switch (format.charAt(iFormat)) {
                        case 'd':
                            output += formatNumber('d', date.getDate(), 2);
                            break;
                        case 'D':
                            output += formatName('D', date.getDay(), this.calendarLocale.dayNamesShort, this.calendarLocale.dayNames);
                            break;
                        case 'o':
                            output += formatNumber('o',
                                Math.round((
                                    new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() -
                                    new Date(date.getFullYear(), 0, 0).getTime()) / 86400000), 3);
                            break;
                        case 'm':
                            output += formatNumber('m', date.getMonth() + 1, 2);
                            break;
                        case 'M':
                            output += formatName('M', date.getMonth(), this.calendarLocale.monthNamesShort, this.calendarLocale.monthNames);
                            break;
                        case 'y':
                            output += lookAhead('y') ? date.getFullYear() : (date.getFullYear() % 100 < 10 ? '0' : '') + (date.getFullYear() % 100);
                            break;
                        case '@':
                            output += date.getTime();
                            break;
                        case '!':
                            output += date.getTime() * 10000 + this.ticksTo1970;
                            break;
                        case '\'':
                            if (lookAhead('\'')) {
                                output += '\'';
                            } else {
                                literal = true;
                            }
                            break;
                        default:
                            output += format.charAt(iFormat);
                    }
                }
            }
        }
        return output;
    }

    formatTime(date) {
        if (!date) {
            return '';
        }

        let output = '';
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        if (this.hourFormat === '12' && hours > 11 && hours !== 12) {
            hours -= 12;
        }

        if (this.hourFormat === '12') {
            output += hours === 0 ? 12 : (hours < 10) ? '0' + hours : hours;
        } else {
            output += (hours < 10) ? '0' + hours : hours;
        }
        output += ':';
        output += (minutes < 10) ? '0' + minutes : minutes;

        if (this.showSeconds) {
            output += ':';
            output += (seconds < 10) ? '0' + seconds : seconds;
        }

        if (this.hourFormat === '12') {
            output += date.getHours() > 11 ? ' PM' : ' AM';
        }

        return output;
    }

    forcaLimpeza() {
        this._bindingValue = null;
        this.isReady = false;
    }


}

