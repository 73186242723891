import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../../../../base/util/base.service';
import { environment } from './../../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
@Injectable()
export class NovoDoadorService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  enviarDoador(doador) {
    return this.post(environment.baseUrl + "doadores", doador);
  }

  recuperarDoador(id: string) {
    let urlDestino = environment.baseUrl + "doadores";
    let idItem: string;
    if (!isNullOrUndefined(id)) {
        idItem = "/" + id;
        urlDestino += idItem;
    }
    return this.get(urlDestino);
}

  atualizaDoador(doador) {
    const urlDestino = environment.baseUrl + "doadores";
    return this.put(urlDestino, doador);
  }

}
