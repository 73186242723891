import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BaseService } from "../../base/util/base.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class EnumService extends BaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  recuperaTipoPessoa() {
    return this.get(environment.baseUrl + "enum/pessoa/tipos");
  }

  recuperaTipoDocumentoPessoa() {
    return this.get(environment.baseUrl + "enum/pessoa/documentos");
  }

  recuperaSexoPessoa() {
    return this.get(environment.baseUrl + "enum/pessoa/sexos");
  }

  recuperaAmostraRecebimentoTransporte() {
    return this.get(
      environment.baseUrl + "enum/amostra-recebimento/transporte"
    );
  }

  recuperaAmostraRecebimentoFinalidade() {
    return this.get(
      environment.baseUrl + "enum/amostra-recebimento/finalidade"
    );
  }

  recuperaTipoFinalidade() {
    return this.get(
      environment.baseUrl + "enum/unidade-coleta/tipo-finalidades"
    );
  }

  recuperaStatus() {
    return this.get(environment.baseUrl + "enum/pessoa/status");
  }

  recuperaStatusUnidadeColeta() {
    return this.get(environment.baseUrl + "enum/UnidadeColeta/Status");
  }

  recuperaTipoArquivoAnexo() {
    return this.get(environment.baseUrl + "enum/tipos-arquivos-anexos");
  }

  recuperaStatusDroga() {
    return this.get(environment.baseUrl + "enum/droga/status");
  }
  recuperaStatusRegiaomatrizColeta() {
    return this.get(environment.baseUrl + "enum/RegiaoMatrizColeta/Status");
  }
  recuperaStatusTipoMatrizExame() {
    return this.get(environment.baseUrl + "enum/TipoMatrizExame/Status");
  }
  recuperaStatusCorMatriz() {
    return this.get(environment.baseUrl + "enum/CorMatrizColeta/Status");
  }
  recuperaStatusEquipamento() {
    return this.get(environment.baseUrl + "enum/equipamento/status");
  }

  recuperaAmostraEquipamento() {
    return this.get(environment.baseUrl + "enum/amostra/equipamento-tipos");
  }
  recuperaStatusStatusFatura() {
    return this.get(environment.baseUrl + "enum/statusFatura/status");
  }
  recuperaStatusStatusRepasse() {
    return this.get(environment.baseUrl + "enum/statusRepasse/status");
  }

  recuperaStatusStatusComissao() {
    return this.get(environment.baseUrl + "enum/statusComissao/status");
  }

  recuperarDrogaFinalidades() {
    return this.get(environment.baseUrl + "enum/droaga/finalidade-drogas");
  }

  recuperarTipoMotivoAmostra() {
    return this.get(environment.baseUrl + "enum/motivoAmostra/Tipos");
  }

  recuperarStatusMotivoAmostra() {
    return this.get(environment.baseUrl + "enum/amostra/StatusMotivos");
  }

  recuperaStatusTipoLancamento() {
    return this.get(environment.baseUrl + "enum/TiposLancamentos/Status");
  }

  recuperaStatusContratoRepasse() {
    return this.get(environment.baseUrl + "enum/contrato-repasse/status");
  }

  recuperaBooleano() {
    return this.get(environment.baseUrl + "enum/booleano");
  }

  recuperaStatusTipoProduto() {
    return this.get(environment.baseUrl + "enum/TipoProduto/Status");
  }

  recuperaStatusProduto() {
    return this.get(environment.baseUrl + "enum/Produto/Status");
  }

  recuperaStatusLocalEstoque() {
    return this.get(environment.baseUrl + "enum/LocalEstoque/Status");
  }

  recuperaStatusEstoque() {
    return this.get(environment.baseUrl + "enum/Estoque/Status");
  }

  recuperaStatusMovimentacao() {
    return this.get(environment.baseUrl + "enum/estoque/MovimantacaoTipo");
  }

  recuperaStatusContratoCliente() {
    return this.get(environment.baseUrl + "enum/contrato-clientes/status");
  }

  recuperaModalidadesContrato() {
    return this.get(environment.baseUrl + "enum/contrato-clientes/modalidades-contratos");
  }

  recuperaRegimeTributario() {
    return this.get(environment.baseUrl + "enum/regimeTributario");
  }

  recuperaTipoVencimento() {
    return this.get(environment.baseUrl + "enum/contrato-clientes/tipo-vencimento");
  }

  recuperaTipoPagamentoPedidos() {
    return this.get(environment.baseUrl + "tipos-pagamentos-pedido/pagamento-dominio");
  }

  recuperaStatusConferenciaConfirmatoria() {
    return this.get(
      environment.baseUrl + "enum/conferencia-confirmatoria/status"
    );
  }

  recuperaResultadoConferenciaConfirmatoria() {
    return this.get(
      environment.baseUrl + "enum/conferencia-confirmatoria/resultado"
    );
  }

  recuperaStatusTipoContato() {
    return this.get(environment.baseUrl + "enum/tipo-contato/status");
  }

  recuperaStatusDrogaTipoMatriz() {
    return this.get(environment.baseUrl + "enum/droga-tipo-matriz/Status");
  }

  recuperaResultadoAmostra() {
    return this.get(environment.baseUrl + "enum/amostra/StatusResultado");
  }
  recuperaStatusGradeComissao() {
    return this.get(environment.baseUrl + "enum/grade-comissao/status");
  }
  recuperaStatusGradeComissaoItem() {
    return this.get(environment.baseUrl + "enum/grade-comissao-item/status");
  }
  recuperaFormaDePagamento() {
    return this.get(environment.baseUrl + "enum/grade-comissao-item/formaDeCalculo");
  }
  recuperaModalidadeItemComissao() {
    return this.get(environment.baseUrl + "enum/grade-comissao-item/Modalidade");
  }
  recuperaStatusItemAvulsoComissao() {
    return this.get(environment.baseUrl + "enum/comissao-itens-avulsos/status");
  }
  recuperaStatusSolicitacaoWorkflow() {
    return this.get(environment.baseUrl + "enum/solicitacao-workflow/Status");
  }
  recuperaStatusUsuario() {
    return this.get(environment.baseUrl + "enum/usuario/status");
  }
  recuperaStatusContratoFornecedor() {
    return this.get(environment.baseUrl + "enum/contrato-fornecedor/status");
  }

  recuperaTipoUnidadeColeta() {
    return this.get(environment.baseUrl + "enum/unidade-coleta/tipo");
  }

  recuperaFinalidadeUnidadeColeta() {
    return this.get(environment.baseUrl + "enum/unidade-coleta/finalidades");
  }

  recuperaISFaturadoEnum() {
    return this.get(environment.baseUrl + "enum/isFaturado");
  }

  recuperaTemContratoEnum() {
    return this.get(environment.baseUrl + "enum/temContrato");
  }

  recuperaUnidadeAnaliseEnum() {
    return this.get(environment.baseUrl + "enum/unidade-analise");
  }

  recuperaStatusTipoPagamento() {
    return this.get(environment.baseUrl + "enum/tipos-pagamento/status");
  }

  recuperaStatusAmostra() {
    return this.get(environment.baseUrl + "enum/amostra/Status");
  }

  public recuperarTiposMatriz() {
    const urlDestino = environment.baseUrl + "tipoMatrizExames";
    return this.get(urlDestino);
  }
}
