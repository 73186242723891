import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'mascaraNota'
})
export class NotaPipe implements PipeTransform{
   notaFormatado: string;

    transform(nota: string){
        
        if((nota.toString()).search('.') == -1){
            if(nota){           
                nota = nota.toString(); 
                if(nota.length === 4){
                this.notaFormatado = nota.substring(0,2).concat(".").concat(nota.substring(2,4))
                } 
                else if(nota.length === 3){
                    this.notaFormatado = nota.substring(0,1).concat(".").concat(nota.substring(1,3))
                } 
                else if(nota.length === 2){
                    this.notaFormatado = nota.substring(0,2).concat(".00")                                     
                } 
                else if(nota.length === 1){
                    this.notaFormatado = nota.substring(0,1).concat(".00")                                    
                } 
            }
            return this.notaFormatado;
        }
        else if (((nota.toString()).search('.') > -1)){
             return this.notaFormatado = nota;
        }
       
    }
}