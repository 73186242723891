import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// PrimeNg
import { Message } from 'primeng/components/common/api';
// VO
import { ContatoPessoaVO } from './../../../../../base/vo/contato-pessoa';
import { Utils } from './../../../../../base/util/utils';
import { DeletarContatoService } from './deletar-contato-component.service';

declare var M: any;

@Component({
  selector: 'app-deletar-contato-component',
  templateUrl: './deletar-contato-component.component.html',
  styleUrls: ['./deletar-contato-component.component.css'],
  providers: [DeletarContatoService]
})
export class DeletarContatoComponent implements OnInit {

  @Input() refazerBusca;
  @Output() respostaCriaAtualiza = new EventEmitter;
  @Output() respostaContatoDeleta = new EventEmitter();

  public ContatoPessoa = new ContatoPessoaVO;
  public isLoading: boolean;
  public displayModalDeletarContatoPessoa: boolean;
  public editContato: boolean;
  public confirmaDelete: boolean;
  public idItem: string = "";
  public msgs: Message[] = [];
  public listContatos: Array<any> = [];

  constructor(public deletarContatoPessoaService: DeletarContatoService, public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
  }

  showDialogDeletaContatoPessoa(Item) {
    this.ContatoPessoa = Item;
    this.displayModalDeletarContatoPessoa = true;
  }

  hideDialogDeletarContatoPessoa() {
    this.idItem = "";
    this.displayModalDeletarContatoPessoa = false;
    this.respostaCriaAtualiza.emit(1);
  }

  confirmarDelete() {
    this.confirmaDelete = true;
    this.displayModalDeletarContatoPessoa = false;
    this.respostaContatoDeleta.emit(this.ContatoPessoa);
    this.respostaCriaAtualiza.emit(1);
  }

  // todo : verificar necessidade de manter os dois métodos , fazendo a mesma coisa
  deletarContatoPessoa() {
    this.confirmaDelete = true;
    this.displayModalDeletarContatoPessoa = false;
    this.respostaContatoDeleta.emit(this.ContatoPessoa);
    this.respostaCriaAtualiza.emit(1);
  }
}
