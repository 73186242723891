import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { isNullOrUndefined } from 'util';

// VO
import { OrigemSolicitacaoVO } from '../../../base/vo/origem-solicitacao';

// Service
import { OrigemSolicitacaoService } from './origem-solicitacao.service';

// PrimeNg
import { Message } from 'primeng/components/common/api';

// Component
import { NovoOrigemSolicitacaoComponent } from './novo-origem-solicitacao/novo-origem-solicitacao.component';
import { DetalhaOrigemSolicitacaoComponent } from './detalha-origem-solicitacao/detalha-origem-solicitacao.component';
import { DeletarOrigemSolicitacaoComponent } from './deletar-origem-solicitacao/deletar-origem-solicitacao.component';
import { StatusOrigemSolicitacaoComponent } from './status-origem-solicitacao/status-origem-solicitacao.component';
import { Utils } from '../../../base/util/utils';

interface FiltroOrigemSolicitacao {
  Codigo: string;
  Descricao: string;
  status: number;

}

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-origem-solicitacao',
  templateUrl: './origem-solicitacao.component.html',
  styleUrls: ['./origem-solicitacao.component.css']
})
export class OrigemSolicitacaoComponent implements OnInit {

  @ViewChild('novoOrigemSolicitacao')
  public novoOrigemSolicitacao: NovoOrigemSolicitacaoComponent;

  @ViewChild('detalhaOrigemSolicitacao')
  public detalhaOrigemSolicitacao: DetalhaOrigemSolicitacaoComponent;

  @ViewChild('deletaOrigemSolicitacao')
  public deletaOrigemSolicitacao: DeletarOrigemSolicitacaoComponent;

  @ViewChild('StatusOrigemSolicitacao')
  public StatusOrigemSolicitacao: StatusOrigemSolicitacaoComponent;

  @Input() refazerBusca: any;

  public visibleForm: boolean;
  public displayModal: boolean;

  public activeAutocompleteCodigo: boolean;
  public activeAutocompleteDescricao: boolean;

  public listaOrigemSolicitacao: any;

  public isLoading: boolean;
  public numberOfRecords = 0;
  public resultFound: boolean;
  public resultNotFound: boolean;
  public actualpage = 0;
  public salvaPagina = 0;
  public msgs: Message[] = [];
  public listarStatus: any;

  public Codigo = '';
  public Descricao = '';
  public status: number;

  public tipoOrigemSolicitacao: OrigemSolicitacaoVO;
  public filtros: FiltroOrigemSolicitacao;
  public listaCodigos: any;
  public listaDescricao: any;

  public idITipoItemCancela: number;
  public isItensBuscados: boolean;

  constructor(private origemSolicitacaoService: OrigemSolicitacaoService, public serviceUtils: Utils) { }

  ngOnInit() {
    this.carregaJQuery();
    this.recuperaStatus();
    this.tipoOrigemSolicitacao = new OrigemSolicitacaoVO;
    this.filtros = {} as FiltroOrigemSolicitacao;
  }

  buscarOrigemSolicitacao() {
    this.isItensBuscados = true;
    this.populaFiltros();
    this.recuperaOrigemSolicitacao(1);
  }

  buscarCodigos(event) {
    this.origemSolicitacaoService.recuperarCodigo(event.query).subscribe(
      data => {
        this.listaCodigos = data.map(function (el) {
          return el.codigo;
        }).filter(this.serviceUtils.onlyUnique);
      },
      erro => {
        console.log(erro);
      }
    );
  }

  buscarDescricao(event) {
    this.origemSolicitacaoService.recuperarDescricoes(event.query).subscribe(
      data => {
        this.listaDescricao = data.map(element => element.descricao).filter(this.serviceUtils.onlyUnique);
      },
      erro => {
        console.log(erro);
      }
    );
  }

  refazBusca(respostaNovoItem) {
    if (this.refazBusca && this.isItensBuscados) {
      this.buscarOrigemSolicitacao();
    }
  }

  public recuperaOrigemSolicitacao(page: number) {
    if (page <= 10) {
      this.actualpage = 1;
    }
    this.isLoading = true;
    this.origemSolicitacaoService.recuperarOrigemSolicitacao(this.filtros, page).subscribe(
      data => {
        this.listaOrigemSolicitacao = data;
        if (!isNullOrUndefined(this.listaOrigemSolicitacao) && this.listaOrigemSolicitacao.length > 0) {
          this.numberOfRecords = this.listaOrigemSolicitacao[0].numberOfRows;
          this.resultFound = true;
          this.resultNotFound = false;
          this.isLoading = false;
          this.salvaPagina = page;
        } else {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
        }
      },
      erro => {
        this.resultFound = false;
        this.resultNotFound = true;
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  paginate(event) {
    this.recuperaOrigemSolicitacao(event.page + 1);
    this.actualpage = event.first;
  }

  populaFiltros() {
    this.filtros.Codigo = this.Codigo;
    this.filtros.Descricao = this.Descricao;
    this.filtros.status = this.status;

  }

  limpaCodigo() {
    this.Codigo = null;
  }

  limpaDescricao() {
    this.Descricao = null;
  }

  hideDialog() {
    this.idITipoItemCancela = null;
    this.displayModal = false;
  }

  showDialogNovoItem(idItem) {
    this.novoOrigemSolicitacao.showDialogNovoItem(idItem);
  }

  showDialogDetalhaItem(idItem) {
    this.detalhaOrigemSolicitacao.showDialogDetalhaItem(idItem);
  }

  showDialogDeletarItem(idItem) {
    this.deletaOrigemSolicitacao.showDialogDeletaOrigemSolicitacao(idItem);
  }

  showDialogStatusItem(idItem) {
    this.StatusOrigemSolicitacao.showDialogStatusItem(idItem);
  }

  recuperaStatus() {
    this.origemSolicitacaoService.listaStatus().subscribe(
      data => {
        this.listarStatus = data;
        this.carregaJQuery();
      },
      erro => {
        console.log(erro);
      }
    );
  }

  private carregaJQuery() {
    setTimeout(function () {
      M.AutoInit();
      $('.dropdown-trigger').dropdown({ "coverTrigger": false });
      $('select').formSelect();
    }, 2);
  }
}
