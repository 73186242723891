import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../../../../base/util/base.service';
import { environment } from './../../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
@Injectable()
export class NovoContatoService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  enviarContatoPessoa(item) {
    return this.post(environment.baseUrl + 'contatoPessoa', item);
  }

  recuperarContatoPessoa(id: string) {
    let urlDestino = environment.baseUrl + 'contatoPessoa';
    let idItem: string;
    if (!isNullOrUndefined(id)) {
      idItem = '/' + id;
      urlDestino += idItem;
    }
    return this.get(urlDestino);
  }
  recuperarDetalhesPessoa(id: string) {
    let urlDestino = environment.baseUrl + 'Pessoas';
    let idItem: string;
    if (!isNullOrUndefined(id)) {
      idItem = '/' + id;
      urlDestino += idItem;
    }
    return this.get(urlDestino);
  }

  recuperarNomeContato(cod: string) {
    let urlDestino = environment.baseUrl + 'contatoPessoa?page=1&pageSize=5';
    let codItem: string;
    if (!isNullOrUndefined(cod)) {
        codItem = '&nome=' + cod;
        urlDestino += codItem;
    }
    return this.get(urlDestino);
}

  atualizaContatoPessoa(tipoItemFatura) {
    const urlDestino = environment.baseUrl + 'contatoPessoa';
    return this.put(urlDestino, tipoItemFatura);
  }

  recuperaTipoContatoAtivo() {
    return this.get(environment.baseUrl + "tipo-contato?status=1");
  }

  recuperaTipoContatoTodos() {
      return this.get(environment.baseUrl + "tipo-contato");
  }
}
