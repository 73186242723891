import { ZSUploadComponent } from './../../../../base/zsFrameWork/zs-upload/zs-upload.component';
import { ArquivoVO } from './../../../../base/vo/arquivo';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { EnumService } from './../../../../base/enum/enum.service';
import { Utils } from './../../../../base/util/utils';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-solicitacao-interacao-novo-documento',
  templateUrl: './solicitacao-interacao-novo-documento.component.html',
  styleUrls: ['./solicitacao-interacao-novo-documento.component.css']
})
export class SolicitacaoInteracaoNovoDocumentoComponent implements OnInit {

  @Output()
  public callback: EventEmitter<any> = new EventEmitter();

  @ViewChild(ZSUploadComponent)
  private zsUploadComponent: ZSUploadComponent;

  public displayModalNovoArquivo: boolean;

  // Variaveis anexo de arquivos
  public arquivo: ArquivoVO;
  public listaArquivosAnexos: Array<ArquivoVO>;
  public isArquivoValido = true;

  constructor(private enumService: EnumService, public serviceUtils: Utils) { }

  ngOnInit() {
    this.limpar();
  }

  //#region Métodos Públicos
  public showDialogNovoArquivo() {
    this.limpar();
    this.displayModalNovoArquivo = true;
    this.isArquivoValido = true;
    this.zsUploadComponent.desabilitaDescricao = false;
  }

  public confirmar() {
    if (this.validaCampos()) {
      this.arquivo.descricaoArquivoAnexo = this.zsUploadComponent.descricao;
      this.callback.emit(this.arquivo);
      this.limpar();
      this.displayModalNovoArquivo = false;
    }
  }

  public fechar() {
    this.limpar();
    this.displayModalNovoArquivo = false;
  }
  //#endregion

  //#region Métodos Privados
  private limpar() {
    this.arquivo = new ArquivoVO();
    this.zsUploadComponent.message = "";
    this.zsUploadComponent.descricao = "";
    this.zsUploadComponent.isDescricaoValida = true;
    this.zsUploadComponent.fileElement.nativeElement.value = "";
    $("zs-upload .file-path").val("");
    this.carregaJQuery();
  }

  private validaCampos() {
    const isValido = new Array<any>();
    this.zsUploadComponent.isDescricaoValida = !this.serviceUtils.isStringNullOrBlank(this.zsUploadComponent.descricao);
    this.zsUploadComponent.isArquivoValido = !this.serviceUtils.isStringNullOrBlank(this.arquivo.idArquivoAnexo);

    isValido.push(this.zsUploadComponent.isDescricaoValida);
    isValido.push(this.isArquivoValido = !this.serviceUtils.isStringNullOrBlank(this.arquivo.idArquivoAnexo));

    return !isValido.some(function isAnyFalse(element) { return (element === false); });
  }

  private carregaJQuery() {
    setTimeout(function () {
      M.AutoInit();
      $('.dropdown-trigger').dropdown({ "coverTrigger": false });
      $('select').formSelect();
    }, 1);
  }
  //#endregion

}
