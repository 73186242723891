import { Utils } from './../../../../base/util/utils';
import { Message } from 'primeng/components/common/message';
import { DetalhaContatoComponent } from './detalha-contato-component/detalha-contato-component.component';
import { NovoContatoComponent } from './novo-contato-component/novo-contato-component.component';
import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { ContatoPessoaVO } from './../../../../base/vo/contato-pessoa';
import { DeletarContatoComponent } from './deletar-contato-component/deletar-contato-component.component';
import { isNullOrUndefined } from 'util';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-contato-component',
  templateUrl: './contato-component.component.html',
  styleUrls: ['./contato-component.component.css']
})
export class ContatoComponent implements OnInit {

  @Output() respostaContatoCadastradoParte2 = new EventEmitter;

  @Output() respostaContatoAtualizadoParte2 = new EventEmitter;

  @ViewChild('novoContatoPessoa')
  public novoContatoPessoa: NovoContatoComponent;

  @ViewChild('contatoPessoaComponent')
  public contatoPessoaComponent: ContatoComponent;

  @ViewChild('detalhaContatoPessoa')
  public detalhaContatoPessoa: DetalhaContatoComponent;

  @ViewChild('deletaContatoPessoa')
  public deletaContatoPessoa: DeletarContatoComponent;


  idItem: any;
  editContato = true;
  teste: any;
  public visibleForm = false;
  public index = 0;
  public qntSplice = 1;
  public indexContato = 0;
  public detalha = false;
  listContatos = new Array<ContatoPessoaVO>();
  msgs: Message[] = [];

  constructor(public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
    if (!this.idItem) {
    }
  }

  showForm() {
    this.visibleForm = true;

    setTimeout(function () {
      $('html, body').animate({
      }, 1000);
    }, 1);

  }

  showDialogNovoContatoPessoa(idItem) {
    this.teste = idItem;
    let x: any;
    x = Object.assign({}, idItem);
    if (!idItem) {
      this.novoContatoPessoa.showDialogNovoContatoPessoa(idItem);
    } if (idItem) {
      this.index = this.listContatos.indexOf(idItem);
      this.novoContatoPessoa.showDialogNovoContatoPessoa(x);
    }

  }

  showDialogDetalhaContatoPessoa(objeto: ContatoPessoaVO) {
    if (this.detalha) {
      this.detalhaContatoPessoa.showDialogDetalhaItem(objeto.id);
    } else {
      this.detalhaContatoPessoa.showDialogDetalhaItemContato(objeto);
    }
  }

  showDialogDeletaContatoPessoa(Item) {
    if (this.editContato) {
      this.deletaContatoPessoa.showDialogDeletaContatoPessoa(Item);
      this.deletaContatoPessoa.editContato = true;
    } else {
      this.deletaContatoPessoa.editContato = false;
      this.indexContato = Item.idIndex;
      this.deletaContatoPessoa.showDialogDeletaContatoPessoa(Item);
    }
  }

  populaArrayContatos(respostaEvento) {
    const podeCadastrar = this.verificaTiposDeContatos(respostaEvento);
    if (isNullOrUndefined(this.listContatos)) {
      this.listContatos = new Array<ContatoPessoaVO>();
    }
    if (podeCadastrar === 1) {
      this.listContatos.push(respostaEvento);
      this.respostaContatoCadastradoParte2.emit(respostaEvento);
    }
  }

  deletaContato(respostaEvento) {
    this.index = this.listContatos.indexOf(respostaEvento, 0);
    this.listContatos.splice(this.index, 1);
    // this.contatoPessoaComponent.listContatos = this.listContatos;
  }

  atualizaArrayContatos(respostaEvento) {
    const podeCadastrar = this.verificaTiposDeContatos(respostaEvento);
    if (podeCadastrar == 1) {
      this.listContatos[this.index] = respostaEvento;
      this.respostaContatoAtualizadoParte2.emit(this.listContatos);
    } else {
      this.listContatos[this.index] = this.teste;
    }
  }

  public populaDetalha(detalheCliente) {
    this.listContatos = new Array<ContatoPessoaVO>();
    this.detalha = true;
    this.listContatos = detalheCliente.contatos;
  }

  verificaTiposDeContatos(contato) {
    let i: number;
    if (!isNullOrUndefined(this.listContatos)) {
      for (i = 0; i < this.listContatos.length; i++) {
        if (this.listContatos[i].telefoneCelular == contato.telefoneCelular && this.listContatos[i].telefoneFax == contato.telefoneFax && this.listContatos[i].telefoneFixo == contato.telefoneFixo && this.listContatos[i].idPessoa == contato.idPessoa && this.listContatos[i].nome == contato.nome && this.listContatos[i].idTipoContato == contato.idTipoContato  && this.listContatos[i].id != contato.id ) {
          this.msgs = this.serviceUtils.showError("Contato já cadastrado");
          return 0;
        }
      }
    }
    return 1;
  }
}
