import { ArquivoVO } from '../arquivo';

export class SolicitacaoInteracaoVO {
  id: number;
  idSolicitacao: number;
  idSolicitacaoWorkflowEtapa: number;
  idGrupoAtendimento: number;
  texto: string;
  IsUltimaInteracao: boolean;
  listaDeArquivos = new Array<ArquivoVO>();
}
