import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'mascaraCep'
})
export class CepPipe implements PipeTransform {
    cepFormatado: string;

    transform(cep: string) {
        if (cep) {
            if (cep.length === 8) {
               this.cepFormatado = cep.substring(0, 5).concat("-").concat(cep.substring(5, 8));
            }
        }

        return this.cepFormatado;
    }
}
