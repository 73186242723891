import { GrupoAtendimentoUsuarioVO } from './../../../../base/vo/grupo-atendimento-usuario';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../../base/util/utils';

// VO
import { GrupoAtendimentoVO } from '../../../../base/vo/grupo-atendimento';

// Service
import { NovoGrupoAtendimentoService } from './novo-grupo-atendimento.service';
import { GrupoAtendimentoService } from '../grupo-atendimento.service';

// PrimeNg
import { Message } from 'primeng/components/common/api';

// Components
import { NovoGrupoAtendimentoUsuarioComponent } from './novo-grupo-atendimento-usuario/novo-grupo-atendimento-usuario.component';

declare var M: any;
declare var $: any;

interface IgrupoAtendimentoUsuario {
  id: number;
  idUsuario: number;
}
interface IgrupoAtendimento {
  id: number;
  nome: string;
  grupoAtendimentoUsuario: Array<IgrupoAtendimentoUsuario>;
}


@Component({
  selector: 'app-novo-grupo-atendimento',
  templateUrl: './novo-grupo-atendimento.component.html',
  styleUrls: ['./novo-grupo-atendimento.component.css']
})
export class NovoGrupoAtendimentoComponent implements OnInit {

  @ViewChild('novoGrupoAtendimentoUsuario')
  public novoGrupoAtendimentoUsuario: NovoGrupoAtendimentoUsuarioComponent;

  @ViewChild('modalNovoGrupoAtenUsuario') modalNovoGrupoAtenUsuarioVC: any;

  @Input() novoGrupoAtendimento: GrupoAtendimentoVO;
  @Input() refazerBusca: any;
  @Output() respostaCriaAtualiza = new EventEmitter;
  @Output() MessageDialog = new EventEmitter();

  public codigo: string;
  public descricao: string;
  public bloqueado: boolean;
  public isLoading: boolean;
  public visibleForm: boolean;
  public formSucess: boolean;
  public formErro: boolean;
  public disableStatus: boolean;
  public validaEventButton: boolean;
  public editItem: boolean;
  public idItem: string = '';
  public isNomeValido: boolean = true;

  public sendInfo: IgrupoAtendimento;

  public display: boolean;

  public displayModalNovoGrupoAtendimento: boolean;
  public msgs: Message[] = [];


  public isGrupoAttendimentoUsuarioValido: boolean = true;

  // variveis de controle do array

  public listaEspera: Array<GrupoAtendimentoUsuarioVO>;


  constructor(private novoGrupoAtendimentoService: NovoGrupoAtendimentoService,
    public grupoAtendimentoService: GrupoAtendimentoService,
    public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();

    this.novoGrupoAtendimento = new GrupoAtendimentoVO();
    this.sendInfo = {} as IgrupoAtendimento;

    setTimeout(() => {
      $('select').formSelect();
    }, 1);

    setTimeout(function () {
      $('.dropdown-trigger').dropdown({ 'coverTrigger': false });
    }, 1);
  }

  populaInterface() {
    this.sendInfo.nome = this.novoGrupoAtendimento.nome;
    this.sendInfo.id = this.novoGrupoAtendimento.id;
    this.sendInfo.grupoAtendimentoUsuario  = [];
    this.novoGrupoAtendimento.grupoAtendimentoUsuario.forEach((element, index) => {
      const localElement = {} as IgrupoAtendimentoUsuario;
      localElement.id = element.id;
      localElement.idUsuario = element.idUsuario;
     this.sendInfo.grupoAtendimentoUsuario.push(localElement);
    });
  }

  enviarGrupoAtendimento() {
    this.validaEventButton = true;
    if (this.validaCampos()) {
      this.populaInterface();
      this.isLoading = true;
      this.novoGrupoAtendimentoService.enviarGrupoAtendimento(this.sendInfo).subscribe(
        response => {
          if (response.success && !isNullOrUndefined(response.data)) {
            this.msgs = this.serviceUtils.showSuccess();
            this.limparForm();
          } else {
            this.msgs = this.serviceUtils.showError(response.message);
          }
          this.isLoading = false;
        },
        error => {
          this.serviceUtils.showError('Erro ao tentar cadastrar');
          this.isLoading = false;
          console.log(error);
        });
    }
  }

  atualizarGrupoAtendimento() {
    this.validaEventButton = true;
    if (this.validaCampos()) {
      this.populaInterface();
      this.isLoading = true;
      this.novoGrupoAtendimentoService.atualizaGrupoAtendimento(this.sendInfo).subscribe(
        response => {
          if (response.success && !isNullOrUndefined(response.data)) {
            this.msgs = this.serviceUtils.showSuccess();
            this.hideDialogNovoItem();
            this.respostaCriaAtualiza.emit(1);
          } else {
            this.msgs = this.serviceUtils.showError(response.message);
          }
          this.isLoading = false;
        },
        error => {
          this.serviceUtils.showError('Erro ao tentar atualizar');
          this.isLoading = false;
          console.log(error);
        });
    }
  }

  //#region Show Modals (Filhos)
  showDialogNovoGrupAtenUsuario() {
    this.isGrupoAttendimentoUsuarioValido = true;

    let x: any;
    x = Object.assign(new Array<GrupoAtendimentoUsuarioVO>(),  this.novoGrupoAtendimento.grupoAtendimentoUsuario);
    this.listaEspera = x;
      this.novoGrupoAtendimentoUsuario.showDialog(x, this.novoGrupoAtendimento.id);
  }

  //#endregion

  recuperarGrupoAtendimento(idItem: string) {
    this.isLoading = true;
    this.novoGrupoAtendimentoService.recuperarGrupoAtendimento(idItem).subscribe(
      data => {
        if (this.displayModalNovoGrupoAtendimento) {
          setTimeout(this.modalNovoGrupoAtenUsuarioVC.center.bind(this.modalNovoGrupoAtenUsuarioVC), 0);
        }
        this.novoGrupoAtendimento = data;
        this.isLoading = false;
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  //#region Validação
  validaCampos(): boolean {
    if (this.validaEventButton) {
      const isValido = new Array<any>();
      isValido.push(this.isNomeValido = !this.serviceUtils.isStringNullOrBlank(this.novoGrupoAtendimento.nome, true));

      if (!isNullOrUndefined(this.novoGrupoAtendimento.grupoAtendimentoUsuario)) {
          if (this.novoGrupoAtendimento.grupoAtendimentoUsuario.length <= 0) {
            isValido.push(this.isGrupoAttendimentoUsuarioValido = false);
          }
      } else {
        isValido.push(this.isGrupoAttendimentoUsuarioValido = false);
      }

      if (!this.isNomeValido) {
        this.novoGrupoAtendimento.nome = null;
      }
      return !isValido.some(function isAnyFalse(element) { return (element === false); });
    }
    this.validaEventButton = false;
    return false;
  }
  //#endregion

  showDialogNovoItem(idItem) {
    this.limparForm();
    if (idItem) {
      this.idItem = idItem;
      this.editItem = true;
      this.recuperarGrupoAtendimento(idItem);
    } if (!idItem) {
      this.idItem = '';
      this.editItem = false;
      this.novoGrupoAtendimento = new GrupoAtendimentoVO();
      this.novoGrupoAtendimento.status = 1;
    }

    this.displayModalNovoGrupoAtendimento = true;

    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }

  hideDialogNovoItem() {
    this.limparForm();
    this.displayModalNovoGrupoAtendimento = false;
    if (!this.editItem) {
      this.respostaCriaAtualiza.emit(1);
    }
  }

  hideDialogAtualizaItem() {
    this.limparForm();
    this.displayModalNovoGrupoAtendimento = false;
  }

  limparForm() {
    this.novoGrupoAtendimento = new GrupoAtendimentoVO();
    this.isNomeValido = true;
    this.isGrupoAttendimentoUsuarioValido = true;

    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }


  //#region Popula Os Arrays ou Atualiza exclui
  populaArrayItem(respostaEvento) {
   if (!isNullOrUndefined(respostaEvento) && respostaEvento != 1 )  {
      this.novoGrupoAtendimento.grupoAtendimentoUsuario = respostaEvento;
    }
  }

  //#endregion
}
