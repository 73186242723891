import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../base/util/base.service';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';

@Injectable()
export class StatusOrigemSolicitacaoService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  recuperarOrigemSolicitacao(id: string) {
    let urlDestino = environment.baseUrl + 'origem-solicitacao';
    let idItem: string;
    if (!isNullOrUndefined(id)) {
      idItem = '/' + id;
      urlDestino += idItem;
    }
    return this.get(urlDestino);
  }

  reativaOrigemSolicitacao(tipoOrigemSolicitacao) {
    const urlDestino = environment.baseUrl + 'origem-solicitacao/reactive';
    return this.put(urlDestino, tipoOrigemSolicitacao);
  }

  inativaOrigemSolicitacao(tipoOrigemSolicitacao) {
    const urlDestino = environment.baseUrl + 'origem-solicitacao/cancel';
    return this.put(urlDestino, tipoOrigemSolicitacao);
  }

}
