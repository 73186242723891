import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatusWorkflowService } from './status-workflow.service';
import { isNullOrUndefined } from 'util';
import { Message } from 'primeng/components/common/api';
import { Utils } from '../../../../base/util/utils';

@Component({
  selector: 'app-status-workflow',
  templateUrl: './status-workflow.component.html',
  styleUrls: ['./status-workflow.component.css']
})
export class StatusWorkflowComponent implements OnInit {

  @Output() respostaChild = new EventEmitter;

  public isLoading: boolean;
  public solicitacaoWorkflow: any;

  public displayStatusWorkflow: boolean;

  public idItem: string = "";
  public msgs: Message[] = [];

  constructor(private statusWorkflowService: StatusWorkflowService,
              public serviceUtils: Utils) { }

  ngOnInit() {
  }

  inativaItem() {
    this.isLoading = true;
    this.statusWorkflowService.inativaWorkflow(this.solicitacaoWorkflow).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogStatusWorkflow();
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(response.message);
        }
        this.isLoading = false;
      },
      error => {
        this.msgs = this.serviceUtils.showError('');
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  ativaItem() {
    this.isLoading = true;
    this.statusWorkflowService.reativaWorkflow(this.solicitacaoWorkflow).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogStatusWorkflow();
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(response.message);
        }
        this.isLoading = false;
      },
      error => {
        this.msgs = this.serviceUtils.showError('');
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  recuperarWorkflow(idItem: string) {
    this.isLoading = true;
    this.statusWorkflowService.recuperarWorkflow(idItem).subscribe(
      result => {
        this.solicitacaoWorkflow = result;
        this.isLoading = false;
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  showDialogStatusWorkflow(idItem) {
    this.idItem = idItem;
    this.recuperarWorkflow(idItem);
    this.displayStatusWorkflow = true;
  }

  hideDialogStatusWorkflow() {
    this.idItem = "";
    this.displayStatusWorkflow = false;
    this.isLoading = false;
    this.respostaChild.emit();
  }

}
