import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// PrimeNg
import { Message } from 'primeng/components/common/api';
import { Utils } from './../../../../../base/util/utils';
import { EnderecoPessoaVO } from './../../../../../base/vo/endereco-pessoa';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-padrao-endereco-pessoa',
  templateUrl: './padrao-endereco-pessoa.component.html',
  styleUrls: ['./padrao-endereco-pessoa.component.css'],
})
export class PadraoEnderecoPessoaComponent implements OnInit {

  @Input() refazerBusca: any;
  @Output() respostaCriaAtualiza = new EventEmitter;
  @Output() respostaTornaPadrao = new EventEmitter;

  public isLoading: boolean;
  public displayModalpadraoEnderecoPessoa: boolean;
  public idItem: string = "";
  public msgs: Message[] = [];
  public index: number;
  public EnderecoPessoa = new EnderecoPessoaVO;

  constructor(public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
  }

  showDialogPadraoEnderecoPessoa(idItem) {
    this.EnderecoPessoa = idItem;
    this.displayModalpadraoEnderecoPessoa = true;
  }

  hideDialogItem() {
    this.idItem = "";
    this.displayModalpadraoEnderecoPessoa = false;
    this.isLoading = false;
    this.respostaCriaAtualiza.emit(1);
  }

  tornarEnderecoPessoa() {
    this.displayModalpadraoEnderecoPessoa = false;
    this.respostaTornaPadrao.emit(this.EnderecoPessoa);
    this.respostaCriaAtualiza.emit(1);
  }

}
