import { Component, OnInit } from '@angular/core';
// PrimeNg
import { Message } from 'primeng/components/common/api';
// Service
import { DetalhaEnderecoPessoaService } from './detalha-endereco-pessoa.service';

@Component({
  selector: 'app-detalha-endereco-pessoa',
  templateUrl: './detalha-endereco-pessoa.component.html',
  styleUrls: ['./detalha-endereco-pessoa.component.css'],
  providers: [DetalhaEnderecoPessoaService],
})

export class DetalhaEnderecoPessoaComponent implements OnInit {

  public detalheItem: any;
  public isLoading: boolean;
  public visibleForm: boolean;
  public idItem: string = "";
  public edit: boolean;
  public endereco: any;
  public msgs: Message[] = [];
  public displayModalDetalhaEnderecoPessoa: boolean;

  constructor(public detalhaEnderecoPessoaService: DetalhaEnderecoPessoaService) { }

  ngOnInit() {
  }

  showDialogDetalhaEnderecoPessoa(id) {
    this.endereco = id;
    this.displayModalDetalhaEnderecoPessoa = true;
  }

  hideDialogDetalhaItem() {
    this.displayModalDetalhaEnderecoPessoa = false;
  }

}
