import { Component, OnInit, ViewChild } from '@angular/core';
import { EtapaWorkflowVO } from '../../../../../base/vo/help-service/etapa-workflow';
import { TabView } from 'primeng/components/tabview/tabview';

@Component({
  selector: 'app-detalhe-etapa-workflow',
  templateUrl: './detalhe-etapa-workflow.component.html',
  styleUrls: ['./detalhe-etapa-workflow.component.css']
})
export class DetalheEtapaWorkflowComponent implements OnInit {

  @ViewChild('tabView') tabView: TabView;

  public displayModalDetalheEtapa: Boolean;
  public etapa: EtapaWorkflowVO;
  public listaGrupoAtendimento: any;
  public listaEtapaSequencia: any;
  public isEtapaEmpty: boolean =  false;
  public isDepEmpty: boolean =  false;
  constructor() { }

  ngOnInit() {

  }

  showDialogDetalheEtapa(etapa: EtapaWorkflowVO) {
    this.etapa = new EtapaWorkflowVO();
    this.etapa = etapa;
    this.displayModalDetalheEtapa = true;
    this.popula();
    this.defineTabPanelPadrao();
  }

  fechar() {
    this.isEtapaEmpty = false;
    this.isDepEmpty = false;
    this.displayModalDetalheEtapa = false;
  }

  private popula() {
    this.listaGrupoAtendimento = [];
    this.listaEtapaSequencia = [];

    this.listaGrupoAtendimento = this.etapa.gruposSelecionados;
    this.listaEtapaSequencia = this.etapa.sequenciasSelecionadas;

    if(this.listaEtapaSequencia.length < 1)
    {
      this.isEtapaEmpty = true;
    }
    else
    {
      this.isEtapaEmpty = false;
    }

    if(this.listaGrupoAtendimento.length < 1)
    {
      this.isDepEmpty = true;
    }
    else
    {
      this.isDepEmpty = false;
    }
  }

  private defineTabPanelPadrao() {
    this.tabView.tabs.forEach(tab => {
      tab.selected = false;
    });
    this.tabView.tabs[0].selected = true;
  }

}
