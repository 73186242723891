import { Utils } from './../../../../base/util/utils';
import { Component, OnInit } from '@angular/core';
import { LaudoServices } from '../laudo.services';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-detalhe-laudo',
  templateUrl: './detalhe-laudo.component.html',
  styleUrls: ['./detalhe-laudo.component.css']
})
export class DetalheLaudoComponent implements OnInit {

  
  // Modal Detalhes
  public displayModalDetalhes: boolean;
  public detalhesLaudo: any;
  public detalheLaudoDrogasResultado: any;
  public detalheLaudoHistoricoAnalise: any;
  public isLoadingDetalhe: boolean = false;
  qtddDrogas: number = 0;
  
  constructor(private laudoServices: LaudoServices, private serviceUtils: Utils) { }  

  ngOnInit() {
  }

  public exibirModalDetalhes(laudo: any) {
    this.isLoadingDetalhe = true;
    this.recuperaLaudo(laudo.id);
    // this.detalhesLaudo = laudo;
    this.displayModalDetalhes = true;
  }

  private recuperaLaudo(id: number) {
    this.laudoServices.recuperarLaudo(id).subscribe(
      result => {
        this.detalhesLaudo = result;
        this.detalheLaudoDrogasResultado = this.detalhesLaudo.drogas;
        let filteredItems: any;
        filteredItems = this.detalheLaudoDrogasResultado.map(function (el) {
          return el.nomeDroga;
        }).filter(this.serviceUtils.onlyUnique);
        this.qtddDrogas = filteredItems.length;
        this.detalheLaudoHistoricoAnalise = this.detalhesLaudo.historicoAnalise;
        console.log("detalhes do laudo:");
        console.log(this.detalhesLaudo);
;        this.isLoadingDetalhe = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  public esconderModalDetalhes() {
    this.displayModalDetalhes = false;
    this.detalhesLaudo = null;
    this.qtddDrogas = 0;
  }

  replace(item: number): string {
    if (!isNullOrUndefined(item) && item != 0) {
      var txt = item.toString().replace(".", ",");
      if(txt.length > 6)
      {
        if(txt.length == 7)
          txt = txt.substring(0,1).concat(".").concat(txt.substring(1));
        else if(txt.length == 8)
          txt = txt.substring(0,2).concat(".").concat(txt.substring(2));
        else if(txt.length == 9)
          txt = txt.substring(0,3).concat(".").concat(txt.substring(3));
      }
      return txt;
    } else {
      return "-";
    }
  }

}
