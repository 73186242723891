import { BaseService } from '../../util/base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class ZSAutoSelectService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    RecuperarDados(entidade, valor) {
        const urlDestino = environment.baseUrl + entidade + '/autoselect?search=' + valor;
        return this.get(urlDestino);
    }
}
