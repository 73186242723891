import { EnderecoPessoaVO } from "./endereco-pessoa";

export class ClienteVO {
    constructor(
        public id?: number,
        public idGrupoPessoa?: number,
        public nome?: string,
        public documento?: string,
        public tipoDocumento?: number, // Enum no back
        public tipoPessoa?: number, // Enum no back
        public descTipoPessoa?: string,
        public rg?: string,
        public rgOrgaoExpedidor?: string,
        public dataEmissaoRG?: Date,
        public nomeFantasia?: string,
        public inscricaoEstadual?: string,
        public inscricaoMunicipal?: string,
        public emailFiscal?: string,
        public doadorAnonimo?: boolean,
        public enderecos?: Array<EnderecoPessoaVO>,


        // Endereço Padrão
        public logradouro?: string,
        public complemento?: string,
        public bairro?: string,
        public cep?: string,
        public numero?: string,
        public idCidade?: number,
        public nomeCidade?: string,
        public nomeEstado?: string,

        // Matriz
        public idMatriz?: number,
        public nomeMatriz?: string,
        public documentoMatriz?: string,
        public nomeFantasiaMatriz?: string,
        public inscricaoEstadualMatriz?: string,
        public inscricaoMunicipalMatriz?: string,
        public emailFiscalMatriz?: string,
    ) {}
}
