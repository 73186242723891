import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../../base/util/utils';

// VO
import { StatusSolicitacaoVO } from '../../../../base/vo/status-solicitacao';

// Service
import { NovoStatusSolicitacaoService } from './novo-status-solicitacao.service';

// PrimeNg
import { Message } from 'primeng/components/common/api';
import { StatusSolicitacaoService } from '../Status-solicitacao.service';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-novo-status-solicitacao',
  templateUrl: './novo-status-solicitacao.component.html',
  styleUrls: ['./novo-status-solicitacao.component.css']
})
export class NovoStatusSolicitacaoComponent implements OnInit {

  @Input() statusSolicitacao: StatusSolicitacaoVO;
  @Input() refazerBusca: any;
  @Output() respostaCriaAtualiza = new EventEmitter;
  @Output() MessageDialog = new EventEmitter();

  public codigo: string;
  public descricao: string;
  public bloqueado: boolean;
  public isLoading: boolean;
  public visibleForm: boolean;
  public formSucess: boolean;
  public formErro: boolean;
  public disableStatus: boolean;
  public validaEventButton: boolean;
  public editItem: boolean;
  public idItem: string = '';
  public isCodigoValido: boolean = true;
  public isDescricaoValida: boolean = true;

  public display: boolean;

  public displayModalNovoStatusSolicitacao: boolean;
  public msgs: Message[] = [];

  constructor(private novoStatusSolicitacaoService: NovoStatusSolicitacaoService,
    public statusSolicitacaoService: StatusSolicitacaoService,
    public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();

    if (!this.statusSolicitacao) {
      this.statusSolicitacao = new StatusSolicitacaoVO();
    }

    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }

  enviarStatusSolicitacao() {
    this.validaEventButton = true;
    if (this.validaCampos()) {
      this.isLoading = true;
      this.novoStatusSolicitacaoService.enviarStatusSolicitacao(this.statusSolicitacao).subscribe(
        response => {
          if (response.success && !isNullOrUndefined(response.data)) {
            this.msgs = this.serviceUtils.showSuccess();
            this.limparForm();
          } else {
            this.limparForm();
            this.msgs = this.serviceUtils.showError(response.message);
          }
          this.isLoading = false;
        },
        error => {
          this.serviceUtils.showError('Erro ao tentar cadastrar');
          this.limparForm();
          this.isLoading = false;
          console.log(error);
        });
    }
  }

  atualizarStatusSolicitacao() {
    this.validaEventButton = true;
    if (this.validaCampos()) {
      this.isLoading = true;
      this.novoStatusSolicitacaoService.atualizarStatusSolicitacao(this.statusSolicitacao).subscribe(
        response => {
          if (response.success && !isNullOrUndefined(response.data)) {
            this.msgs = this.serviceUtils.showSuccess();
            this.hideDialogNovoItem();
            this.respostaCriaAtualiza.emit(1);
          } else {
            this.msgs = this.serviceUtils.showError(response.message);
          }
          this.isLoading = false;
        },
        error => {
          this.serviceUtils.showError('Erro ao tentar atualizar');
          this.isLoading = false;
          console.log(error);
        });
    }
  }

  recuperarStatusSolicitacao(idItem: string) {
    this.isLoading = true;
    this.novoStatusSolicitacaoService.recuperarStatusSolicitacao(idItem).subscribe(
      data => {
        this.statusSolicitacao = data;
        this.isLoading = false;
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  validaCampos(): boolean {
    if (this.validaEventButton) {
      const isValido = new Array<any>();
      isValido.push(this.isCodigoValido = !this.serviceUtils.isStringNullOrBlank(this.statusSolicitacao.codigo, true));
      isValido.push(this.isDescricaoValida = !this.serviceUtils.isStringNullOrBlank(this.statusSolicitacao.descricao, true));

      if (!this.isCodigoValido) {
        this.statusSolicitacao.codigo = null;
      }

      if (!this.isDescricaoValida) {
        this.statusSolicitacao.descricao = null;
      }

      return !isValido.some(function isAnyFalse(element) { return (element === false); });
    }
    this.validaEventButton = false;
    return false;
  }

  showDialogNovoItem(idItem) {
    this.limparForm();
    if (idItem) {
      this.idItem = idItem;
      this.editItem = true;
      this.recuperarStatusSolicitacao(idItem);
    } if (!idItem) {
      this.idItem = '';
      this.editItem = false;
      this.statusSolicitacao = new StatusSolicitacaoVO();
      this.statusSolicitacao.status = 1;
    }

    this.displayModalNovoStatusSolicitacao = true;

    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }

  hideDialogNovoItem() {
    this.limparForm();
    this.displayModalNovoStatusSolicitacao = false;
    if (!this.editItem) {
      this.respostaCriaAtualiza.emit(1);
    }
  }

  hideDialogAtualizaItem() {
    this.limparForm();
    this.displayModalNovoStatusSolicitacao = false;
  }

  limparForm() {
    this.statusSolicitacao = new StatusSolicitacaoVO();
    this.isCodigoValido = true;
    this.isDescricaoValida = true;

    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }

}
