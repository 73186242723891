import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'mascaraTelefone'
})
export class TelefonePipe implements PipeTransform{
    telFormatado: string;

    transform(telefone: string){
        if(telefone){            
            if(telefone.length === 8){
               this.telFormatado = telefone.substring(0,4).concat("-").concat(telefone.substring(4,8))                                      
            } 
            if(telefone.length === 11){
                this.telFormatado = "(" + telefone.substring(0,2).concat(")").concat(telefone.substring(2,7)).concat("-").concat(telefone.substring(7,11))                                      
             } 
             if(telefone.length === 10){
                this.telFormatado = "(" + telefone.concat("(").substring(0,2).concat(")").concat(telefone.substring(2,6)).concat("-").concat(telefone.substring(6,10))                                      
             } 
        }

        return this.telFormatado;
    }
}