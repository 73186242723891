import { EstadoVO } from './estado';

export class CidadeVO {
    constructor (
        public id?: number,
        public nome?: string,
        public codigoIBGE?: string,
        public Estado?: EstadoVO,
    ) {}
}
