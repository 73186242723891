import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DetatlheWorkflowComponent } from './detatlhe-workflow/detatlhe-workflow.component';
import { WorkflowService } from './workflow.service';
import { Utils } from '../../../base/util/utils';
import { BuscaComponent } from '../../../base/util/busca.component';
import { isNullOrUndefined } from 'util';
import { Message } from 'primeng/components/common/api';
import { StatusWorkflowComponent } from './status-workflow/status-workflow.component';
import { GerenciarWorkflowComponent } from './gerenciar-workflow/gerenciar-workflow.component';
import { EnumService } from '../../../base/enum/enum.service';
import { DeletarWorkflowComponent } from './deletar-workflow/deletar-workflow.component';

declare var M: any;
declare var $: any;

interface FiltrosBusca {
  tipoSolicitacao: number;
  status: number;
  codigo: string;
  descricao: string;
}

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent extends BuscaComponent implements OnInit {

  @Input() refazerBusca: any;

  @ViewChild('detalheWorkflow')
  public detalheWorkflow: DetatlheWorkflowComponent;

  @ViewChild('statusWorkflow')
  public statusWorkflow: StatusWorkflowComponent;

  @ViewChild(GerenciarWorkflowComponent)
  private gerenciarWorkflowComponent: GerenciarWorkflowComponent;

  @ViewChild(DeletarWorkflowComponent)
  private deletarWorkflowComponent: DeletarWorkflowComponent;

  public displayModalDetalheWorkflow: boolean;

  public recarregaPagina: boolean;
  public listaWorkflow: any;
  public listaTipoSolicitacao: any;

  // filtros
  public filtros: FiltrosBusca;
  public codigo: string;
  public status: number = 0;
  public descricao: string;
  public tipoSolicitacao: number = 0;
  public listaStatus: any;

  // Mensagens
  public msgs: Message[] = [];

  public marcarTodosWorkflow: boolean;
  public listaLiberarWorkflow: Array<any>;
  public listaNaoLiberarWorkflow: Array<any>;
  public displaModalLiberar: boolean;

  // autoComplete
  public activeAutocompleteCodigo: boolean;
  public activeAutocompleteNome: boolean;
  public listaAutoComplete: any;

  constructor(private workflowService: WorkflowService,
              public serviceUtils: Utils,
              private enumService: EnumService) { super(); }

  ngOnInit() {
    this.recuperarTipoSolicitacao();
    this.carregaVariaveis();
    this.buscarWorkflow();
    this.carregaListaStatus();
    this.carregaJQuery();
  }

  public showDialogDetalhe(id: string) {
    this.detalheWorkflow.showDialogDetalhe(id);
  }

  public showDialogStatusWorkflow(idItem) {
    this.statusWorkflow.showDialogStatusWorkflow(idItem);
  }

  public showDialogNovoWorkflow() {
    this.gerenciarWorkflowComponent.showDialogNovoWorkflow(undefined);
  }

  public showDialogEditarWorkflow(workflowId: string) {
    this.gerenciarWorkflowComponent.showDialogNovoWorkflow(workflowId);
  }

  public showDialogDeletaWorkflow(idItem) {
    this.deletarWorkflowComponent.showDialogDeletaWorkflow(idItem);
  }

  public buscarWorkflow() {
    this.populaFiltro();
    this.recuperarWorkflow(0);
  }

  private populaFiltro() {
    this.filtros.codigo = this.codigo;
    this.filtros.descricao = this.descricao;
    this.filtros.status = this.status;
    this.filtros.tipoSolicitacao = this.tipoSolicitacao;
  }

  private recuperarWorkflow(page: number) {
    if (page <= 10) {
      this.pageNumber = 1;
    }

    this.isLoading = true;
    this.workflowService.recuperarWorkflow(this.filtros, page, this.numberOfRows).subscribe(
      result => {
        this.listaWorkflow = result;
        if (!isNullOrUndefined(this.listaWorkflow)) {
          if (this.listaWorkflow.length > 0) {
            this.numberOfRecords = this.listaWorkflow[0].numberOfRows;

            this.resultFound();
          } else {
            this.resultNotFound();
          }
        } else {
          this.resultNotFound();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  recuperarTipoSolicitacao() {
    this.workflowService.recuperaTipoSolicitacao().subscribe(
      data => {
        this.listaTipoSolicitacao = data;
        this.carregaJQuery();
      },
      erro => {
        console.log(erro);
      }
    );
  }

  buscarNome(event) {
    this.workflowService.recuperarNome(event.query).subscribe(
      data => {
        this.listaAutoComplete = data.map(function (el) {
          return el.nome;
        }).filter(this.serviceUtils.onlyUnique);
      }
    );
  }

  buscarCodigo(event) {
    this.workflowService.recuperarCodigo(event.query).subscribe(
      data => {
        this.listaAutoComplete = data.map(function (el) {
          return el.codigo;
        }).filter(this.serviceUtils.onlyUnique);
      }
    );
  }

  public resultNotFound() {
    this.isLoading = false;
    this.isResultFound = false;
    this.isResultNotFound = true;
  }

  public resultFound() {
    this.isLoading = false;
    this.isResultFound = true;
    this.isResultNotFound = false;
  }

  private carregaVariaveis() {
    this.filtros = {} as FiltrosBusca;
  }

  public recarregarPagina(event) {
    this.recarregaPagina = event;
    if (this.recarregaPagina) {
      this.recuperarWorkflow(0);
    }
  }

  private carregaListaStatus() {
    this.enumService.recuperaStatusSolicitacaoWorkflow().subscribe(
      result => {
        this.listaStatus = result;
        this.carregaJQuery();
      },
      error => {
        console.log(error);
      }
    );
  }

  paginate(event) {
    this.recuperarWorkflow(event.page + 1);
    this.pageNumber = event.first;
  }

  private carregaJQuery() {
    setTimeout(function () {
      M.AutoInit();
      $('.dropdown-trigger').dropdown({ "coverTrigger": false });
      $('select').formSelect();
    }, 1);
  }

  refazBusca() {
    this.pageNumber = 0;
    if (this.refazBusca) {
      this.buscarWorkflow();
    }
  }

}
