import { Component } from '@angular/core';

@Component({
  selector: 'app-busca',
  template: ''
})
export class BuscaComponent {
  // Paginator control
  public numberOfRecords: number = 0;
  public numberOfRows: number = 10;
  public pageNumber: number = 0;

  // Controle de busca
  public isLoading: boolean = false;
  public isResultFound: boolean = false;
  public isResultNotFound: boolean = false;
}
