import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// Mask
import { CurrencyMaskModule } from 'ng2-currency-mask';
// PrimeNg
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { GrowlModule } from 'primeng/growl';
import { InputSwitchModule } from 'primeng/inputswitch';
import { KeyFilterModule } from 'primeng/keyfilter';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
// Components
import { ZsDateTimeComponent } from '../zsFrameWork/zs-datetime/zs-datetime.component';
import { ZSUploadComponent } from '../zsFrameWork/zs-upload/zs-upload.component';
import { ZSAutoSelectComponent } from '../zsFrameWork/zs-autoselect/zs-autoselect.component';
import { EnderecoPessoaComponent } from './../../components/pessoas/pessoas-data/endereco-pessoa/endereco-pessoa.component';
import { NovoEnderecoPessoaComponent } from './../../components/pessoas/pessoas-data/endereco-pessoa/novo-endereco-pessoa/novo-endereco-pessoa.component';
import { DetalhaEnderecoPessoaComponent } from './../../components/pessoas/pessoas-data/endereco-pessoa/detalha-endereco-pessoa/detalha-endereco-pessoa.component';
import { DeletarEnderecoPessoaComponent } from './../../components/pessoas/pessoas-data/endereco-pessoa/deletar-endereco-pessoa/deletar-endereco-pessoa.component';
import { PadraoEnderecoPessoaComponent } from './../../components/pessoas/pessoas-data/endereco-pessoa/padrao-endereco-pessoa/padrao-endereco-pessoa.component';
import { PesquisaCidadesComponent } from './../../components/pessoas/pessoas-data/pesquisa-cidade/pesquisa-cidade.component';
import { PesquisaPessoasComponent } from './../../components/pessoas/pessoas-data/pesquisa-pessoas/pesquisa-pessoas.component';
import { DetalharContatoPessoaComponent } from './../../components/pessoas/pessoas-data/contato-pessoa/detalhar-contato-pessoa/detalhar-contato-pessoa.component';
import { NovoClienteComponent } from './../../components/pessoas/pessoas-data/clientes/novo-cliente/novo-cliente.component';
import { DadosTipoPessoaComponent } from './../../components/pessoas/pessoas-config/dados-tipo-pessoa/dados-tipo-pessoa.component';
import { NovoDoadorComponent } from './../../components/pessoas/pessoas-data/doadores/novo-doador/novo-doador.component';
import { DeletarContatoComponent } from './../../components/pessoas/pessoas-data/contato-component/deletar-contato-component/deletar-contato-component.component';
import { DetalhaContatoComponent } from './../../components/pessoas/pessoas-data/contato-component/detalha-contato-component/detalha-contato-component.component';
import { NovoContatoComponent } from '../../components/pessoas/pessoas-data/contato-component/novo-contato-component/novo-contato-component.component';
import { ContatoComponent } from './../../components/pessoas/pessoas-data/contato-component/contato-component.component';
import { AlterarSenhaUsuarioHeaderComponent } from './../../components/login/alterar-senha-usuario-header/alterar-senha-usuario-header.component';
import { DetalheLaudoComponent } from './../../components/laudo-resultado/laudo/detalhe-laudo/detalhe-laudo.component';

// Services
import { ZSAutoSelectService } from '../zsFrameWork/zs-autoselect/zs-autoselect.service';
import { PesquisaCidadesService } from './../../components/pessoas/pessoas-data/pesquisa-cidade/pesquisa-cidade.service';
import { NovoClienteService } from './../../components/pessoas/pessoas-data/clientes/novo-cliente/novo-cliente.service';
import { NovoEnderecoPessoaService } from './../../components/pessoas/pessoas-data/endereco-pessoa/novo-endereco-pessoa/novo-endereco-pessoa.service';
import { DetalhaEnderecoPessoaService } from './../../components/pessoas/pessoas-data/endereco-pessoa/detalha-endereco-pessoa/detalha-endereco-pessoa.service';
import { DeletarEnderecoPessoaService } from './../../components/pessoas/pessoas-data/endereco-pessoa/deletar-endereco-pessoa/deletar-endereco-pessoa.service';
import { PesquisaPessoasService } from './../../components/pessoas/pessoas-data/pesquisa-pessoas/pesquisa-pessoas.service';
import { NovoDoadorService } from './../../components/pessoas/pessoas-data/doadores/novo-doador/novo-doador.service';
import { PessoaService } from './../../components/pessoas/pessoas-config/dados-tipo-pessoa/dados-tipo-pessoa.service';
import { NovoContatoService } from './../../components/pessoas/pessoas-data/contato-component/novo-contato-component/novo-contato-component.service';
import { AlterarSenhaUsuarioHeaderService } from './../../components/login/alterar-senha-usuario-header/alterar-senha-usuario-header.service';
import { LaudoServices } from './../../components/laudo-resultado/laudo/laudo.services';

// Pipes
import { DateHoraFormatPipe } from '../../pipes/date-hora-format.pipe';
import { DateFormatPipe } from '../../pipes/date-format.pipe';
import { EllipsisPipe } from '../../pipes/ellipsis.pipe';
import { CpfPipe } from '../../pipes/mascara-cpf.pipe';
import { CnpjPipe } from '../../pipes/mascara-cnpj.pipe';
import { CepPipe } from '../../pipes/cep.pipe';
import { DocumentoPipe } from '../../pipes/mascara-documento.pipe';
import { TelefonePipe } from '../../pipes/telefone.pipe';
import { RgPipe } from '../../pipes/mascara-rg.pipe';
import { NotaPipe } from '../../pipes/nota.pipe';
import { OrderByPipe } from '../../pipes/orderby.pipe';
import { MonetarioPipe } from '../../pipes/mascara-monetario.pipe';
import { PadLeftPipe } from '../../pipes/padleft.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { PercentualPipe } from './../../pipes/mascara-percentual.pipe';
import { DateHoraSemSegundosFormatPipe } from './../../pipes/date-hora-format.sem-segundos.pipe';
import { CustomDecimalFormat } from './../../pipes/custom-decimal-format.pipe';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CalendarModule,
    DialogModule,
    ProgressSpinnerModule,
    TooltipModule,
    NgxMaskModule.forRoot(),
    // Modules
    DialogModule,
    HttpClientModule,
    FormsModule,
    DropdownModule,
    AutoCompleteModule,
    GrowlModule,
    CalendarModule,
    PaginatorModule,
    TooltipModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    KeyFilterModule,
    InputSwitchModule,
    NgxMaskModule,
    CurrencyMaskModule,
    CheckboxModule,
    TabViewModule,
    AccordionModule,
    DataViewModule,
    OverlayPanelModule,
  ],
  declarations: [
    // Components
    ZsDateTimeComponent,
    ZSUploadComponent,
    ZSAutoSelectComponent,
    EnderecoPessoaComponent,
    NovoEnderecoPessoaComponent,
    DetalhaEnderecoPessoaComponent,
    DeletarEnderecoPessoaComponent,
    PadraoEnderecoPessoaComponent,
    PesquisaCidadesComponent,
    PesquisaPessoasComponent,
    NovoClienteComponent,
    DadosTipoPessoaComponent,
    NovoDoadorComponent,
    ContatoComponent,
    NovoContatoComponent,
    DetalhaContatoComponent,
    DeletarContatoComponent,
    DetalharContatoPessoaComponent,
    AlterarSenhaUsuarioHeaderComponent,
    DetalheLaudoComponent,
    // Pipes
    DateFormatPipe,
    EllipsisPipe,
    CpfPipe,
    CnpjPipe,
    CepPipe,
    TelefonePipe,
    RgPipe,
    NotaPipe,
    OrderByPipe,
    MonetarioPipe,
    PadLeftPipe,
    DateHoraFormatPipe,
    PercentualPipe,
    DocumentoPipe,
    DateHoraSemSegundosFormatPipe,
    CustomDecimalFormat,
  ],
  exports: [
    // Modules
    DialogModule,
    HttpClientModule,
    FormsModule,
    DropdownModule,
    AutoCompleteModule,
    GrowlModule,
    CalendarModule,
    PaginatorModule,
    TooltipModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    KeyFilterModule,
    InputSwitchModule,
    NgxMaskModule,
    CurrencyMaskModule,
    CheckboxModule,
    TabViewModule,
    AccordionModule,
    DataViewModule,
    OverlayPanelModule,
    // Components
    ZsDateTimeComponent,
    ZSUploadComponent,
    ZSAutoSelectComponent,
    EnderecoPessoaComponent,
    NovoEnderecoPessoaComponent,
    DetalhaEnderecoPessoaComponent,
    DeletarEnderecoPessoaComponent,
    PadraoEnderecoPessoaComponent,
    PesquisaCidadesComponent,
    PesquisaPessoasComponent,
    NovoClienteComponent,
    DadosTipoPessoaComponent,
    NovoDoadorComponent,
    ContatoComponent,
    NovoContatoComponent,
    DetalhaContatoComponent,
    DeletarContatoComponent,
    DetalharContatoPessoaComponent,
    AlterarSenhaUsuarioHeaderComponent,
    DetalheLaudoComponent,
    // Pipes
    DateFormatPipe,
    EllipsisPipe,
    CpfPipe,
    CnpjPipe,
    CepPipe,
    TelefonePipe,
    RgPipe,
    NotaPipe,
    OrderByPipe,
    MonetarioPipe,
    PadLeftPipe,
    DateHoraFormatPipe,
    PercentualPipe,
    DocumentoPipe,
    DateHoraSemSegundosFormatPipe,
    CustomDecimalFormat,
  ],
  providers: [
    ZSAutoSelectService,
    NovoEnderecoPessoaService,
    DetalhaEnderecoPessoaService,
    DeletarEnderecoPessoaService,
    PesquisaCidadesService,
    PesquisaPessoasService,
    NovoClienteService,
    NovoDoadorService,
    PessoaService,
    NovoContatoService,
    AlterarSenhaUsuarioHeaderService,
    LaudoServices,
  ]
})
export class SharedModule { }
