import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { findIndex } from './../../../../../../node_modules/rxjs/operators';
import { EnumService } from './../../../../base/enum/enum.service';
import { Utils } from './../../../../base/util/utils';
import { DadosTipoPessoaVO } from './../../../../base/vo/dados-tipo-pessoa';
import { PessoaService } from './dados-tipo-pessoa.service';

interface FiltroPessoa {
  tipoDocumento: number;
  documento: string;
}

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-dados-tipo-pessoa',
  templateUrl: './dados-tipo-pessoa.component.html',
  styleUrls: ['./dados-tipo-pessoa.component.css']
})
export class DadosTipoPessoaComponent implements OnInit {

  public isErro: boolean;
  public calendarLocale: any;
  public onlyNumber: RegExp = /[1-9]\d*|0\d+/;
  public isDataEmissao: boolean = true;
  public display: string = "none";
  public dadosContato: DadosTipoPessoaVO = new DadosTipoPessoaVO();
  public isNomeValido: boolean = true;
  public isNomeFantasiaValido: boolean = true;
  public isEmailFiscalValido: boolean = true;
  public isInscricaoEstadualValido: boolean = true;
  public isInscricaoMunicipalValido: boolean = true;
  public isTipoPessoaValido: boolean = true;
  public isTipoDocumentoValido: boolean = true;
  public isCpfValido: boolean = true;
  public isRneValido: boolean = true;
  public isCnpjValido: boolean = true;
  public isOrgaoExpedidorValido: boolean = true;
  public isDataEmissaoValido: boolean = true;
  public isRgValido: boolean = true;
  public listaTipoPessoa: any;
  public listaTipoDocumento: any;
  public filtros: FiltroPessoa;
  public listaPessoa: any;
  public resultFound: boolean;
  public resultNotFound: boolean;
  public numberOfRecords: number = 0;
  public isLoading: boolean;
  public dataAtual = new Date();
  public dataComponente = new Date();
  public edit: boolean;
  public escondeTipoPessoa: boolean;
  public pf: boolean;
  public pj: boolean;
  public contadorValidacao: boolean;
  public isDataEmissaoValida: boolean = true;

  // validação unitaria controlado pelo pai
  public isEmailObrigatorio: boolean = true;
  public emailPlaceholder: string = "Email Fiscal";
  public isInscricaoEstadualObrigatoria: boolean;
  public isInscricaoMunicipalObrigatoria: boolean;

  constructor(private pessoaService: PessoaService, private enumService: EnumService,
    public serviceUtils: Utils) { }

  @Output() isPjSelecionado: EventEmitter<any> = new EventEmitter();

  @Input() isNaoOcultarDoadorAnonimo: boolean;

  ngOnInit() {
    M.AutoInit();
    this.carregarTipoPessoa();
    this.carregarTipoDocumento();
    if (this.dadosContato.tipoPessoa == 1) { this.dadosContato.tipoDocumento = 1; }

    this.filtros = {} as FiltroPessoa;
  }

  getDisplayTipo() {
    if (this.escondeTipoPessoa) {
      if (this.pf) {
        this.dadosContato.tipoPessoa = 2;
      }
      if (this.pj) {
        this.dadosContato.tipoPessoa = 1;
      }
      return "none";
    }
    if (this.escondeTipoPessoa && this.pj) {
      this.dadosContato.tipoPessoa = 1;
      return "none";
    }
  }

  getDisplay() {
    if (this.dadosContato.tipoPessoa == 2) {
      return "";
    } else {
      return "none";
    }
  }

  private showTipoDocumento() {
    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }

  validaDados(): boolean {

    if (this.contadorValidacao) {

      const isValido = new Array<any>();

      isValido.push(this.isNomeValido = !this.serviceUtils.isStringNullOrBlank(this.dadosContato.nome));
      isValido.push(this.isTipoPessoaValido = !isNullOrUndefined(this.dadosContato.tipoPessoa));

      if (this.dadosContato.tipoPessoa == 2) {

        if (this.dadosContato.tipoDocumento == 2) {
          if (!this.contemLetra(this.dadosContato.nome)) {
            isValido.push(this.isNomeValido = false);
          }
          isValido.push(this.isCpfValido = !this.serviceUtils.isStringNullOrBlank(this.dadosContato.CPF));
          //isValido.push(this.isRgValido = !this.serviceUtils.isStringNullOrBlank(this.dadosContato.RG));
          //isValido.push(this.isOrgaoExpedidorValido = !this.serviceUtils.isStringNullOrBlank(this.dadosContato.orgaoExpedidor));
        }

        if (this.dadosContato.tipoDocumento == 3) {
          isValido.push(this.isRneValido = !this.serviceUtils.isStringNullOrBlank(this.dadosContato.RNE));
        }

        // if (!isNullOrUndefined(this.dadosContato.dataEmissao)) {
        //   if ((new Date(this.dadosContato.dataEmissao)) > new Date()) {
        //     isValido.push(this.isDataEmissaoValido = false);
        //   }
        // }
      }

      if (this.dadosContato.tipoPessoa == 1) {

        this.dadosContato.tipoDocumento = 1;
        isValido.push(this.isCnpjValido = !this.serviceUtils.isStringNullOrBlank(this.dadosContato.CNPJ));
        isValido.push(this.isNomeFantasiaValido = !this.serviceUtils.isStringNullOrBlank(this.dadosContato.nomeFantasia));
        // Validação do InscricaoEstadual se ele for obrigatorio para o componente pai
        if (this.isInscricaoEstadualObrigatoria) {
          isValido.push(this.isInscricaoEstadualValido = !this.serviceUtils.isStringNullOrBlank(this.dadosContato.inscricaoEstadual));
        }
        // Validação do InscricaoMunicipal se ele for obrigatorio para o componente pai
        if (this.isInscricaoMunicipalObrigatoria) {
          isValido.push(this.isInscricaoMunicipalValido = !this.serviceUtils.isStringNullOrBlank(this.dadosContato.inscricaoMunicipal));
        }

        // Validação do E-mail se ele for obrigatorio para o componente pai
        if (this.isEmailObrigatorio) {
          this.isEmailFiscalValido = !this.serviceUtils.isStringNullOrBlank(this.dadosContato.emailFiscal);

          if (this.isEmailFiscalValido) {
            this.isEmailFiscalValido = this.serviceUtils.validaEmails(this.dadosContato.emailFiscal);
          }
        } else {
          this.isEmailFiscalValido = this.serviceUtils.validaEmails(this.dadosContato.emailFiscal);
        }
        isValido.push(this.isEmailFiscalValido);

      } else {
        isValido.push(this.isTipoDocumentoValido = !isNullOrUndefined(this.dadosContato.tipoDocumento));

      }

      return !isValido.some(function isAnyFalse(element) { return (element === false); });
    }
  }


  verificaTipoPessoa() {
    if (this.dadosContato.tipoPessoa == 1) {
      this.pf = false;
      this.pj = true;
      this.selecionouPJ(true);
    }
    if (this.dadosContato.tipoPessoa != 1) {
      this.pf = true;
      this.pj = false;
      this.selecionouPJ(false);
    }
    this.dadosContato.tipoDocumento = null;
  }

  selecionouPJ(selecionou: boolean)
  {
      this.isPjSelecionado.emit(selecionou);
  }

  carregarTipoPessoa() {
    if (!this.isLoading) {
      this.enumService.recuperaTipoPessoa().subscribe(
        data => {
          this.listaTipoPessoa = data;
        },
        erro => {
          this.isErro = true;
          this.listaTipoPessoa = null;
        }
      );
    }
  }

  carregarTipoDocumento() {
    this.enumService.recuperaTipoDocumentoPessoa().subscribe(
      data => {
        this.listaTipoDocumento = data;
        this.listaTipoDocumento.splice(findIndex, 1);
      },
      erro => {
        this.isErro = true;
        this.listaTipoDocumento = null;
      }
    );
  }

  validaDnv(evento) {
    this.validaDados();
  }

  limparForm() {
    this.dadosContato = new DadosTipoPessoaVO;
    this.edit = false;
    this.dadosContato.RG = "";
    this.dadosContato.CPF = '';
    this.dadosContato.CNPJ = "";
    this.dadosContato.dataEmissao = undefined;
    this.isDataEmissaoValida = true;
    this.isNomeValido = true;
    this.isNomeFantasiaValido = true;
    this.isEmailFiscalValido = true;
    this.isInscricaoEstadualValido = true;
    this.isInscricaoMunicipalValido = true;
    this.isTipoPessoaValido = true;
    this.isTipoDocumentoValido = true;
    this.isCpfValido = true;
    this.isRneValido = true;
    this.isCnpjValido = true;
    this.isOrgaoExpedidorValido = true;
    this.isDataEmissaoValido = true;
    this.isRgValido = true;
    this.dadosContato.tipoDocumento = null;
    this.dadosContato.tipoPessoa = null;
    this.getDisplayTipo();
  }

  carregaPessoa() {
    if (!this.edit) {
      this.populaFiltros();
      if (this.filtros.documento !== '' && !isNullOrUndefined(this.filtros.documento)) {
        this.pessoaService.recuperarPessoas(this.filtros).subscribe(
          data => {
            this.listaPessoa = data;
            if (!isNullOrUndefined(this.listaPessoa)) {
              // Encontrou resultado
              this.resultFound = true;
              this.resultNotFound = false;
              this.isLoading = false;
              this.populaDadosGerais();
            } else {
              this.dadosContato.idPessoa = 0;
              this.resultFound = false;
              this.resultNotFound = true;
              this.isLoading = false;
            }
          },
          erro => {
            this.resultFound = false;
            this.resultNotFound = true;
            this.isLoading = false;
            console.log(erro);
          }
        );
      }
    }
  }

  populaDadosGerais() {
    if (!this.edit) {
      this.dadosContato.idPessoa = this.listaPessoa.id;
      this.dadosContato.nome = this.listaPessoa.nome;
      this.dadosContato.doadorAnonimo = this.listaPessoa.doadorAnonimo;
      if (this.pf == true) {
        this.dadosContato.RG = this.listaPessoa.rg;
        this.dadosContato.orgaoExpedidor = this.listaPessoa.rgOrgaoExpedidor;
        this.dadosContato.dataEmissao = this.listaPessoa.dataEmissaoRG;
      }
      if (this.pj == true) {
        this.dadosContato.inscricaoEstadual = this.listaPessoa.inscricaoEstadual;
        this.dadosContato.inscricaoMunicipal = this.listaPessoa.inscricaoMunicipal;
        this.dadosContato.nomeFantasia = this.listaPessoa.nomeFantasia;
        this.dadosContato.emailFiscal = this.listaPessoa.emailFiscal;
      }
    }
  }

  populaFiltros() {
    this.limpaDocumento();
    if (this.dadosContato.tipoPessoa == 2) {
      if (this.dadosContato.tipoDocumento == 2) {
        this.filtros.documento = this.dadosContato.CPF;
      } else {
        this.filtros.documento = this.dadosContato.RNE;
      }
    } else {
      this.filtros.documento = this.dadosContato.CNPJ;
    }
    this.filtros.tipoDocumento = this.dadosContato.tipoDocumento;

  }

  limpaDocumento() {
    this.filtros.documento = '';
    this.filtros.tipoDocumento = 0;
    if (this.dadosContato.tipoDocumento == 2) {
      this.dadosContato.RNE = '';
      this.dadosContato.CNPJ = '';
    }
    if (this.dadosContato.tipoDocumento == 3) {
      this.dadosContato.CPF = '';
      this.dadosContato.CNPJ = '';
    }
    if (this.dadosContato.tipoDocumento == 1) {
      this.dadosContato.CPF = '';
      this.dadosContato.RNE = '';
    }
  }

  contemLetra(nome) {
    let i: number;
    let charAtual;
    let j: number;
    if (!isNullOrUndefined(nome)) {
      for (i = 0; i < nome.length; i++) {
        for (j = 0; j <= 9; j++) {
          charAtual = parseFloat(nome[i]);
          if (j === charAtual) {
            return false;
          }
        }
      }
    }
    return true;
  }

  // ehLetra(evt) {
  //   const theEvent = evt || window.event;
  //   const charCode = (evt.charCode) ? evt.charCode : ((evt.keyCode) ? evt.keyCode :
  //      ((evt.which) ? evt.which : 0));
  //   const regex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;
  //   if ( !regex.test(charCode) ) {
  //     theEvent.returnValue = false;
  //     if (theEvent.preventDefault) { theEvent.preventDefault(); }
  //   }
  // }

  // evt = (evt) ? evt : event;
  //  var charCode = (evt.charCode) ? evt.charCode : ((evt.keyCode) ? evt.keyCode :
  //      ((evt.which) ? evt.which : 0));
  //  if (charCode > 31 && (charCode < 65 || charCode > 90) &&
  //      (charCode < 97 || charCode > 122)) {
  //     return false;
  //  }
  //  return true;



}
