import { Constants } from '../base/util/constants';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(value != null)
      return super.transform(value, Constants.DATE_FMT);
    else
      return "-"
  }
}