export class TipoSolicitacaoVO {
    constructor(
        public id?: number,
        public codigo?: string,
        public descricao?: string,
        public status?: number,
        public numberOfRows?: number,
        public dataAlteracao?: Date,
        public dataCancelamento?: Date,
        public dataCriacao?: Date,
        public descricaoStatus?: string,
        public nomeUsuarioCriacao?: string,
        public nomeUsuarioAlteracao?: string,
        public nomeUsuarioCancelamento?: string,
    ) {}
}
