import { UsuarioAlterarSenhaHeaderVO } from './../../../base/vo/Usuario/usuarioAlterarSenhaHeader';
import { AlterarSenhaUsuarioHeaderService } from './alterar-senha-usuario-header.service';
import { Utils } from './../../../base/util/utils';
import { Message } from 'primeng/components/common/api';
import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';


@Component({
  selector: 'app-alterar-senha-usuario-header',
  templateUrl: './alterar-senha-usuario-header.component.html',
  styleUrls: ['./alterar-senha-usuario-header.component.css']
})
export class AlterarSenhaUsuarioHeaderComponent implements OnInit {

  public senhaAntiga: string;
  public senha: string;
  public senha2: string;
  public usuario: UsuarioAlterarSenhaHeaderVO;

  public displayModalAlterarSenhaUsuarioHeader: boolean;
  public isSenhaAntigaValido: boolean = true;
  public isSenhaValido: boolean = true;
  public isSenha2Valido: boolean = true;
  public isSenhaRequisitosValido: boolean = true;

  public msgs: Message[] = [];

  constructor(public serviceUtils: Utils, private alterarSenhaService: AlterarSenhaUsuarioHeaderService) { }

  ngOnInit() {
  }

  //#region Metodos publicos
  public showModal() {
    this.limpaForm();
    this.displayModalAlterarSenhaUsuarioHeader = true;
  }

  public hideModal() {
    this.limpaForm();
    this.displayModalAlterarSenhaUsuarioHeader = false;
  }

  limpaCriticaSenha(campoParaLimpar: number) {
    if (campoParaLimpar === 1) {
      this.isSenhaValido = true;
    } else if(campoParaLimpar === 2) {
      this.isSenha2Valido = true;
    } else {
      this.isSenhaAntigaValido = true;
    }
    this.isSenhaRequisitosValido = true;
  }

  AlterarSenha() {
    if (this.validaDados()) {
      this.populaVO();
      this.alterarSenhaService.alterarSenha(this.usuario).subscribe(response => {
        let resposta: any;
        resposta = response;
        if (resposta.success && !isNullOrUndefined(resposta.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideModal();
        } else {
          if (!isNullOrUndefined(resposta.data.length)) {
            this.exibirMensagemErro(resposta.data[0].message);
          } else {
            this.exibirMensagemErro(resposta.message);
          }
        }
      },
        erro => {
          console.log(erro);
        });
    }
  }

  exibirMensagemErro(response) {
    let mensagemErro = "<p>" + response.message + "</p><br/>";
    if (!isNullOrUndefined(response.data)) {
      for (let i = 0; i < response.data.length; i++) {
        mensagemErro += "<p>" + response.data[i].message + "</p><br/>";
      }
    } else {
      mensagemErro = response;
    }

    this.msgs = this.serviceUtils.showError(mensagemErro);
  }
  //#endregion

  //#region Metodos privados
  private validaDados() {
    const isValido = new Array<any>();
    const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    isValido.push(this.isSenhaAntigaValido = !this.serviceUtils.isStringNullOrBlank(this.senhaAntiga));
    isValido.push(this.isSenhaValido = !this.serviceUtils.isStringNullOrBlank(this.senha));
    isValido.push(this.isSenha2Valido = !this.serviceUtils.isStringNullOrBlank(this.senha2));
    if (!regExp.test(this.senha)) {
      isValido.push(this.isSenhaRequisitosValido = false);
    }
    if (this.senha !== this.senha2) {
      this.msgs = this.serviceUtils.showError("Essas senhas não coincidem. Tentar novamente");
      isValido.push(this.isSenhaValido = false);
      isValido.push(this.isSenha2Valido = false);
    }

    return !isValido.some(function isAnyFalse(element) { return (element === false); });
  }

  private populaVO() {
    this.usuario.senhaAntiga = this.senhaAntiga;
    this.usuario.senha = this.senha;
    this.usuario.confirmaSenha = this.senha2;
  }

  private limpaForm() {
    this.isSenhaValido = true;
    this.isSenha2Valido = true;
    this.isSenhaAntigaValido = true;
    this.senhaAntiga = null;
    this.senha2 = null;
    this.senha = null;
    this.isSenhaRequisitosValido = true;
    this.usuario = new UsuarioAlterarSenhaHeaderVO();
  }
  //#endregion
}
