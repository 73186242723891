import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../base/util/base.service';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';

@Injectable()
export class StatusGrupoAtendimentoService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  recuperarGrupoAtendimento(id: string) {
    let urlDestino = environment.baseUrl + 'grupo-atendimento';
    let idItem: string;
    if (!isNullOrUndefined(id)) {
      idItem = '/' + id;
      urlDestino += idItem;
    }
    return this.get(urlDestino);
  }

  reativaGrupoAtendimento(tipoGrupoAtendimento) {
    const urlDestino = environment.baseUrl + 'grupo-atendimento/reactive';
    return this.put(urlDestino, tipoGrupoAtendimento);
  }

  inativaGrupoAtendimento(tipoGrupoAtendimento) {
    const urlDestino = environment.baseUrl + 'grupo-atendimento/cancel';
    return this.put(urlDestino, tipoGrupoAtendimento);
  }

}
