import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { BaseService } from './../../../../../base/util/base.service';
import { environment } from './../../../../../../environments/environment';


@Injectable()
export class NovoEnderecoPessoaService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    consultaCep(cep) {
        return this.get(environment.baseUrl + "consulta-cep?cep=" + cep);
    }

    recuperaTipoEnderecoAtivo() {
        return this.get(environment.baseUrl + "tipo-endereco?status=1");
    }

    recuperaTipoEnderecoTodos() {
        return this.get(environment.baseUrl + "tipo-endereco");
    }

    recuperarCidades(idEstado: number) {
        let urlDestino = environment.baseUrl + "cidades";
        urlDestino += this.parametrosUrl(idEstado, 0, 0);
        return this.get(urlDestino);
    }

    parametrosUrl(idEstado: number, page: number, pageSize: number): string {

        let url = "?page=" + page + "&pageSize=" + pageSize;
        if (!isNullOrUndefined(idEstado)) {
            url = url + "&idEstado=" + idEstado;
        }

        return url;
    }

    recuperaEstados() {
        return this.get(environment.baseUrl + "estados");
    }

    recuperaEstadoByID(id) {
        const urlDestino = environment.baseUrl + '/estados/' + id;
        return this.get(urlDestino);
      }
}
