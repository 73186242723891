import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../../base/util/utils';

// Service
import { StatusGrupoAtendimentoService } from './status-grupo-atendimento.service';

// VO
import { GrupoAtendimentoVO } from '../../../../base/vo/grupo-atendimento';

// PrimeNg
import { Message } from 'primeng/components/common/api';

declare var M: any;

@Component({
  selector: 'app-status-grupo-atendimento',
  templateUrl: './status-grupo-atendimento.component.html',
  styleUrls: ['./status-grupo-atendimento.component.css']
})
export class StatusGrupoAtendimentoComponent implements OnInit {

  @Input() refazerBusca: any;
  @Output() respostaCriaAtualiza = new EventEmitter;
  @Output() MessageDialog = new EventEmitter();

  public tipoGrupoAtendimento: GrupoAtendimentoVO;

  public isLoading: boolean;
  public displayModalStatusGrupoAtendimento: boolean;

  public idItem = '';
  public msgs: Message[] = [];

  constructor(private statusGrupoAtendimentoService: StatusGrupoAtendimentoService,
              public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
    this.tipoGrupoAtendimento = new GrupoAtendimentoVO();
  }

  inativarItem() {
    this.tipoGrupoAtendimento.status = 1;
    this.isLoading = true;
    this.statusGrupoAtendimentoService.inativaGrupoAtendimento(this.tipoGrupoAtendimento).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogStatusItem();
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(response.message);
        }
        this.isLoading = false;
      },
      error => {
        this.msgs = this.serviceUtils.showError('Erro ao tentar inativar');
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  ativarItem() {
    this.tipoGrupoAtendimento.status = 2;
    this.isLoading = true;
    this.statusGrupoAtendimentoService.reativaGrupoAtendimento(this.tipoGrupoAtendimento).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogStatusItem();
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(response.message);
        }
        this.isLoading = false;
      },
      error => {
        this.msgs = this.serviceUtils.showError('Erro ao tentar ativar');
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  recuperarGrupoAtendimento(idItem: string) {
    this.isLoading = true;
    this.statusGrupoAtendimentoService.recuperarGrupoAtendimento(idItem).subscribe(
      data => {
        this.tipoGrupoAtendimento = data;
        this.isLoading = false;
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      });
  }

  showDialogStatusItem(idItem) {
    this.idItem = idItem;
    this.recuperarGrupoAtendimento(idItem);
    this.displayModalStatusGrupoAtendimento = true;
  }

  hideDialogStatusItem() {
    this.idItem = '';
    this.displayModalStatusGrupoAtendimento = false;
    this.isLoading = false;
    this.respostaCriaAtualiza.emit(1);
  }
  hideDialogStatus() {
    this.idItem = '';
    this.displayModalStatusGrupoAtendimento = false;
    this.isLoading = false;
  }
}
