import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { BaseService } from '../../../base/util/base.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class SolicitacoesService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    recuperaSolicitacoes(page: number, pageSize: number, filtros: any) {
        let urlDestino = environment.baseUrl + "solicitacoes";
        urlDestino += this.parametrosUrl(filtros, page, pageSize);
        return this.get(urlDestino);
    }

    recuperaOrigensDaSolicitacao() {
        const urlDestino = environment.baseUrl + "origem-solicitacao";
        return this.get(urlDestino);
    }

    recuperaTipoDaSolicitacao() {
        const urlDestino = environment.baseUrl + "tipo-solicitacao";
        return this.get(urlDestino);
    }

    recuperaListaDeWorkflows() {
        const urlDestino = environment.baseUrl + "solicitacao-workflow";
        return this.get(urlDestino);
    }

    recuperaListaDeEtapas(idSolicitacaoWorkflow: number) {
        const urlDestino = environment.baseUrl + "solicitacao-workflow-etapas?idSolicitacaoWorkflow=" + idSolicitacaoWorkflow.toString();
        return this.get(urlDestino);
    }

    recuperaListaDeStatusDaSolicitacao() {
        const urlDestino = environment.baseUrl + "status-solicitacao";
        return this.get(urlDestino);
    }

    cancelarSolicitacaio(idSolicitacao: number) {
        const strJson = JSON.stringify({ id: idSolicitacao});
        const body = JSON.parse(strJson);
        const urlDestino = environment.baseUrl + "solicitacao/Cancel";
        return this.put(urlDestino, body);
    }

    parametrosUrl(filtros: any, page: number, pageSize: number): string {

        let url = "?page=" + page + "&pageSize=" + pageSize;
        url = url + "&periodoIni=" + this.formatarData(filtros.periodoIni);
        url = url + "&periodoFim=" + this.formatarData(filtros.periodoFim);

        if (!isNullOrUndefined(filtros.protocolo)) {
            url = url + "&protocolo=" + filtros.protocolo;
        }
        if (!isNullOrUndefined(filtros.nomeSolicitante)) {
            url = url + "&nomeSolicitante=" + filtros.nomeSolicitante;
        }
        if (!isNullOrUndefined(filtros.idDomOrigemSolicitacao)) {
            url = url + "&idDomOrigemSolicitacao=" + filtros.idDomOrigemSolicitacao;
        }
        if (!isNullOrUndefined(filtros.idDomTipoSolicitacao)) {
            url = url + "&idDomTipoSolicitacao=" + filtros.idDomTipoSolicitacao;
        }
        if (!isNullOrUndefined(filtros.idWorkflow)) {
            url = url + "&idWorkflow=" + filtros.idWorkflow;
        }
        if (!isNullOrUndefined(filtros.idDomSolicitacaoWorkflowEtapa)) {
            url = url + "&idDomSolicitacaoWorkflowEtapa=" + filtros.idDomSolicitacaoWorkflowEtapa;
        }
        if (!isNullOrUndefined(filtros.idDomStatusSolicitacao)) {
            url = url + "&idDomStatusSolicitacao=" + filtros.idDomStatusSolicitacao;
        }

        return url;
    }

    formatarData(data: string): string {

        let splitString;
        let dataFormatada;
        splitString = data.split("/");

        dataFormatada = (splitString[1] + "/" + splitString[0] + "/" + splitString[2]);

        return dataFormatada;
    }
}
