import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Message } from 'primeng/components/common/message';
import { isNullOrUndefined } from 'util';
import { DadosTipoPessoaComponent } from './../../../pessoas-config/dados-tipo-pessoa/dados-tipo-pessoa.component';
import { EnderecoPessoaComponent } from '../../endereco-pessoa/endereco-pessoa.component';
import { NovoEnderecoPessoaComponent } from '../../endereco-pessoa/novo-endereco-pessoa/novo-endereco-pessoa.component';
import { Utils } from './../../../../../base/util/utils';
import { ClienteVO } from './../../../../../base/vo/cliente';
import { NovoClienteService } from './novo-cliente.service';
import { PesquisaPessoasComponent } from '../../pesquisa-pessoas/pesquisa-pessoas.component';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-novo-cliente',
  templateUrl: './novo-cliente.component.html',
  styleUrls: ['./novo-cliente.component.css']
})
export class NovoClienteComponent implements OnInit {

  @Output() respostaCriaAtualiza = new EventEmitter;
  @Output() clienteChange = new EventEmitter<ClienteVO>();

  @ViewChild('novoEnderecoPessoa')
  public novoEnderecoPessoa: NovoEnderecoPessoaComponent;

  @ViewChild(PesquisaPessoasComponent)
  public pesquisaPessoasComponent: PesquisaPessoasComponent;

  public idItem: any;
  public editCliente: boolean;
  public edit: boolean;
  public visibleForm: boolean;
  public currentStep: number;
  public isLoading: boolean;
  public validaEventButton: boolean;
  public ValidaDadosDnv: boolean;
  public isEnderecosValido: boolean = true;
  public listEnderecos: Array<any> = [];
  public matriz: any;
  public isMatrizPreenchida: boolean = false;
  public apareceMatriz: boolean = false;

  public msgs: Message[] = [];

  public displayModalNovoCliente = false;

  constructor(public novoClienteService: NovoClienteService, public serviceUtils: Utils) { }

  @Input() cliente: ClienteVO;

  @ViewChild('dadosTipoPessoa')
  private dadosTipoPessoa: DadosTipoPessoaComponent;

  @ViewChild('enderecoPessoa')
  private enderecoPessoa: EnderecoPessoaComponent;

  ngOnInit() {
    M.AutoInit();
  }

  showForm() {
    this.visibleForm = true;

    setTimeout(function () {
      $('html, body').animate({
      }, 1000);
    }, 1);

  }

  showDialogNovoItem(idItem) {
    this.dadosTipoPessoa.emailPlaceholder = "Email Fiscal*";
    this.limparForm();
    this.displayModalNovoCliente = true;
    if (idItem) {
      this.idItem = idItem;
      this.editCliente = true;
      this.recuperarCliente(idItem);
      this.enderecoPessoa.editEndereco = true;
      this.dadosTipoPessoa.edit = true;
    } if (!idItem) {
      this.enderecoPessoa.editEndereco = false;
      this.idItem = '';
      this.editCliente = false;
      this.limparForm();
    }

    this.showForm();
    setTimeout(() => {
      $('select').formSelect();
    }, 1);

  }

  atribuiDados() {

    this.dadosTipoPessoa.contadorValidacao = true;

    this.cliente.id = this.dadosTipoPessoa.dadosContato.idPessoa;
    this.cliente.nome = this.dadosTipoPessoa.dadosContato.nome;
    this.cliente.tipoPessoa = this.dadosTipoPessoa.dadosContato.tipoPessoa;
    this.cliente.tipoDocumento = this.dadosTipoPessoa.dadosContato.tipoDocumento;
    this.cliente.doadorAnonimo = this.dadosTipoPessoa.dadosContato.doadorAnonimo;

    if (this.dadosTipoPessoa.dadosContato.tipoPessoa == 2) {
      if (this.dadosTipoPessoa.dadosContato.tipoDocumento == 2) {
        this.cliente.documento = this.dadosTipoPessoa.dadosContato.CPF;
      }
      if (this.dadosTipoPessoa.dadosContato.tipoDocumento == 3) {
        this.cliente.documento = this.dadosTipoPessoa.dadosContato.RNE;
      }

      this.cliente.rg = this.dadosTipoPessoa.dadosContato.RG;
      this.cliente.rgOrgaoExpedidor = this.dadosTipoPessoa.dadosContato.orgaoExpedidor;
      this.cliente.dataEmissaoRG = this.dadosTipoPessoa.dadosContato.dataEmissao;
    }

    if (this.dadosTipoPessoa.dadosContato.tipoPessoa == 1) {
      this.cliente.nomeFantasia = this.dadosTipoPessoa.dadosContato.nomeFantasia;
      this.cliente.inscricaoEstadual = this.dadosTipoPessoa.dadosContato.inscricaoEstadual;
      this.cliente.inscricaoMunicipal = this.dadosTipoPessoa.dadosContato.inscricaoMunicipal;
      this.cliente.documento = this.dadosTipoPessoa.dadosContato.CNPJ;
      this.cliente.emailFiscal = this.dadosTipoPessoa.dadosContato.emailFiscal;
    }

    if(!isNullOrUndefined(this.matriz.id))
    {
      this.cliente.idGrupoPessoa = this.matriz.id;
    }

    if (this.listEnderecos.length == 0) // edit sem alterar o endereço
      this.listEnderecos = this.cliente.enderecos;

    this.cliente.enderecos = this.listEnderecos;
  }

  carregaDadosGerais() {

    this.dadosTipoPessoa.dadosContato.idPessoa = this.cliente.id;
    this.dadosTipoPessoa.dadosContato.nome = this.cliente.nome;
    this.dadosTipoPessoa.dadosContato.tipoDocumento = this.cliente.tipoDocumento;
    this.dadosTipoPessoa.dadosContato.tipoPessoa = this.cliente.tipoPessoa;
    this.dadosTipoPessoa.dadosContato.doadorAnonimo = this.cliente.doadorAnonimo;

    if (this.cliente.tipoPessoa == 2) {
      if (this.cliente.tipoDocumento == 2) {
        this.dadosTipoPessoa.dadosContato.CPF = this.cliente.documento;
      }
      if (this.cliente.tipoDocumento == 3) {
        this.dadosTipoPessoa.dadosContato.RNE = this.cliente.documento;
      }

      this.dadosTipoPessoa.dadosContato.RG = this.cliente.rg;
      this.dadosTipoPessoa.dadosContato.orgaoExpedidor = this.cliente.rgOrgaoExpedidor;
      this.dadosTipoPessoa.dadosContato.dataEmissao = this.cliente.dataEmissaoRG;
    }
    if (this.cliente.tipoPessoa == 1) {
      this.dadosTipoPessoa.dadosContato.nomeFantasia = this.cliente.nomeFantasia;
      this.dadosTipoPessoa.dadosContato.inscricaoEstadual = this.cliente.inscricaoEstadual;
      this.dadosTipoPessoa.dadosContato.inscricaoMunicipal = this.cliente.inscricaoMunicipal;
      this.dadosTipoPessoa.dadosContato.CNPJ = this.cliente.documento;
      this.dadosTipoPessoa.dadosContato.emailFiscal = this.cliente.emailFiscal;
    }
  }

  recuperarCliente(idItem: string) {

    this.isLoading = true;
    this.dadosTipoPessoa.isLoading = true;
    this.novoClienteService.recuperarCliente(idItem).subscribe(
      data => {
        this.cliente = data;
        this.enderecoPessoa.listEnderecos = this.cliente.enderecos;

        if(!isNullOrUndefined(this.cliente.idMatriz))
        {
          this.matriz.id = this.cliente.idMatriz;
          this.matriz.nome = this.cliente.nomeMatriz;
          this.matriz.nomeFantasia = this.cliente.nomeFantasiaMatriz;
          this.matriz.documento = this.cliente.documentoMatriz;
          this.matriz.emailFiscal = this.cliente.emailFiscalMatriz;
          this.matriz.inscricaoMunicipal = this.cliente.inscricaoMunicipalMatriz;
          this.matriz.inscricaoEstadual = this.cliente.inscricaoEstadualMatriz;
          this.isMatrizPreenchida = true;
        }

        if(this.cliente.tipoDocumento == 1)
        {
          this.apareceMatriz = true;
        }

        setTimeout(() => {
          $('select').formSelect();
        }, 1);
        this.carregaDadosGerais();
        this.isLoading = false;
        this.dadosTipoPessoa.isLoading = false;
      },
      erro => {
        this.isLoading = false;
        this.dadosTipoPessoa.isLoading = false;
        console.log(erro);
      }
    );
  }

  hideDialogNovoCliente() {
    this.limparForm();
    this.dadosTipoPessoa.limparForm();
    this.displayModalNovoCliente = false;
  }

  hideDialogAtualizaCliente() {
    this.limparForm();
    this.dadosTipoPessoa.limparForm();
    this.displayModalNovoCliente = false;
  }

  enviarCliente() {

    this.validaEventButton = true;
    this.dadosTipoPessoa.contadorValidacao = true;
    var valido = this.dadosTipoPessoa.validaDados();
    var enderecoValido = this.validaDadosEndereco();

    if (valido && enderecoValido) {

      this.atribuiDados();
      this.ValidaDadosDnv = true;

      this.novoClienteService.enviarCliente(this.cliente).subscribe(

        response => {

          if (response.success && !isNullOrUndefined(response.data)) {
            this.cliente.id = response.data.id;
            this.cliente.enderecos[0].padrao = 1;
            this.clienteChange.emit(this.cliente);
            this.msgs = this.serviceUtils.showSuccess();
            this.validaEventButton = false;
            this.limparForm();
            this.respostaCriaAtualiza.emit();
            this.displayModalNovoCliente = false;
          } else {
            if (!isNullOrUndefined(response.data.length)) {
              this.exibirMensagemErro(response.data[0].message);
            } else {
              this.exibirMensagemErro(response.message);
            }
          }

          this.isLoading = false;
          this.dadosTipoPessoa.isLoading = false;
        },
        error => {
          this.msgs = this.serviceUtils.showError('');
          this.isLoading = false;
          this.dadosTipoPessoa.isLoading = false;
          console.log(error);
        });
    }
  }

  atualizarCliente() {

    this.validaEventButton = true;
    this.dadosTipoPessoa.contadorValidacao = true;
    var valido = this.dadosTipoPessoa.validaDados();
    var enderecoValido = this.validaDadosEndereco();

    if (valido && enderecoValido) {

      this.atribuiDados();
      this.ValidaDadosDnv = true;

      this.novoClienteService.atualizaCliente(this.cliente).subscribe(
        response => {
          if (response.success && !isNullOrUndefined(response.data)) {
            this.clienteChange.emit(this.cliente);
            this.msgs = this.serviceUtils.showSuccess();
            this.validaEventButton = false;
            this.limparForm();
            this.respostaCriaAtualiza.emit();
            this.displayModalNovoCliente = false;

          } else {
            if (!isNullOrUndefined(response.data.length)) {
              this.exibirMensagemErro(response.data[0].message);
            } else {
              this.exibirMensagemErro(response.message);
            }
          }
          this.isLoading = false;
          this.dadosTipoPessoa.isLoading = false;
        },
        error => {
          this.msgs = this.serviceUtils.showError('');
          this.isLoading = false;
          this.dadosTipoPessoa.isLoading = false;
          console.log(error);
        });
    }
  }

  validaDadosEndereco() {

    this.cliente.enderecos = this.enderecoPessoa.listEnderecos;
    this.listEnderecos = this.enderecoPessoa.listEnderecos;

    const isValido = new Array<any>();

    if (this.editCliente) {
      this.isEnderecosValido = !isNullOrUndefined(this.cliente.enderecos) && this.cliente.enderecos.length != 0;
    } else {
      this.isEnderecosValido = !isNullOrUndefined(this.listEnderecos);

      if (this.isEnderecosValido) {
        this.isEnderecosValido = this.listEnderecos.length > 0;
      }
    }

    isValido.push(this.isEnderecosValido);

    return !isValido.some(function isAnyFalse(element) { return (element === false); });
  }

  limparForm() {
    this.idItem = 0;
    this.editCliente = false;
    this.cliente = new ClienteVO();
    this.matriz = new ClienteVO();
    this.isMatrizPreenchida = false;
    this.apareceMatriz = false;
    this.dadosTipoPessoa.limparForm();
    this.listEnderecos = [];
    this.enderecoPessoa.listEnderecos = [];
    this.isEnderecosValido = true;
    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }

  populaArrayEnderecos(respostaEvento) {

    if (!this.editCliente && this.edit) {
      this.listEnderecos.push(respostaEvento);
      this.edit = true;
    }
  }

  atualizaArrayEnderecos(respostaEvento) {
    this.listEnderecos = respostaEvento;
  }

  exibirMensagemErro(response) {
    var mensagemErro = "<p>" + response.message + "</p><br/>";
    if (!isNullOrUndefined(response.data)) {
      for (var i = 0; i < response.data.length; i++) {
        mensagemErro += "<p>" + response.data[i].message + "</p><br/>";
      }
    } else {
      mensagemErro = response;
    }

    this.msgs = this.serviceUtils.showError(mensagemErro);
  }

  setMatriz(result) {
    this.matriz = result;
    this.isMatrizPreenchida = true;
  }

  mostraMatriz(event: any)
  {
    this.apareceMatriz = event;
  }

  public showDialogPesquisarMatriz() {
    this.pesquisaPessoasComponent.limpaForm();
    this.pesquisaPessoasComponent.filtros.tipoPessoa = "1";
    this.pesquisaPessoasComponent.ehContrato = true;
    this.pesquisaPessoasComponent.ehCliente = true;
    this.pesquisaPessoasComponent.ehColetor = false;
    this.pesquisaPessoasComponent.ehDoador = false;
    this.pesquisaPessoasComponent.ehTestemunha = false;
    this.pesquisaPessoasComponent.ehVendedor = false;
    this.pesquisaPessoasComponent.tipoPessoa = "1";
    this.pesquisaPessoasComponent.tipoDocumento = 1;
    this.pesquisaPessoasComponent.verificaPessoaFisica();
    this.pesquisaPessoasComponent.showDialogPesquisaPessoa();
  }

  public removerMatriz() {
    this.matriz = new ClienteVO();
    this.isMatrizPreenchida = false;
  }
}
