import { Component, Input, OnInit } from '@angular/core';
// PrimeNg
import { Message } from 'primeng/components/common/api';
import { PessoaVO } from './../../../../../base/vo/pessoa';
// Service
import { DetalhaContatoService } from './detalha-contato-component.service';
import { ContatoPessoaVO } from '../../../../../base/vo/contato-pessoa';

@Component({
  selector: 'app-detalha-contato-component',
  templateUrl: './detalha-contato-component.component.html',
  styleUrls: ['./detalha-contato-component.component.css'],
  providers: [DetalhaContatoService],
})

export class DetalhaContatoComponent implements OnInit {

  @Input() refazerBusca: any;

  public detalheItem: any;
  public isLoading: boolean;
  public visibleForm: boolean;
  public idItem: number;
  public pessoa: PessoaVO;
  public msgs: Message[] = [];
  public displayModalDetalhaContatoPessoa: boolean;

  constructor(public detalharContatoPessoaService: DetalhaContatoService) { }

  ngOnInit() {
    this.pessoa = new PessoaVO;
  }

  recuperarDetalhesContatoPessoa(idItem: number) {
    this.isLoading = true;
    this.detalharContatoPessoaService.recuperarContatoPessoa(this.idItem).subscribe(
      data => {
        this.detalheItem = data;
        this.recuperaDetalhesPessoa(this.detalheItem.idPessoa);
        this.isLoading = false;
      },
      erro => {
        console.log(erro);
        this.isLoading = false;
      }
    );
  }

  recuperaDetalhesPessoa(id) {
    this.detalharContatoPessoaService.recuperarDetalhesPessoa(id).subscribe(
      data => {
        this.pessoa = data;
        this.isLoading = false;
      },
      erro => {
        console.log(erro);
        this.isLoading = false;
      }
    );
  }

  showDialogDetalhaItem(id: any) {
    this.idItem = id;
    this.recuperarDetalhesContatoPessoa(this.idItem);
    this.displayModalDetalhaContatoPessoa = true;
  }

  showDialogDetalhaItemContato(objeto: ContatoPessoaVO) {
    this.detalheItem = objeto;
    this.isLoading = true;
    this.recuperaDetalhesPessoa(this.detalheItem.idPessoa);
    this.displayModalDetalhaContatoPessoa = true;
  }

  hideDialogDetalhaItem() {
    this.displayModalDetalhaContatoPessoa = false;
  }

}
