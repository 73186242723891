import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../../../base/util/base.service';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class PesquisaPessoasService extends BaseService {

  constructor(public http: HttpClient) {
    super(http);
  }

  recuperarContatoPessoa(filtros: any,  page) {
    let urlDestino = environment.baseUrl + 'Pessoas';
    urlDestino += this.parametrosUrl(filtros,  page, 10);
    return this.get(urlDestino);
  }
  parametrosUrl(filtros: any, page, pageSize, isActive?: boolean): string {

    let url = '?page=' + page + '&pageSize=' + pageSize;

    if (isActive) {
      url = url + '&status=1';
    }
    if (!isNullOrUndefined(filtros.nome)) {
      url = url + '&nome=' + filtros.nome;
    }
    if (!isNullOrUndefined(filtros.documento)) {
      url = url + '&documento=' + filtros.documento;
    }
    if (!isNullOrUndefined(filtros.tipoDocumento)) {
      url = url + '&tipoDocumento=' + filtros.tipoDocumento;
    }
    if (!isNullOrUndefined(filtros.tipoPessoa)) {
      url = url + '&tipoPessoa=' + filtros.tipoPessoa;
    }
    if (!isNullOrUndefined(filtros.idUnidadeColeta)) {
      url = url + '&idUnidadeColeta=' + filtros.idUnidadeColeta;
    }

    return url;
  }

  recuperarContatoCliente(filtros: any,  page) {
    let urlDestino = environment.baseUrl + 'clientes/componenteAutoSelect';
    urlDestino += this.parametrosUrl(filtros,  page, 10);
    return this.get(urlDestino);
  }

  recuperarContatoDoador(filtros: any,  page) {
    let urlDestino = environment.baseUrl + 'doadores/componenteAutoSelect';
    urlDestino += this.parametrosUrl(filtros,  page, 10);
    return this.get(urlDestino);
  }

  recuperarContatoColetor(filtros: any,  page) {
    let urlDestino = environment.baseUrl + 'coletores';
    urlDestino += this.parametrosUrl(filtros,  page, 10, true);
    return this.get(urlDestino);
  }

  recuperarContatoTestemunha(filtros: any,  page) {
    let urlDestino = environment.baseUrl + 'testemunhas-coletas';
    urlDestino += this.parametrosUrl(filtros,  page, 10, true);
    return this.get(urlDestino);
  }

  recuperarNome(cod: string) {
    let urlDestino = environment.baseUrl + 'Pessoas?page=1&pageSize=5';
    let codItem: string;
    if (!isNullOrUndefined(cod)) {
        codItem = '&nome=' + cod;
        urlDestino += codItem;
    }
    return this.get(urlDestino);
}

  recuperarNomeCliente(cod: string) {
    let urlDestino = environment.baseUrl + 'clientes/componenteAutoSelect?page=1&pageSize=5';
    let codItem: string;
    if (!isNullOrUndefined(cod)) {
        codItem = '&nome=' + cod;
        urlDestino += codItem;
    }
    return this.get(urlDestino);
  }

  recuperarClienteId(cod: number) {
    const urlDestino = environment.baseUrl + 'clientes/' + cod;
    return this.get(urlDestino);
  }

  recuperarNomeDoador(cod: string) {
    let urlDestino = environment.baseUrl + 'doadores/componenteAutoSelect?page=1&pageSize=5';
    let codItem: string;
    if (!isNullOrUndefined(cod)) {
        codItem = '&nome=' + cod;
        urlDestino += codItem;
    }
    return this.get(urlDestino);
  }

  recuperarNomeColetor(cod: string) {
    let urlDestino = environment.baseUrl + 'coletores?page=1&pageSize=5';
    let codItem: string;
    if (!isNullOrUndefined(cod)) {
        codItem = '&nome=' + cod;
        urlDestino += codItem;
    }
    return this.get(urlDestino);
  }

  recuperarNomeTestemunha(cod: string) {
    let urlDestino = environment.baseUrl + 'testemunhas-coletas?page=1&pageSize=5';
    let codItem: string;
    if (!isNullOrUndefined(cod)) {
        codItem = '&nome=' + cod;
        urlDestino += codItem;
    }
    return this.get(urlDestino);
  }

  recuperarVendedorId(cod: number) {
    const urlDestino = environment.baseUrl + 'vendedores/' + cod;
    return this.get(urlDestino);
  }

  recuperarUnidadeColetaId(id: number) {
    const urlDestino = environment.baseUrl + 'unidade-coletas/' + id;
    return this.get(urlDestino);
  }

  recuperarUnidadeColetaComContratoId(id: number) {
    const urlDestino = environment.baseUrl + 'unidade-coletas/com-contrato' + id;
    return this.get(urlDestino);
  }

  recuperarClienteComContratoId(id: number) {
    const urlDestino = environment.baseUrl + 'clientes/' + id;
    return this.get(urlDestino);
  }

  recuperarContratoVendedor(filtros: any,  page) {
    let urlDestino = environment.baseUrl + 'vendedores';
    urlDestino += this.parametrosUrl(filtros,  page, 10);
    return this.get(urlDestino);
  }

  recuperarUnidadeColeta(filtros: any, page: number, isActive: boolean) {
    let urlDestino = environment.baseUrl + 'unidade-coletas';
    urlDestino += this.parametrosUrl(filtros,  page, 10, isActive);
    return this.get(urlDestino);
  }

  recuperarUnidadeColetaComContrato(filtros: any, page: number, isActive: boolean) {
    let urlDestino = environment.baseUrl + 'unidade-coletas/com-contrato';
    urlDestino += this.parametrosUrl(filtros,  page, 10, isActive);
    return this.get(urlDestino);
  }

  recuperarClienteComContrato(filtros: any, page: number, isActive?: boolean) {
    let urlDestino = environment.baseUrl + 'contratos-cliente/cliente';
    urlDestino += this.parametrosUrl(filtros,  page, 10, isActive);
    return this.get(urlDestino);
  }

  recuperarNomeVendedor(cod: string) {
    let urlDestino = environment.baseUrl + 'vendedores?page=1&pageSize=5';
    let codItem: string;
    if (!isNullOrUndefined(cod)) {
        codItem = '&nome=' + cod;
        urlDestino += codItem;
    }
    return this.get(urlDestino);
  }
}
