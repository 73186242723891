import { Component, Input, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { ValueAccessorBase } from './value-accessor';
import { ZSAutoSelectService } from './zs-autoselect.service';
import { isNullOrUndefined } from 'util';

import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'zs-auto-select',
  templateUrl: './zs-autoselect.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ZSAutoSelectComponent,
    multi: true,
  }],
})

export class ZSAutoSelectComponent extends ValueAccessorBase<number> implements OnInit {
  @ViewChild(NgModel) autoselect: NgModel;
  @Input() entity: string;
  @Input() fields: string;
  @Input() labels: string;
  @Input() valueField: string;
  @Input() textField: string;
  @Input() defaultText: string;
  @Input() placeholderText: string;
  @Input() placeholder: string;
  @Output() public emitObjetoSelecionado: EventEmitter<any> = new EventEmitter();
  @Output() public emitClearField: EventEmitter<any> = new EventEmitter();
  public search: string;
  public selectItem: any;
  arrayFields: any = null;
  arrayLabels: any = null;
  display = false;
  listaItens: any = [];
  textSelected: string;
  isLoading = true;
  notFound = true;
  msgnRefinarBusca = true;
  numberOfRecords: number;

  constructor(private autoSelectService: ZSAutoSelectService) {
    super();
  }

  ngOnInit(): void {
    this.textSelected = this.defaultText;
    if (isNullOrUndefined(this.placeholderText)) {
      this.placeholderText = "Nome";
    }
  }

  showDialog() {
    this.search = '';
    this.arrayFields = this.fields.split('|');
    this.arrayLabels = this.labels.split('|');
    this.display = true;
    this.carregaTabela();
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      this.carregaTabela();
    }
  }

  carregaTabela() {
    this.listaItens = [];
    this.msgnRefinarBusca = false;
    this.isLoading = true;
    this.autoSelectService.RecuperarDados(this.entity, this.search).subscribe(
      data => {
        this.listaItens = data;
        this.isLoading = false;
        this.notFound = data == null || this.listaItens.length > 0;

        if (this.listaItens.length > 9) {
          this.numberOfRecords = this.listaItens[0].numberOfRows;
          this.msgnRefinarBusca = true;
        }
      },
      erro => {
        console.log(erro);
        this.isLoading = false;
        this.notFound = true;
      }
    );
  }

  seleciona(item) {
    this.textSelected = item[this.textField];
    this.value = item[this.valueField];
    this.display = false;
    this.selectItem = item;
    this.emitObjetoSelecionado.emit(item);
  }

  clearField() {
    this.textSelected = this.defaultText;
    this.value = undefined;
    this.emitClearField.emit();
  }

}
