import { Component, OnInit } from '@angular/core';

// PrimeNg
import { Message } from 'primeng/components/common/api';

// Service
import { DetalhaOrigemSolicitacaoService } from './detalha-origem-solicitacao.service';

@Component({
  selector: 'app-detalha-origem-solicitacao',
  templateUrl: './detalha-origem-solicitacao.component.html',
  styleUrls: ['./detalha-origem-solicitacao.component.css']
})
export class DetalhaOrigemSolicitacaoComponent implements OnInit {

  public detalheItem: any;
  public isLoading: boolean;
  public visibleForm: boolean;
  public idItem = '';

  public msgs: Message[] = [];

  public displayModalDetalhaOrigemSolicitacao: boolean;

  constructor(public detalhaOrigemSolicitacaoService: DetalhaOrigemSolicitacaoService) { }

  ngOnInit() {
  }

  recuperarDetalheOrigemSolicitacao(idItem: string) {
    this.isLoading = true;
    this.detalhaOrigemSolicitacaoService.recuperarOrigemSolicitacao(idItem).subscribe(
      data => {
        this.detalheItem = data;
        this.isLoading = false;
      },
      erro => {
        console.log(erro);
        this.isLoading = false;
      }
    );
  }

  showDialogDetalhaItem(id) {
    this.idItem = id;
    this.recuperarDetalheOrigemSolicitacao(this.idItem);
    this.displayModalDetalhaOrigemSolicitacao = true;
  }

  hideDialogDetalhaItem() {
    this.displayModalDetalhaOrigemSolicitacao = false;
  }

}
