import { Component, OnInit, ViewChild } from '@angular/core';
import { DetalheWorkflowService } from './detatlhe-workflow.service';
import { EtapaWorkflowVO } from '../../../../base/vo/help-service/etapa-workflow';
import { Message } from 'primeng/components/common/api';
import { TabView } from 'primeng/tabview';

@Component({
  selector: 'app-detatlhe-workflow',
  templateUrl: './detatlhe-workflow.component.html',
  styleUrls: ['./detatlhe-workflow.component.css']
})
export class DetatlheWorkflowComponent implements OnInit {

  @ViewChild('tabView') tabView: TabView;

  public displayModalDetalhes: boolean;
  public listaWorkflow: any;
  public isLoading: boolean;
  public idItem: string = "";

  public msgs: Message[] = [];

  public Etapas: Array<EtapaWorkflowVO>;

  public dataCriacao: Date;
  public dataAlteracao: Date;
  public dataCancelamento: Date;
  public nomeUsuarioCriacao: string;
  public nomeUsuarioAlteracao: string;
  public nomeUsuarioCancelamento: string;

  public displayModalDetalheEtapa: Boolean;
  public etapa: EtapaWorkflowVO;
  public listaGrupoAtendimento: any;
  public listaEtapaSequencia: any;
  public isEtapaEmpty: boolean =  false;
  public isDepEmpty: boolean =  false;

  constructor(private detalheWorkflowService: DetalheWorkflowService) { }

  ngOnInit() {
  }

  public showDialogDetalhe(id: string) {
    this.idItem = id;
    this.Etapas = new Array<EtapaWorkflowVO>();
    this.recuperarDetalheWorkflow(this.idItem);
    this.displayModalDetalhes = true;
  }

  public hideDialogDetalhe() {
    this.limpaForm();
    this.displayModalDetalhes = false;
  }

  private recuperarDetalheWorkflow(idItem: string) {
    this.isLoading = true;
    this.detalheWorkflowService.recuperarWorkflowDetalhe(idItem).subscribe(
      data => {
        this.listaWorkflow = data;
        this.popular();
        this.isLoading = false;
      }
    );
  }

  popular() {

    this.listaWorkflow.listaDeEtapa.forEach(etapa => {
        this.Etapas.push(etapa);
    });

    // this.listaWorkflow.listaDeEtapa.sequenciasSelecionadas.forEach(sequencia => {
    //   this.Etapas.sequenciasSelecionadas.push(sequencia);
    // });

    // this.listaWorkflow.listaDeEtapa.gruposSelecionados.forEach(grupoAtendimento => {
    //   this.Etapas.gruposSelecionados.push(grupoAtendimento);
    // });

    this.dataCriacao = this.listaWorkflow.dataCriacao;
    this.dataAlteracao = this.listaWorkflow.dataAlteracao;
    this.dataCancelamento = this.listaWorkflow.dataCancelamento;
    this.nomeUsuarioCriacao = this.listaWorkflow.nomeUsuarioCriacao;
    this.nomeUsuarioAlteracao = this.listaWorkflow.nomeUsuarioAlteracao;
    this.nomeUsuarioCancelamento = this.listaWorkflow.nomeUsuarioCancelamento;
  }

  private limpaForm() {
    this.Etapas = null;
  }

  public getNamesStringFromArray(objWName, key: string) {
    const arr = [];

    objWName.forEach(element => {
      arr.push(element[key]);
    });

    return arr.join(", ");
  }

  showDialogDetalheEtapa(etapa: EtapaWorkflowVO) {
    this.etapa = new EtapaWorkflowVO();
    this.etapa = etapa;
    this.displayModalDetalheEtapa = true;
    this.popula();
    this.defineTabPanelPadrao();
  }

  fechar() {
    this.isEtapaEmpty = false;
    this.isDepEmpty = false;
    this.displayModalDetalheEtapa = false;
  }

  private popula() {
    this.listaGrupoAtendimento = [];
    this.listaEtapaSequencia = [];

    this.listaGrupoAtendimento = this.etapa.gruposSelecionados;
    this.listaEtapaSequencia = this.etapa.sequenciasSelecionadas;

    if(this.listaEtapaSequencia.length < 1)
    {
      this.isEtapaEmpty = true;
    }
    else
    {
      this.isEtapaEmpty = false;
    }

    if(this.listaGrupoAtendimento.length < 1)
    {
      this.isDepEmpty = true;
    }
    else
    {
      this.isDepEmpty = false;
    }
  }

  private defineTabPanelPadrao() {
    this.tabView.tabs.forEach(tab => {
      tab.selected = false;
    });
    this.tabView.tabs[0].selected = true;
  }
}
