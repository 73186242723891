import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../../../../base/util/base.service';
import { environment } from './../../../../../../environments/environment';
import { isNullOrUndefined } from 'util';

@Injectable()
export class DetalharContatoPessoaService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  recuperarContatoPessoa(id: number) {
      let urlDestino = environment.baseUrl + 'contatoPessoa';
      let idItem: string;
      if (!isNullOrUndefined(id)) {
          idItem = '/' + id;
          urlDestino += idItem;
      }
      return this.get(urlDestino);
  }

  recuperarDetalhesPessoa(id: string) {
    let urlDestino = environment.baseUrl + 'Pessoas';
    let idItem: string;
    if (!isNullOrUndefined(id)) {
      idItem = '/' + id;
      urlDestino += idItem;
    }
    return this.get(urlDestino);
  }
}
