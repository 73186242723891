import { Component, OnInit } from '@angular/core';

// Service
import { DetalhaStatusSolicitacaoService } from './detalha-status-solicitacao.service';

// PrimeNg
import { Message } from 'primeng/components/common/api';

@Component({
  selector: 'app-detalha-status-solicitacao',
  templateUrl: './detalha-status-solicitacao.component.html',
  styleUrls: ['./detalha-status-solicitacao.component.css']
})
export class DetalhaStatusSolicitacaoComponent implements OnInit {

  public detalheItem: any;
  public isLoading: boolean;
  public visibleForm: boolean;
  public idItem = '';

  public msgs: Message[] = [];

  public displayModalDetalhaStatusSolicitacao: boolean;

  constructor(public detalhaStatusSolicitacaoService: DetalhaStatusSolicitacaoService) { }

  ngOnInit() {
  }

  recuperarDetalheStatusSolicitacao(idItem: string) {
    this.isLoading = true;
    this.detalhaStatusSolicitacaoService.recuperarStatusSolicitacao(idItem).subscribe(
      data => {
        this.detalheItem = data;
        this.isLoading = false;
      },
      erro => {
        console.log(erro);
        this.isLoading = false;
      }
    );
  }

  showDialogDetalhaItem(id) {
    this.idItem = id;
    this.recuperarDetalheStatusSolicitacao(this.idItem);
    this.displayModalDetalhaStatusSolicitacao = true;
  }

  hideDialogDetalhaItem() {
    this.displayModalDetalhaStatusSolicitacao = false;
  }

}
