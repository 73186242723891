export class DoadorVO {
    constructor(
        public id?: number,
        public nome?: string,
        public documento?: string,
        public tipoDocumento?: number, // Enum no back
        public tipoPessoa?: number, // Enum no back
        public emailFiscal?: string,
        public rg?: string,
        public rgOrgaoExpedidor?: string,
        public dataEmissaoRG?: Date,
        public dataNascimento?: Date,
        public dataValidade?: Date,
        public sexo?: number, // Enum no back
        public cnh?: string,
        public nomeMae?: string,
        public nomePai?: string
    ) {}
}
