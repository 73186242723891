import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GerenciaWorkflowService } from '../gerenciar-workflow.service';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../../../base/util/utils';
import { EtapaWorkflowVO } from '../../../../../base/vo/help-service/etapa-workflow';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-nova-etapa-workflow',
  templateUrl: './nova-etapa-workflow.component.html',
  styleUrls: ['./nova-etapa-workflow.component.css']
})
export class NovaEtapaWorkflowComponent implements OnInit {

  @Output()
  public callbackSalvar: EventEmitter<any> = new EventEmitter();

  @Output()
  public callbackEditar: EventEmitter<any> = new EventEmitter();

  public displayModalNovaEtapa: boolean;
  public listaGrupoAtendimento: any;
  public id: number;
  public codigo: string;
  public nome: string;
  public status: number;
  public gruposSelecionados = new Array<any>();
  public gruposSelecionadosHtml = new Array<any>();
  public isCodigoValido: boolean = true;
  public isNomeValido: boolean = true;
  public listaGrupoAtendimentoValido: boolean = true;
  public isEdit: boolean;
  public editIndex: number;
  public isFirst: boolean;

  constructor(private serviceUtils: Utils, private gerenciaWorkflowService: GerenciaWorkflowService) { }

  ngOnInit() {
    this.listarGrupoAtendimento(1);
  }

  showDialogNovaEtapa(etapa, isFirst) {
    this.displayModalNovaEtapa = true;
    this.isEdit = false;
    this.isFirst = isFirst;
    this.status = 1;

    if (etapa) {
      this.listarGrupoAtendimento(null);
      this.codigo = etapa.codigo;
      this.nome = etapa.nome;
      this.status = etapa.status;
      const x = Object.assign(new Array<any>(), etapa.gruposSelecionados);
      this.gruposSelecionados = x;
      this.gruposSelecionadosHtml = this.gruposSelecionados;
      this.isEdit = true;

    } else {
      this.listarGrupoAtendimento(1);
    }
  }

  private listarGrupoAtendimento(status: number) {
    this.gerenciaWorkflowService.recuperarGrupoAtendimento(status).subscribe(
      result => {
        this.listaGrupoAtendimento = result;
        this.carregarJavascript();
        setTimeout(() => {
          this.gruposSelecionadosHtml.forEach(gs => {
            this.listaGrupoAtendimento.forEach((g, index) => {
              if (g.id === gs.id) {
                $($(".grupos-checkbox")[index]).prop('checked', true);
              }
            });
          });
        }, 0);
      }
    );
  }

  atualizaGrupos(g) {
    this.listaGrupoAtendimento.forEach((grupo) => {
      if (grupo.id === g.id) {
        const val = !this.gruposSelecionadosHtml.map(x => x.id).includes(grupo.id);
        if (val) {
          this.gruposSelecionadosHtml.push(grupo);
        } else {
          this.gruposSelecionadosHtml.forEach((element, index) => {
            if (element.id === g.id) {
              this.gruposSelecionadosHtml.splice(index, 1);
            }
          });
        }
      }
    });
  }

  public limpar() {
    this.codigo = undefined;
    this.nome = undefined;
    this.gruposSelecionados = [];
    this.gruposSelecionadosHtml = [];

    this.isCodigoValido = true;
    this.isNomeValido = true;
    this.listaGrupoAtendimentoValido = true;

    $(".grupos-checkbox").prop('checked', false);
    this.carregarJavascript();
  }

  validaCampos(): boolean {
    const isValido = new Array<any>();

    isValido.push(this.isCodigoValido = !this.serviceUtils.isStringNullOrBlank(this.codigo, true));
    isValido.push(this.isNomeValido = !this.serviceUtils.isStringNullOrBlank(this.nome, true));
    if (this.isFirst) {
      if (this.gruposSelecionadosHtml.length < 1) {
        isValido.push(this.listaGrupoAtendimentoValido = false);
      }
    }

    if (!this.isCodigoValido) {
      this.codigo = null;
    }

    if (!this.isNomeValido) {
      this.nome = null;
    }

    return !isValido.some(function isAnyFalse(element) { return (element === false); });
  }

  salvar() {
    if (this.validaCampos()) {
      if (this.isEdit) {
        this.gruposSelecionados.forEach(grupoSelecionado => {
          if (!this.gruposSelecionadosHtml.includes(grupoSelecionado)) {
            this.gruposSelecionadosHtml.push(grupoSelecionado);
          }
        });
      }

      this.gruposSelecionados = this.gruposSelecionadosHtml;

      const etapaWorkflow = new EtapaWorkflowVO();

      etapaWorkflow.codigo = this.codigo;
      etapaWorkflow.nome = this.nome;
      etapaWorkflow.status = this.status;
      etapaWorkflow.gruposSelecionados = this.gruposSelecionados;

      if (!this.isEdit) {
        this.callbackSalvar.emit(etapaWorkflow);
      } else {
        this.callbackEditar.emit(etapaWorkflow);
      }
      this.limpar();
      this.displayModalNovaEtapa = false;
    }
  }
  fecharDialog() {
    this.limpar();
    this.displayModalNovaEtapa = false;
  }

  private carregarJavascript() {
    setTimeout(function () {
      M.AutoInit();
      $('.dropdown-trigger').dropdown({ "coverTrigger": false });
    }, 1);
  }
}
