export class ArquivoVO {
    constructor(
        public id?: number,
        public idArquivoAnexo?: string,
        public descricaoArquivoAnexo?: string,
        public descricao?: string,
        public dataCriacao?: Date,
        public dataAlteracao?: any,
        public dataCancelamento?: any,
        public nomeUsuarioCriacao?: string,
        public nomeUsuarioAlteracao?: any,
        public nomeUsuarioCancelamento?: any,
        public extensao?: string,
        public mimeType?: string,
        public nomeArquivoAnexo?: string,
        public tipoArquivoAnexo?: string,
        public statusArquivoAnexo?: number
    ) { }
}
