import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { BaseService } from './../../../../../base/util/base.service';
import { environment } from './../../../../../../environments/environment';


@Injectable()
export class NovoClienteService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    recuperarCliente(id: string) {
        let urlDestino = environment.baseUrl + "clientes";
        let idItem: string;
        if (!isNullOrUndefined(id)) {
            idItem = "/" + id;
            urlDestino += idItem;
        }
        return this.get(urlDestino);
    }

    enviarCliente(cliente) {
        return this.post(environment.baseUrl + "clientes", cliente);
    }

    atualizaCliente(cliente) {
        const urlDestino = environment.baseUrl + "clientes";
        return this.put(urlDestino, cliente);
    }
}
