import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { BaseService } from './../../../../../base/util/base.service';
import { environment } from './../../../../../../environments/environment';

@Injectable()
export class DeletarContatoService extends BaseService {

  constructor(public http: HttpClient) {
    super(http);
  }

  deletarItem(idItem) {
    const urlDestino = environment.baseUrl + "contatoPessoa";
    return this.delete(urlDestino + "?Id=" + idItem, "");
  }

  recuperarContatoPessoa(id: string) {
    let urlDestino = environment.baseUrl + "contatoPessoa";
    let idItem: string;
      if (!isNullOrUndefined(id)) {
        idItem = "/" + id;
        urlDestino += idItem;
      }
    return this.get(urlDestino);
  }

}
