import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { BaseService } from '../../../../base/util/base.service';
import { environment } from '../../../../../environments/environment';

// VOs
import { SolicitacaoVO } from '../../../../base/vo/Solicitacoes/solicitacao';
import { SolicitacaoInteracaoVO } from '../../../../base/vo/Solicitacoes/solicitacao-interacao';

@Injectable()
export class NovaSolicitacaoService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    recuperaOrigensDaSolicitacao() {
        const urlDestino = environment.baseUrl + "origem-solicitacao?status=1";
        return this.get(urlDestino);
    }

    recuperaTipoDaSolicitacao() {
        const urlDestino = environment.baseUrl + "tipo-solicitacao?status=1";
        return this.get(urlDestino);
    }

    recuperaListaDeWorkflows(id: number) {
        const urlDestino = environment.baseUrl + "solicitacao-workflow?status=1&idTipoSolicitacao=" + id;
        return this.get(urlDestino);
    }

    recuperaListaDeGrupoDeAtendimento() {
        const urlDestino = environment.baseUrl + "grupo-atendimento";
        return this.get(urlDestino);
    }

    recuperaEtapasDoWorkflow(idSolicitacaoWorkflow: number) {
        const urlDestino = environment.baseUrl + "solicitacao-workflow-etapa-ativas/" + idSolicitacaoWorkflow;
        return this.get(urlDestino);
    }

    recuperaDadosSolicitacaoPorId(idSolicitacao: number) {
        const urlDestino = environment.baseUrl + "solicitacao/" + idSolicitacao.toString();
        return this.get(urlDestino);
    }

    insereNovaSolicitacao(solicitacao: SolicitacaoVO) {
        return this.post(environment.baseUrl + "solicitacao", solicitacao);
    }

    insereNovaSolicitacaoInteracao(solicitacaoInteracao: SolicitacaoInteracaoVO) {
        return this.post(environment.baseUrl + "solicitacao-interacao", solicitacaoInteracao);
    }

}
