import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../../base/util/utils';

// Service
import { DeletarTipoSolicitacaoService } from './deletar-tipo-solicitacao.service';

// VO
import { TipoSolicitacaoVO } from '../../../../base/vo/tipo-solicitacao';

// PrimeNg
import { Message } from 'primeng/components/common/api';

declare var M: any;
declare var $: any;


@Component({
  selector: 'app-deletar-tipo-solicitacao',
  templateUrl: './deletar-tipo-solicitacao.component.html',
  styleUrls: ['./deletar-tipo-solicitacao.component.css']
})
export class DeletarTipoSolicitacaoComponent implements OnInit {

  @Input() refazerBusca: any;
  @Output() respostaCriaAtualiza = new EventEmitter;

  public tipoSolicitacao: TipoSolicitacaoVO = new TipoSolicitacaoVO;
  public isLoading: boolean;

  public displayModalDeletarTipoSolicitacao: boolean;

  public idItem = '';
  public msgs: Message[] = [];

  constructor(private deletarTipoSolicitacaoService: DeletarTipoSolicitacaoService,
              public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
  }

  deletarTipoSolicitacao() {
    this.isLoading = true;
    this.deletarTipoSolicitacaoService.deletarTipoSolicitacao(this.idItem).subscribe(
      response => {
        const mensagem: any = response;
        if (!isNullOrUndefined(response) && mensagem.success) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogDeletarTipoSolicitacao();
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(mensagem.message);
          this.hideDialogDeletarTipoSolicitacao();
        }
      },
      erro => {
        this.serviceUtils.showError('Erro ao Tentar Excluir');
        this.hideDialogDeletarTipoSolicitacao();
        this.isLoading = false;
        console.log(erro);
      });
  }

  recuperarTipoSolicitacao(idItem: string) {
    this.isLoading = true;
    this.deletarTipoSolicitacaoService.recuperarTipoSolicitacao(idItem).subscribe(
      data => {
        this.tipoSolicitacao = data;
        this.isLoading = false;

        setTimeout(() => {
          $('select').formSelect();
        }, 1);
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  showDialogDeletaTipoSolicitacao(idItem) {
    this.idItem = idItem;
    this.recuperarTipoSolicitacao(idItem);
    this.displayModalDeletarTipoSolicitacao = true;
  }

  hideDialogDeletarTipoSolicitacao() {
    this.idItem = '';
    this.displayModalDeletarTipoSolicitacao = false;
    this.respostaCriaAtualiza.emit(1);
  }

}
