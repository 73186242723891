import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { BaseService } from '../../../../base/util/base.service';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class DeletarTipoSolicitacaoService extends BaseService {

  constructor(public http: HttpClient) {
    super(http);
  }

  deletarTipoSolicitacao(idItem) {
    const urlDestino = environment.baseUrl + 'tipo-solicitacao';
    return this.delete(urlDestino + '?Id=' + idItem, "");
  }

  recuperarTipoSolicitacao(id: string) {
    let urlDestino = environment.baseUrl + 'tipo-solicitacao';
    let idItem: string;
      if (!isNullOrUndefined(id)) {
      idItem = '/' + id;
      urlDestino += idItem;
    }
    return this.get(urlDestino);
  }

}
