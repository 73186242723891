import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { JwtHelper } from 'angular2-jwt';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    private rota: string;

    constructor(public router: Router, public jwtHelper: JwtHelper) {
        router.events.subscribe((val) => {
            this.rota = window.location.href.split("?")[0].split("/").pop();
            this.rota = "/" + this.rota;
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!window.navigator.onLine) {
            Swal.fire({
                title: 'Sem Conexão de Internet!',
                text: 'Por favor, restabeleça a sua conexão',
                type: 'error',
                confirmButtonText: 'Ok'
              });
            return throwError(new HttpErrorResponse({ error: 'Você está sem conexão com a internet' }));
        } else {
            return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        // server-side error
                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                    }
                    if (!environment.production) {
                        window.alert(errorMessage);
                    }
                    return throwError(errorMessage);
                })
            );
        }
    }
}
