import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../base/util/base.service';
import { environment } from '../../../../environments/environment';


@Injectable()
export class AlterarSenhaUsuarioHeaderService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    alterarSenha(trocarSenha) {
        const urlDestino = environment.baseUrl + "usuario/alterar-senha-header";
        return this.put(urlDestino, trocarSenha);
    }
}
