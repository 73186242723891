import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from '../util/utils';
import { AlterarSenhaUsuarioHeaderComponent } from './../../components/login/alterar-senha-usuario-header/alterar-senha-usuario-header.component';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild('trocarSenhaComponent')
  public trocarSenhaComponent: AlterarSenhaUsuarioHeaderComponent;

  public visibleAtendimento: boolean;
  public usuario: any;

  constructor(private router: Router,
              private utils: Utils) { }

  ngOnInit() {
    M.AutoInit();
    setTimeout(function() {
      $('.dropdown-trigger').dropdown({"coverTrigger": false});
    }, 1);
    this.usuario = JSON.parse(localStorage.getItem('maxilaborUser'));
  }

  trocarSenha()
  {
    this.trocarSenhaComponent.showModal();
  }

  openAtendimento() {
    document.getElementById("navAtendimento").style.height = "100%";
    document.body.style.overflow = "hidden";
    this.verificaAtendimento();
    this.utils.carregaJQuery();
  }

  closeAtendimento() {
    document.getElementById("navAtendimento").style.height = "0px";
    document.body.style.overflow = "auto";
    this.verificaAtendimento();
    this.utils.carregaJQuery();
  }

  verificaAtendimento() {
    if (this.visibleAtendimento) {
      this.visibleAtendimento = false;
    } else {
      this.visibleAtendimento = true;
    }
  }

  public sair() {
    localStorage.clear();
    this.router.navigateByUrl('/');
  }
}
