import { environment } from './../../../../../../environments/environment';
import { BaseService } from './../../../../../base/util/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';



@Injectable()
export class NovoGrupoAtendimentoUsuarioService extends BaseService {

  constructor(public http: HttpClient) {
    super(http);
  }

  recuperarGrupoAtendimentoUsuario(filtros: any, page: number) {
    let urlDestino = environment.baseUrl + 'grupo-atendimento/usuarios';
    urlDestino += this.parametrosUrl(filtros, page, null);
    console.log('URL DESTINY: ' + urlDestino);
    return this.get(urlDestino);
}

parametrosUrl(filtros: any, page: number, pageSize: number): string {

    let url = '?page=' + page + '&pageSize=' + pageSize;

    if (!isNullOrUndefined(filtros.Nome)) {
        url = url + '&nome=' + filtros.Nome;
    }
    if (!isNullOrUndefined(filtros.Status)) {
       // url = url + '&Status=' + filtros.Status;
    }
    if (!isNullOrUndefined(filtros.idGrupoAtendiento)) {
         url = url + '&idGrupoAtendimento=' + filtros.idGrupoAtendiento;
     }


    return url;
}

recuperarNomes(nome: string) {
    let urlDestino = environment.baseUrl + 'grupo-atendimento/usuarios?page=1&pageSize=5';
    let nomeRamo: string;
    if (!isNullOrUndefined(nome)) {
        nomeRamo = '&nome=' + nome;
        urlDestino += nomeRamo;
    }
    return this.get(urlDestino);
}

cancelaGrupoAtendimento(GrupoAtendimento) {
    const urlDestino = environment.baseUrl + 'grupo-atendimento';
    return this.put(urlDestino, GrupoAtendimento);
}


listaStatus() {
    const urlDestino = environment.baseUrl + 'enum/origem-solicitacao/status';
    return this.get(urlDestino);
}
}

