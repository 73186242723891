import { GrupoAtendimentoUsuarioVO } from './grupo-atendimento-usuario';
export class GrupoAtendimentoVO {
    constructor(
        public id?: number,
        public nome?:  string ,
        public status?: number,
        public descricaoStatus?:  string ,
        public nomeUsuarioCriacao?:  string ,
        public dataCriacao?: Date,
        public nomeUsuarioAlteracao?:  string ,
        public dataAlteracao?:  Date ,
        public numberOfRows?: number,
        public grupoAtendimentoUsuario?: Array<GrupoAtendimentoUsuarioVO>,
    ) {}
}
