import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../../base/util/utils';

// VO
import { OrigemSolicitacaoVO } from '../../../../base/vo/origem-solicitacao';

// Service
import { NovoOrigemSolicitacaoService } from './novo-origem-solicitacao.service';
import { OrigemSolicitacaoService } from '../origem-solicitacao.service';

// PrimeNg
import { Message } from 'primeng/components/common/api';

declare var M: any;
declare var $: any;


@Component({
  selector: 'app-novo-origem-solicitacao',
  templateUrl: './novo-origem-solicitacao.component.html',
  styleUrls: ['./novo-origem-solicitacao.component.css']
})
export class NovoOrigemSolicitacaoComponent implements OnInit {

  @Input() tipoOrigemSolicitacao: OrigemSolicitacaoVO;
  @Input() refazerBusca: any;
  @Output() respostaCriaAtualiza = new EventEmitter;
  @Output() MessageDialog = new EventEmitter();

  public codigo: string;
  public descricao: string;
  public bloqueado: boolean;
  public isLoading: boolean;
  public visibleForm: boolean;
  public formSucess: boolean;
  public formErro: boolean;
  public disableStatus: boolean;
  public validaEventButton: boolean;
  public editItem: boolean;
  public idItem: string = '';
  public isCodigoValido: boolean = true;
  public isDescricaoValida: boolean = true;

  public display: boolean;

  public displayModalNovoOrigemSolicitacao: boolean;
  public msgs: Message[] = [];

  constructor(private novoOrigemSolicitacaoService: NovoOrigemSolicitacaoService,
              public origemSolicitacaoService: OrigemSolicitacaoService,
              public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();

    if (!this.tipoOrigemSolicitacao) {
      this.tipoOrigemSolicitacao = new OrigemSolicitacaoVO();
    }

    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }

  enviarOrigemSolicitacao() {
    this.validaEventButton = true;
    if (this.validaCampos()) {
      this.isLoading = true;
      this.novoOrigemSolicitacaoService.enviarOrigemSolicitacao(this.tipoOrigemSolicitacao).subscribe(
        response => {
          if (response.success && !isNullOrUndefined(response.data)) {
            this.msgs = this.serviceUtils.showSuccess();
            this.limparForm();
          } else {
            this.msgs = this.serviceUtils.showError(response.message);
            this.limparForm();
          }
          this.isLoading = false;
        },
        error => {
          this.serviceUtils.showError('Erro ao tentar cadastrar');
          this.isLoading = false;
          console.log(error);
          this.limparForm();
        });
    }
  }

  atualizarOrigemSolicitacao() {
    this.validaEventButton = true;
    if (this.validaCampos()) {
      this.isLoading = true;
    this.novoOrigemSolicitacaoService.atualizaOrigemSolicitacao(this.tipoOrigemSolicitacao).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogNovoItem();
          this.respostaCriaAtualiza.emit(1);
        } else {
          this.msgs = this.serviceUtils.showError(response.message);
        }
        this.isLoading = false;
      },
      error => {
        this.serviceUtils.showError('Erro ao tentar atualizar');
        this.isLoading = false;
        console.log(error);
      });
    }
  }

  recuperarOrigemSolicitacao(idItem: string) {
    this.isLoading = true;
    this.novoOrigemSolicitacaoService.recuperarOrigemSolicitacao(idItem).subscribe(
      data => {
        this.tipoOrigemSolicitacao = data;
        this.isLoading = false;
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  validaCampos(): boolean {
    if (this.validaEventButton) {
    const isValido = new Array<any>();
      isValido.push(this.isCodigoValido = !this.serviceUtils.isStringNullOrBlank(this.tipoOrigemSolicitacao.codigo, true));
      isValido.push(this.isDescricaoValida = !this.serviceUtils.isStringNullOrBlank(this.tipoOrigemSolicitacao.descricao, true));

      if (!this.isCodigoValido) {
        this.tipoOrigemSolicitacao.codigo = null;
      }

      if (!this.isDescricaoValida) {
        this.tipoOrigemSolicitacao.descricao = null;
      }

      return !isValido.some(function isAnyFalse(element) { return (element === false); });
  }
  this.validaEventButton = false;
  return false;
  }

  showDialogNovoItem(idItem) {
    this.limparForm();
    if (idItem) {
      this.idItem = idItem;
      this.editItem = true;
      this.recuperarOrigemSolicitacao(idItem);
    } if (!idItem) {
      this.idItem = '';
      this.editItem = false;
      this.tipoOrigemSolicitacao = new OrigemSolicitacaoVO();
      this.tipoOrigemSolicitacao.status = 1;
    }

    this.displayModalNovoOrigemSolicitacao = true;

    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }

  hideDialogNovoItem() {
    this.limparForm();
    this.displayModalNovoOrigemSolicitacao = false;
    this.respostaCriaAtualiza.emit(1);
  }

  hideDialogAtualizaItem() {
    this.limparForm();
    this.displayModalNovoOrigemSolicitacao = false;
  }

  limparForm() {
    this.tipoOrigemSolicitacao = new OrigemSolicitacaoVO();
    this.isCodigoValido = true;
    this.isDescricaoValida = true;

    setTimeout(() => {
      $('select').formSelect();
    }, 1);
  }

}
