import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Utils } from '../../../../base/util/utils';
import { GerenciaWorkflowService } from './gerenciar-workflow.service';
import { EtapaWorkflowVO } from '../../../../base/vo/help-service/etapa-workflow';
import { NovaEtapaWorkflowComponent } from './nova-etapa-workflow/nova-etapa-workflow.component';
import { DetalheEtapaWorkflowComponent } from './detalhe-etapa-workflow/detalhe-etapa-workflow.component';
import { isNullOrUndefined } from 'util';
import { Message } from 'primeng/components/common/api';

declare var M: any;
declare var $: any;

interface WorkflowInterface {
  id: number;
  codigo: string;
  nome: string;
  idTipoSolicitacao: number;
  etapaList: Array<EtapaList>;
}

interface EtapaList {
  id: number;
  idTemporario: number;
  codigo: string;
  nome: string;
  status: number;
  grupoAtendimentoList: Array<number>;
  etapaSequenciaList: Array<number>;
}

@Component({
  selector: 'app-gerenciar-workflow',
  templateUrl: './gerenciar-workflow.component.html',
  styleUrls: ['./gerenciar-workflow.component.css']
})
export class GerenciarWorkflowComponent implements OnInit {

  @ViewChild('novaEtapaComponent')
  public novaEtapaComponent: NovaEtapaWorkflowComponent;

  @ViewChild('detalheEtapaComponent')
  public detalheEtapaComponent: DetalheEtapaWorkflowComponent;

  @Output() RecarrePagina = new EventEmitter();

  public id: string;
  public codigo: string;
  public nome: string;
  public idTipoSolicitacao: number;
  public NomeModal: string;

  public statusTipoSolicitacao: number;

  public listaTipoSolicitacao: any;
  public listaSolicitacaoWorkflowEtapa: any;
  public isLoading: boolean;
  public msgs: Message[] = [];

  public workflow: WorkflowInterface;
  public etapa: EtapaList;

  public listaWokflow: any;
  public listaEtapas: Array<EtapaWorkflowVO>;

  public displayModalGerenciarWorkflow: boolean;
  public isEdit: boolean;
  public etapaEditIndex: number;

  // etapaSequencia
  public etapasSelecionados = new Array<any>();
  public etapasSelecionadosHtml = new Array<any>();
  public displayModalEtapaSequencia: boolean;
  public listaDeSequencias = new Array<any>();

  // remover etapa
  public displayModalRemover: boolean;
  private posicaoParaRemover: number;
  public etapaParaRemover: any;
  public apagarNegado: boolean;

  // status etapa
  public displayStatusEtapa: boolean;
  public status: number;
  public objetoCancel: any;
  public cancelIndex: number;

  public IdNovoRegistro: number = 0;

  // validacao
  public isCodigoValido: boolean = true;
  public isNomeValido: boolean = true;
  public isTipoSolicitacaoValido: boolean = true;
  public isListaEtapaValido: boolean = true;
  public isFirst: boolean;
  public DesabilitaSequencia: boolean;
  public isListaVazia: boolean = false;
  public isCarregado: false;
  public isProxEtapaValida: boolean = true;
  public listaEtapasInvalidas = new Array<any>();
  public etapaAtiva = 1;

  constructor(public serviceUtils: Utils, private gerenciaWorkflowService: GerenciaWorkflowService) { }

  ngOnInit() {
    this.limparCampos();
    this.carregarJQuery();
  }

  public showDialogNovoWorkflow(workflowid: string) {
    this.carregarJQuery();
    this.limparCampos();

    if (workflowid) {
      this.id = workflowid;
      this.recuperarWorkflow(workflowid);
      this.NomeModal = "Editar Workflow";
      this.isEdit = true;
      this.recuperarTipoSolicitacao(null);
      this.displayModalGerenciarWorkflow = true;
    } else {
      this.isEdit = false;
      this.NomeModal = "Novo Workflow";
      this.isLoading = false;
      this.recuperarTipoSolicitacao(1);
      this.displayModalGerenciarWorkflow = true;
    }
  }

  public hideDialogNovoWorkflow() {
    this.displayModalGerenciarWorkflow = false;
    this.limparCampos();
  }

  public showDialogDetalheEtapa(etapa: EtapaWorkflowVO) {
    this.detalheEtapaComponent.showDialogDetalheEtapa(etapa);
  }

  public showDialogRemoverEtapa(etapa: EtapaWorkflowVO, posicaoEtapa: number) {
    this.displayModalRemover = true;
    this.posicaoParaRemover = posicaoEtapa;
    this.etapaParaRemover = etapa;
  }

  public hideDialogRemoverEtapa() {
    this.displayModalRemover = false;
    this.posicaoParaRemover = undefined;
  }

  public showDialogStatusEtapa(etapa, index) {
    this.objetoCancel = etapa;
    this.status = etapa.status;
    this.cancelIndex = index;
    this.displayStatusEtapa = true;
  }

  public hideDialogStatusEtapa() {
    this.displayStatusEtapa = false;
  }

  public inativaItem() {
    this.listaEtapas[this.cancelIndex].status = 2;
    this.displayStatusEtapa = false;
  }

  public ativaItem() {
    this.listaEtapas[this.cancelIndex].status = 1;
    this.displayStatusEtapa = false;
  }

  populaCampos() {
    if (this.isEdit) {
      this.carregarJQuery();
      this.codigo = this.listaWokflow.codigo;
      this.nome = this.listaWokflow.nome;
      this.idTipoSolicitacao = this.listaWokflow.idTipoSolicitacao;
      this.isEdit = true;
      this.listaWokflow.listaDeEtapa.forEach(etapa => {
        this.listaEtapas.push(etapa);
      });
      this.listaEtapas.forEach(etapa => {
        etapa.isEtapaUtilizada = false;
      });
    }
  }

  private recuperarWorkflow(idItem: string) {
    this.isLoading = true;
    this.gerenciaWorkflowService.recuperarWorkflow(idItem).subscribe(
      result => {
        this.listaWokflow = result;
        this.populaCampos();
        this.isLoading = false;
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  private recuperarTipoSolicitacao(status: number) {
    this.gerenciaWorkflowService.recuperarTipoSolicitacao(status).subscribe(
      result => {
        this.listaTipoSolicitacao = result;
        this.carregarJQuery();
      }
    );
  }

  showDialogNovaEtapa() {
    this.isListaVazia = false;
    // verifica se esse é o primeira etapa
    // se for a primeira etapa, é obrigatorio ter um departamento
    if (this.isFirstEtapa(undefined)) {
      this.isFirst = true;
    } else {
      this.isFirst = false;
    }
    this.novaEtapaComponent.showDialogNovaEtapa(undefined, this.isFirst);
  }

  editarEtapa(etapa: EtapaWorkflowVO, index) {
    this.etapaEditIndex = index;
    // verifica se esta editando a primeira etapa
    // se estiver editando a primeira etapa o departamento é obrigatorio
    if (this.isFirstEtapa(etapa)) {
      this.isFirst = true;
    } else {
      this.isFirst = false;
    }
    const x = Object.assign(new EtapaWorkflowVO(), etapa);
    this.novaEtapaComponent.showDialogNovaEtapa(x, this.isFirst);
  }

  removerEtapa() {
    // verifica se essa etapa esta vinculada a outra
    this.listaEtapas.forEach(etapa => {
      if (etapa.sequenciasSelecionadas.some(seq => seq.id === this.etapaParaRemover.id)) {
        this.apagarNegado = true;
      }
    });

    if (!this.apagarNegado) {
      this.listaEtapas.splice(this.posicaoParaRemover, 1);
      this.displayModalRemover = false;
      this.limpaRemover();
    } else {
      this.msgs = this.serviceUtils.showError("Etapa " + this.etapaParaRemover.codigo + " está vinculada a outra etapa");
      this.displayModalRemover = false;
      this.limpaRemover();
    }

  }

  public limpaRemover() {
    this.apagarNegado = false;
    this.etapaParaRemover = null;
    this.posicaoParaRemover = null;
  }

  public callbackNovaEtapa(etapaObj) {
    let isValido: boolean = true;
    this.listaEtapas.forEach(etapa => {
      if (etapa.codigo === etapaObj.codigo) {
        isValido = false;
      }
    });

    if (isValido) {
      this.listaEtapas.push(etapaObj);
      this.listaEtapas.forEach((etapa, index, array) => {
        if (isNullOrUndefined(etapa.id)) {
          etapa.id = this.IdNovoRegistro;
          this.IdNovoRegistro -= 1;
          etapa.isNew = true;
        }
      });
    } else {
      this.msgs = this.serviceUtils.showError("O código já existe");
    }
  }

  public callbackEditarEtapa(etapaObj) {
    let isValido: boolean = true;

    this.listaEtapas.forEach((etapa, index) => {
      if (this.etapaEditIndex !== index) {
        if (etapa.codigo === etapaObj.codigo) {
          isValido = false;
        }
      }
    });

    if (isValido) {

      if (etapaObj.gruposSelecionados.length === 0) {
        this.listaEtapas[this.etapaEditIndex].sequenciasSelecionadas = [];
      }

      this.listaEtapas[this.etapaEditIndex].codigo = etapaObj.codigo;
      this.listaEtapas[this.etapaEditIndex].nome = etapaObj.nome;
      this.listaEtapas[this.etapaEditIndex].status = etapaObj.status;
      this.listaEtapas[this.etapaEditIndex].gruposSelecionados = etapaObj.gruposSelecionados;
      // atualiza a lista de sequencia
      this.listaEtapas.forEach(etapa => {
        etapa.sequenciasSelecionadas.forEach(seq => {
          if (seq.id === this.listaEtapas[this.etapaEditIndex].id) {
            seq.codigo = etapaObj.codigo;
            seq.nome = etapaObj.nome;
          }
        });
      });
    } else {
      this.msgs = this.serviceUtils.showError("O código já existe");
    }
  }

  limparCampos() {
    this.listaEtapas = new Array<EtapaWorkflowVO>();
    this.listaWokflow = null;
    this.codigo = "";
    this.nome = "";
    this.idTipoSolicitacao = null;
    this.listaTipoSolicitacao = null;

    this.isCodigoValido = true;
    this.isNomeValido = true;
    this.isTipoSolicitacaoValido = true;
    this.isListaEtapaValido = true;

    this.isListaVazia = false;
    this.IdNovoRegistro = 0;
  }

  private carregarJQuery() {
    setTimeout(function () {
      M.AutoInit();
      $('.dropdown-trigger').dropdown({ "coverTrigger": false });
      $('select').formSelect();
    }, 1);
  }

  // etapaSequencia
  showDialogEtapaSequencia(etapa, index) {
    this.isProxEtapaValida = true;
    this.etapaEditIndex = index;
    this.populaListaSequencia(etapa);
    this.displayModalEtapaSequencia = true;

    const x = Object.assign(new Array<any>(), etapa.sequenciasSelecionadas);
    this.etapasSelecionados = x;

    this.etapasSelecionadosHtml = x;
    setTimeout(() => {
      this.etapasSelecionadosHtml.forEach(es => {
        this.listaDeSequencias.forEach((le, index2) => {
          if (le.id === es.id) {
            $($(".etapas-checkbox")[index2]).prop('checked', true);
          }
        });
      });
    }, 0);
  }

  populaListaSequencia(etapaFront) {
    this.listaEtapas.forEach(etapa => {
      if (etapa.id !== etapaFront.id && etapa.status == 1 && this.isEdit == false) {
        this.listaDeSequencias.push(etapa);
      } else if (etapa.id !== etapaFront.id && this.isEdit == true) {
        this.listaDeSequencias.push(etapa);
      }
    });
  }

  atualizaEtapas(e) {
    this.listaEtapas.forEach((etapa) => {
      if (etapa.id === e.id) {
        const b = !this.etapasSelecionadosHtml.map(x => x.id).includes(etapa.id);
        if (b) {
          this.etapasSelecionadosHtml.push(etapa);
        } else {
          this.etapasSelecionadosHtml.forEach((element, index) => {
            if (element.id === e.id) {
              this.etapasSelecionadosHtml.splice(index, 1);
            }
          });
        }
      }
    });
  }

  public salvar() {
    if (this.etapasSelecionados.length > 0) {

      this.isProxEtapaValida = true;
      this.etapasSelecionados.forEach(etapasSelec => {
        if (!this.etapasSelecionadosHtml.includes(etapasSelec)) {
          this.etapasSelecionadosHtml.push(etapasSelec);
        }
      });

      this.etapasSelecionados = this.etapasSelecionadosHtml;

      this.listaEtapas[this.etapaEditIndex].sequenciasSelecionadas = this.etapasSelecionados;
      this.displayModalEtapaSequencia = false;
    } else {
      this.isProxEtapaValida = false;
      // this.msgs = this.serviceUtils.showError("Favor Selecionar a Próxima Etapa!");
    }
  }

  public emit() {
    if (this.validaCampos()) {
      this.workflow = {} as WorkflowInterface;
      this.workflow.etapaList = new Array<EtapaList>();

      if (this.isEdit) {
        this.workflow.id = parseFloat(this.id);
      }
      this.workflow.codigo = this.codigo;
      this.workflow.nome = this.nome;
      this.workflow.idTipoSolicitacao = this.idTipoSolicitacao;

      this.listaEtapas.forEach(item => {
        this.etapa = {} as EtapaList;
        this.etapa.etapaSequenciaList = new Array<number>();
        this.etapa.grupoAtendimentoList = new Array<number>();

        if (item.isNew) {
          this.etapa.id = undefined;
        } else {
          this.etapa.id = item.id;
        }
        this.etapa.idTemporario = item.id;
        this.etapa.codigo = item.codigo;
        this.etapa.nome = item.nome;
        this.etapa.status = item.status;
        item.gruposSelecionados.forEach(gru => {
          this.etapa.grupoAtendimentoList.push(gru.id);
        });
        item.sequenciasSelecionadas.forEach(seq => {
          this.etapa.etapaSequenciaList.push(seq.id);
        });

        this.workflow.etapaList.push(this.etapa);
      });

      if (this.isEdit) {
        this.EditarWorkflow();
      } else {
        this.CriarWorkflow();
      }
    }
  }

  private EditarWorkflow() {
    this.isLoading = true;
    this.gerenciaWorkflowService.editarWorkflow(this.workflow).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.RecarrePagina.emit();
          this.hideDialogNovoWorkflow();
        } else {
          if (!isNullOrUndefined(response.data[0])) {
            this.msgs = this.serviceUtils.showError(response.data[0].message);
          } else {
            this.msgs = this.serviceUtils.showError(response.message);
          }
          this.isLoading = false;
        }
        this.carregarJQuery();
      },
      error => {
        this.carregarJQuery();
        this.msgs = this.serviceUtils.showError('');
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  private CriarWorkflow() {
    this.isLoading = true;
    this.gerenciaWorkflowService.criarWorkflow(this.workflow).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.RecarrePagina.emit(true);
          this.limparCampos();
          this.isLoading = false;
          this.recuperarTipoSolicitacao(1);
          this.carregarJQuery();
        } else {
          if (!isNullOrUndefined(response.data[0])) {
            this.msgs = this.serviceUtils.showError(response.data[0].message);
            this.carregarJQuery();
          } else {
            this.msgs = this.serviceUtils.showError(response.message);
            this.carregarJQuery();
          }
          this.isLoading = false;
        }
      },
      error => {
        this.msgs = this.serviceUtils.showError('');
        console.log(error);
      }
    );
  }

  public limparSequencia() {
    this.listaDeSequencias = [];
    $(".etapas-checkbox").prop('checked', false);
  }

  public validaCampos(): boolean {
    const isValido = new Array<any>();

    isValido.push(this.isCodigoValido = !this.serviceUtils.isStringNullOrBlank(this.codigo, true));
    isValido.push(this.isNomeValido = !this.serviceUtils.isStringNullOrBlank(this.nome, true));
    isValido.push(this.isTipoSolicitacaoValido = !isNullOrUndefined(this.idTipoSolicitacao));
    isValido.push(this.isListaEtapaValido = this.validaEtapa());

    if (!this.isCodigoValido) {
      this.codigo = null;
    }

    if (!this.isNomeValido) {
      this.nome = null;
    }

    if (!this.isTipoSolicitacaoValido) {
      this.idTipoSolicitacao = null;
    }

    return !isValido.some(function isAnyFalse(element) { return (element === false); });
  }

  public validaEtapa(): boolean {

    let listaEtpaValida = true;
    this.listaEtapasInvalidas = [];

    // sem etapas para o workflow
    if (this.listaEtapas.length === 0) {
      listaEtpaValida = false;
      this.isListaVazia = true;
    }

    if (!this.PrimeiraEtapaIsValid()) {
      listaEtpaValida = false;
      this.msgs = this.serviceUtils.showError("A primeira etapa ativa deve conter um departamento");
    }

    // valida se etapa tem sequencia ou nao, e se tiver verifica se a sequencia é valida
    if (this.listaEtapas.length > 0) {
      this.listaEtapas.forEach((etapa) => {
        if (etapa.gruposSelecionados.length > 0 && etapa.sequenciasSelecionadas.length == 0 && etapa.status === this.etapaAtiva) {
          listaEtpaValida = false;
          this.msgs = this.serviceUtils.showError("Etapa " + etapa.codigo + " não possui sequência atribuída");
        } else
        // se a etapa tem sequencia, verificar se contem sequencia valida
        if (etapa.gruposSelecionados.length > 0 && etapa.sequenciasSelecionadas.length > 0 && etapa.status === this.etapaAtiva) {
          let nmrSequenciasValidas = 0;
          etapa.sequenciasSelecionadas.forEach(sequencia => {
            if (this.isSequeciaValida(sequencia.id)) {
              nmrSequenciasValidas ++;
            }
          });
          if (nmrSequenciasValidas === 0) {
            this.listaEtapasInvalidas.push(etapa);
            nmrSequenciasValidas = 0;
          }
        }
      });
      // se encontrar alguma etapa onde todas as sequencias seja invalidas
      if (this.listaEtapasInvalidas.length > 0) {
        this.msgs = this.serviceUtils.showError("Etapa(s) " + this.getNamesStringFromArray(this.listaEtapasInvalidas, 'codigo') + " não possui sequência atribuída valida");
        listaEtpaValida = false;
      }
    }

    // verificar se a etapa esta sendo utilizada
    this.listaEtapas.forEach((etapa, index, array) => {
      if (index !== 0) {
        array.forEach(etapa2 => {
          etapa2.sequenciasSelecionadas.forEach(sequencia => {
            if (etapa.id === sequencia.id) {
              etapa.isEtapaUtilizada = true;
            }
          });
        });
      }
    });

    // valida se a etapa esta sendo utilizada
    this.listaEtapas.forEach((etapa, index) => {
      if (!etapa.isEtapaUtilizada && index !== 0 && etapa.status  === this.etapaAtiva) {
        listaEtpaValida = false;
        this.msgs = this.serviceUtils.showError("Etapa " + etapa.codigo + " não utilizada");
      }
    });

    let etapaFinal: number = 0;
    this.listaEtapas.forEach((etapa) => {
        if (etapa.gruposSelecionados.length == 0 && etapa.status == this.etapaAtiva) {
          etapaFinal++;
        }
    });

    if (etapaFinal < 1) {
      listaEtpaValida = false;
      this.msgs = this.serviceUtils.showError("O Workflow deve possuir uma etapa final(sem departamentos)");
    }

    return listaEtpaValida;
  }

  private isSequeciaValida(idSequencia: number): boolean {
    let isValid = false;
    this.listaEtapas.forEach(etapa => {
      if (etapa.id == idSequencia && etapa.status == this.etapaAtiva) {
         isValid = true;
      }
    });
    return isValid;
  }

  private PrimeiraEtapaIsValid(): boolean {
    // verifica se a primeira etapa é valida
    let listaEtapasValidas = [];
    let isValid = false;
    listaEtapasValidas = this.listaEtapas.filter(etapa => etapa.status == this.etapaAtiva);
    listaEtapasValidas.forEach((etapa, index) => {
      if (index == 0 && etapa.gruposSelecionados.length > 0) {
        isValid = true;
      }
    });
    return isValid;
  }

  private isFirstEtapa(etapaFront: EtapaWorkflowVO): boolean {
    // verifica se é a primeira etapa
    let listaEtapasValidas = [];
    let isFirst = false;
    listaEtapasValidas = this.listaEtapas.filter(etapa => etapa.status == this.etapaAtiva);

    if (etapaFront) {
      listaEtapasValidas.forEach((etapa, index) => {
        if (etapa.codigo == etapaFront.codigo && index == 0) {
          isFirst = true;
        }
      });
    } else {
      if (listaEtapasValidas.length == 0) {
        isFirst = true;
      }
    }
    return isFirst;
  }

  public isHabilitaSequencia(etapa) {
    if (etapa.gruposSelecionados.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  public soTemUmaEtapa(etapa: any)
  {
    if(isNullOrUndefined(etapa))
    {
      etapa = {} as EtapaList;
    }
    let contador = 0;
    this.listaEtapas.forEach(element => {
      if (element.status == this.etapaAtiva) {
        contador++;
      }
    });

    if (contador > 1) {
      if (etapa.status == 1) {
        if (etapa.gruposSelecionados.length === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public getNamesStringFromArray(objWName, key: string) {
    const arr = [];

    objWName.forEach(element => {
      arr.push(element[key]);
    });

    return arr.join(", ");
  }

}
