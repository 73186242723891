import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { BaseService } from '../../../../base/util/base.service';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class DeletarGrupoAtendimentoService extends BaseService {

  constructor(public http: HttpClient) {
    super(http);
  }


  deletarGrupoAtendimento(idItem) {
    const urlDestino = environment.baseUrl + 'grupo-atendimento/Delete';
    return this.delete(urlDestino + '?Id=' + idItem, "");
  }

  recuperarGrupoAtendimento(id: string) {
    let urlDestino = environment.baseUrl + 'grupo-atendimento';
    let idItem: string;
      if (!isNullOrUndefined(id)) {
        idItem = '/' + id;
        urlDestino += idItem;
      }
    return this.get(urlDestino);
  }

}
