import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { isNullOrUndefined } from 'util';

// VO
import { GrupoAtendimentoVO } from '../../../base/vo/grupo-atendimento';

// Service
import { GrupoAtendimentoService } from './grupo-atendimento.service';

// PrimeNg
import { Message } from 'primeng/components/common/api';

// Component
import { NovoGrupoAtendimentoComponent } from './novo-grupo-atendimento/novo-grupo-atendimento.component';
import { DetalhaGrupoAtendimentoComponent } from './detalha-grupo-atendimento/detalha-grupo-atendimento.component';
import { DeletarGrupoAtendimentoComponent } from './deletar-grupo-atendimento/deletar-grupo-atendimento.component';
import { StatusGrupoAtendimentoComponent } from './status-grupo-atendimento/status-grupo-atendimento.component';
import { Utils } from '../../../base/util/utils';

interface FiltroGrupoAtendimento {
  Nome: string;
  status: number;

}

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-grupo-atendimento',
  templateUrl: './grupo-atendimento.component.html',
  styleUrls: ['./grupo-atendimento.component.css']
})
export class GrupoAtendimentoComponent implements OnInit {

  @ViewChild('novoGrupoAtendimento')
  public novoGrupoAtendimento: NovoGrupoAtendimentoComponent;

  @ViewChild('detalhaGrupoAtendimento')
  public detalhaGrupoAtendimento: DetalhaGrupoAtendimentoComponent;

  @ViewChild('deletaGrupoAtendimento')
  public deletaGrupoAtendimento: DeletarGrupoAtendimentoComponent;

  @ViewChild('StatusGrupoAtendimento')
  public StatusGrupoAtendimento: StatusGrupoAtendimentoComponent;

  @Input() refazerBusca: any;

  public visibleForm: boolean;
  public displayModal: boolean;

  public activeAutocompleteNome: boolean;
  public activeAutocompleteDescricao: boolean;

  public listaGrupoAtendimento: any;

  public isLoading: boolean;
  public numberOfRecords = 0;
  public resultFound: boolean;
  public resultNotFound: boolean;
  public actualpage = 0;
  public salvaPagina = 0;
  public msgs: Message[] = [];
  public listarStatus: any;

  public Nome = '';
  public status: number;

  public tipoGrupoAtendimento: GrupoAtendimentoVO;
  public filtros: FiltroGrupoAtendimento;
  public listaNomes: any;


  public idITipoItemCancela: number;
  public isItensBuscados: boolean;

  constructor(private grupoAtendimentoService: GrupoAtendimentoService, public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
    this.recuperaStatus();
    this.tipoGrupoAtendimento = new GrupoAtendimentoVO;
    this.filtros = {} as FiltroGrupoAtendimento;
  }

  buscarGrupoAtendimento() {
    this.isItensBuscados = true;
    this.populaFiltros();
    this.recuperaGrupoAtendimento(1);
  }

  buscarNomes(event) {
    this.grupoAtendimentoService.recuperarNomes(event.query).subscribe(
      data => {
        this.listaNomes = data.map(function (el) {
          return el.nome;
        }).filter(this.serviceUtils.onlyUnique);
      },
      erro => {
        console.log(erro);
      }
    );
  }

  refazBusca(respostaNovoItem) {
    if (this.refazBusca && this.isItensBuscados) {
      this.buscarGrupoAtendimento();
    }
  }

  public recuperaGrupoAtendimento(page: number) {
    if (page <= 10) {
      this.actualpage = 1;
    }
    this.isLoading = true;
    this.grupoAtendimentoService.recuperarGrupoAtendimento(this.filtros, page).subscribe(
      data => {
        this.listaGrupoAtendimento = data;
        if (!isNullOrUndefined(this.listaGrupoAtendimento) && this.listaGrupoAtendimento.length > 0) {
          this.numberOfRecords = this.listaGrupoAtendimento[0].numberOfRows;
          this.resultFound = true;
          this.resultNotFound = false;
          this.isLoading = false;
          this.salvaPagina = page;
        } else {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
        }
      },
      erro => {
        this.resultFound = false;
        this.resultNotFound = true;
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  paginate(event) {
    this.recuperaGrupoAtendimento(event.page + 1);
    this.actualpage = event.first;
  }

  populaFiltros() {
    this.filtros.Nome = this.Nome;
    this.filtros.status = this.status;

  }

  limpaNome() {
    this.Nome = null;
  }


  hideDialog() {
    this.idITipoItemCancela = null;
    this.displayModal = false;
  }

  showDialogNovoItem(idItem) {
    this.novoGrupoAtendimento.showDialogNovoItem(idItem);
  }

  showDialogDetalhaItem(idItem) {
    this.detalhaGrupoAtendimento.showDialogDetalhaItem(idItem);
  }

  showDialogDeletarItem(idItem) {
    this.deletaGrupoAtendimento.showDialogDeletaGrupoAtendimento(idItem);
  }

  showDialogStatusItem(idItem) {
    this.StatusGrupoAtendimento.showDialogStatusItem(idItem);
  }

  recuperaStatus() {
    this.grupoAtendimentoService.listaStatus().subscribe(
      data => {
        this.listarStatus = data;
        setTimeout(() => {
          $('select').formSelect();
        }, 2);
      },
      erro => {
        console.log(erro);
      }
    );
  }


}
