import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './base/util/http-error.interceptor';

// Services
import { EnumService } from './base/enum/enum.service';
import { CalendarioService } from './base/util/calendario.service';
import { Utils } from './base/util/utils';

import { TipoSolicitacaoService } from './components/help-service/tipo-solicitacao/tipo-solicitacao.service';
import { NovoTipoSolicitacaoService } from './components/help-service/tipo-solicitacao/novo-tipo-solicitacao/novo-tipo-solicitacao.service';
import { DeletarTipoSolicitacaoService } from './components/help-service/tipo-solicitacao/deletar-tipo-solicitacao/deletar-tipo-solicitacao.service';
import { DetalhaTipoSolicitacaoService } from './components/help-service/tipo-solicitacao/detalha-tipo-solicitacao/detalha-tipo-solicitacao.service';
import { StatusTipoSolicitacaoService } from './components/help-service/tipo-solicitacao/status-tipo-solicitacao/status-tipo-solicitacao.service';

import { OrigemSolicitacaoService} from './components/help-service/origem-solicitacao/origem-solicitacao.service';
import { NovoOrigemSolicitacaoService} from './components/help-service/origem-solicitacao/novo-origem-solicitacao/novo-origem-solicitacao.service';
import { DeletarOrigemSolicitacaoService} from './components/help-service/origem-solicitacao/deletar-origem-solicitacao/deletar-origem-solicitacao.service';
import { DetalhaOrigemSolicitacaoService} from './components/help-service/origem-solicitacao/detalha-origem-solicitacao/detalha-origem-solicitacao.service';
import { StatusOrigemSolicitacaoService} from './components/help-service/origem-solicitacao/status-origem-solicitacao/status-origem-solicitacao.service';

import { StatusSolicitacaoService} from './components/help-service/status-solicitacao/Status-solicitacao.service';
import { NovoStatusSolicitacaoService} from './components/help-service/status-solicitacao/novo-status-solicitacao/novo-status-solicitacao.service';
import { DeletarStatusSolicitacaoService} from './components/help-service/status-solicitacao/deletar-status-solicitacao/deletar-status-solicitacao.service';
import { DetalhaStatusSolicitacaoService} from './components/help-service/status-solicitacao/detalha-status-solicitacao/detalha-status-solicitacao.service';
import { CancelaStatusSolicitacaoService} from './components/help-service/status-solicitacao/cancela-status-solicitacao/cancela-status-solicitacao.service';

import { WorkflowService} from './components/help-service/workflow/workflow.service';
import { DetalheWorkflowService } from './components/help-service/workflow/detatlhe-workflow/detatlhe-workflow.service';
import { StatusWorkflowService } from './components/help-service/workflow/status-workflow/status-workflow.service';
import { GerenciaWorkflowService } from './components/help-service/workflow/gerenciar-workflow/gerenciar-workflow.service';
import { GrupoAtendimentoService } from './components/help-service/grupo-atendimento/grupo-atendimento.service';
import { DetalhaGrupoAtendimentoService } from './components/help-service/grupo-atendimento/detalha-grupo-atendimento/detalha-grupo-atendimento.service';
import { DeletarGrupoAtendimentoService } from './components/help-service/grupo-atendimento/deletar-grupo-atendimento/deletar-grupo-atendimento.service';
import { NovoGrupoAtendimentoService } from './components/help-service/grupo-atendimento/novo-grupo-atendimento/novo-grupo-atendimento.service';
import { StatusGrupoAtendimentoService } from './components/help-service/grupo-atendimento/status-grupo-atendimento/status-grupo-atendimento.service';
import { NovoGrupoAtendimentoUsuarioService } from './components/help-service/grupo-atendimento/novo-grupo-atendimento/novo-grupo-atendimento-usuario/novo-grupo-atendimento-usuario.service';

import { SolicitacoesService } from './components/help-service/solicitacoes/solicitacoes.service';
import { DeletarWorkflowService } from './components/help-service/workflow/deletar-workflow/deletar-workflow.service';
import { NovaSolicitacaoService } from './components/help-service/solicitacoes/nova-solicitacao/nova-solicitacao.service';
import { DetalhesSolicitacaoService } from './components/help-service/solicitacoes/detalhes-solicitacao/detalhes-solicitacao.service';
import { JwtHelper } from 'angular2-jwt';
import { AuthGuard } from './components/login/autenticar/auth-guard.service';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './base/header/header.component';

import { SidenavComponent } from './base/sidenav/sidenav.component';
import { BuscaComponent } from './base/util/busca.component';

import { HelpServiceComponent } from './components/help-service/help-service.component';

import { SharedModule } from './base/shared/shared.module';
import { WorkflowComponent } from './components/help-service/workflow/workflow.component';
import { SolicitacoesComponent } from './components/help-service/solicitacoes/solicitacoes.component';
import { GerenciarWorkflowComponent } from './components/help-service/workflow/gerenciar-workflow/gerenciar-workflow.component';

import { TipoSolicitacaoComponent } from './components/help-service/tipo-solicitacao/tipo-solicitacao.component';
import { StatusTipoSolicitacaoComponent } from './components/help-service/tipo-solicitacao/status-tipo-solicitacao/status-tipo-solicitacao.component';
import { NovoTipoSolicitacaoComponent } from './components/help-service/tipo-solicitacao/novo-tipo-solicitacao/novo-tipo-solicitacao.component';
import { DetalhaTipoSolicitacaoComponent } from './components/help-service/tipo-solicitacao/detalha-tipo-solicitacao/detalha-tipo-solicitacao.component';
import { DeletarTipoSolicitacaoComponent } from './components/help-service/tipo-solicitacao/deletar-tipo-solicitacao/deletar-tipo-solicitacao.component';
import { OrigemSolicitacaoComponent } from './components/help-service/origem-solicitacao/origem-solicitacao.component';
import { StatusOrigemSolicitacaoComponent } from './components/help-service/origem-solicitacao/status-origem-solicitacao/status-origem-solicitacao.component';
import { NovoOrigemSolicitacaoComponent } from './components/help-service/origem-solicitacao/novo-origem-solicitacao/novo-origem-solicitacao.component';
import { DetalhaOrigemSolicitacaoComponent } from './components/help-service/origem-solicitacao/detalha-origem-solicitacao/detalha-origem-solicitacao.component';
import { DeletarOrigemSolicitacaoComponent } from './components/help-service/origem-solicitacao/deletar-origem-solicitacao/deletar-origem-solicitacao.component';
import { StatusSolicitacaoComponent } from './components/help-service/status-solicitacao/status-solicitacao.component';
import { CancelaStatusSolicitacaoComponent } from './components/help-service/status-solicitacao/cancela-status-solicitacao/cancela-status-solicitacao.component';
import { NovoStatusSolicitacaoComponent } from './components/help-service/status-solicitacao/novo-status-solicitacao/novo-status-solicitacao.component';
import { DetalhaStatusSolicitacaoComponent } from './components/help-service/status-solicitacao/detalha-status-solicitacao/detalha-status-solicitacao.component';
import { DeletarStatusSolicitacaoComponent } from './components/help-service/status-solicitacao/deletar-status-solicitacao/deletar-status-solicitacao.component';
import { DetatlheWorkflowComponent } from './components/help-service/workflow/detatlhe-workflow/detatlhe-workflow.component';
import { StatusWorkflowComponent } from './components/help-service/workflow/status-workflow/status-workflow.component';
import { NovaEtapaWorkflowComponent } from './components/help-service/workflow/gerenciar-workflow/nova-etapa-workflow/nova-etapa-workflow.component';
import { DetalheEtapaWorkflowComponent } from './components/help-service/workflow/gerenciar-workflow/detalhe-etapa-workflow/detalhe-etapa-workflow.component';

import { GrupoAtendimentoComponent } from './components/help-service/grupo-atendimento/grupo-atendimento.component';
import { StatusGrupoAtendimentoComponent } from './components/help-service/grupo-atendimento/status-grupo-atendimento/status-grupo-atendimento.component';
import { NovoGrupoAtendimentoComponent } from './components/help-service/grupo-atendimento/novo-grupo-atendimento/novo-grupo-atendimento.component';
import { DetalhaGrupoAtendimentoComponent } from './components/help-service/grupo-atendimento/detalha-grupo-atendimento/detalha-grupo-atendimento.component';
import { DeletarGrupoAtendimentoComponent } from './components/help-service/grupo-atendimento/deletar-grupo-atendimento/deletar-grupo-atendimento.component';
import { NovoGrupoAtendimentoUsuarioComponent } from './components/help-service/grupo-atendimento/novo-grupo-atendimento/novo-grupo-atendimento-usuario/novo-grupo-atendimento-usuario.component';
import { ErroComponent } from './components/erro/erro.component';
import { NovaSolicitacaoComponent } from './components/help-service/solicitacoes/nova-solicitacao/nova-solicitacao.component';
import { DetalhesSolicitacaoComponent } from './components/help-service/solicitacoes/detalhes-solicitacao/detalhes-solicitacao.component';
import { DeletarWorkflowComponent } from './components/help-service/workflow/deletar-workflow/deletar-workflow.component';
import { SolicitacaoInteracaoNovoDocumentoComponent } from './components/help-service/solicitacoes/solicitacao-interacao-novo-documento/solicitacao-interacao-novo-documento.component';
import { DetalhesInteracaoComponent } from './components/help-service/solicitacoes/detalhes-solicitacao/detalhes-interacao/detalhes-interacao.component';

import { DatePipe } from '@angular/common';

const appRoutes: Routes = [
  // Módulos
  { path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  { path: '', loadChildren: './components/login/login.module#LoginModule' },
  { path: 'comissao', canActivate: [AuthGuard], loadChildren: './components/comissao/comissao-data/comissao-data.module#ComissaoDataModule' },
  { path: 'comissao/config', canActivate: [AuthGuard], loadChildren: './components/comissao/comissao-config/comissao-config.module#ComissaoConfigModule' },
  { path: 'dashboard', canActivate: [AuthGuard], loadChildren: './components/dashboard/dashboard.module#DashboardModule' },
  { path: 'home', canActivate: [AuthGuard], loadChildren: './components/home/home.module#HomeModule' },
  { path: 'estoque/config', canActivate: [AuthGuard], loadChildren: './components/estoque/estoque-config/estoque-config.module#EstoqueConfigModule' },
  { path: 'faturamento', canActivate: [AuthGuard], loadChildren: './components/faturamento/faturamento-data/faturamento-data.module#FaturamentoDataModule' },
  { path: 'faturamento/config', canActivate: [AuthGuard], loadChildren: './components/faturamento/faturamento-config/faturamento-config.module#FaturamentoConfigModule' },
  { path: 'laboratorio', canActivate: [AuthGuard], loadChildren: './components/laboratorio/laboratorio-data/laboratorio-data.module#LaboratorioDataModule' },
  { path: 'laboratorio/config', canActivate: [AuthGuard], loadChildren: './components/laboratorio/laboratorio-config/laboratorio-config.module#LaboratorioConfigModule' },
  { path: 'laudo-resultado', canActivate: [AuthGuard], loadChildren: './components/laudo-resultado/laudo-resultado.module#LaudoResultadoModule' },
  { path: 'pessoas', canActivate: [AuthGuard], loadChildren: './components/pessoas/pessoas-data/pessoas-data.module#PessoasDataModule' },
  { path: 'pessoas/config', canActivate: [AuthGuard], loadChildren: './components/pessoas/pessoas-config/pessoas-config.module#PessoasConfigModule' },
  { path: 'recebimento', canActivate: [AuthGuard], loadChildren: './components/recebimento/recebimento.module#RecebimentoModule' },
  { path: 'amostra', canActivate: [AuthGuard], loadChildren: './components/recebimento/recebimento.module#RecebimentoModule' },
  { path: 'relatorio', canActivate: [AuthGuard], loadChildren: './components/relatorio/relatorio-data/relatorio-data.module#RelatorioDataModule' },
  { path: 'relatorio/config', canActivate: [AuthGuard], loadChildren: './components/relatorio/relatorio-config/relatorio-config.module#RelatorioConfigModule' },
  { path: 'repasse', canActivate: [AuthGuard], loadChildren: './components/repasse/repasse-data/repasse-data.module#RepasseDataModule' },
  { path: 'repasse/config', canActivate: [AuthGuard], loadChildren: './components/repasse/repasse-config/repasse-config.module#RepasseConfigModule' },
  { path: 'unsupported', loadChildren: './components/unsupported-browser/unsupported-browser.module#UnsupportedBrowserModule'},

  // Components
  { path: 'help-service', canActivate: [AuthGuard], component: HelpServiceComponent },
  { path: 'help-service/tipo-solicitacao', canActivate: [AuthGuard], component: TipoSolicitacaoComponent },
  { path: 'help-service/origem-solicitacao', canActivate: [AuthGuard], component: OrigemSolicitacaoComponent },
  { path: 'help-service/status-solicitacao', canActivate: [AuthGuard], component: StatusSolicitacaoComponent },
  { path: 'help-service/grupo-atendimento', canActivate: [AuthGuard], component: GrupoAtendimentoComponent },
  { path: 'help-service/workflow', canActivate: [AuthGuard], component: WorkflowComponent },
  {path: '404', canActivate: [AuthGuard], component: ErroComponent},
  {path: '**', canActivate: [AuthGuard], redirectTo: '/404'},
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavComponent,
    SidenavComponent,
    BuscaComponent,
    HelpServiceComponent,
    WorkflowComponent,
    SolicitacoesComponent,
    GerenciarWorkflowComponent,
    TipoSolicitacaoComponent,
    StatusTipoSolicitacaoComponent,
    NovoTipoSolicitacaoComponent,
    DetalhaTipoSolicitacaoComponent,
    DeletarTipoSolicitacaoComponent,
    OrigemSolicitacaoComponent,
    StatusOrigemSolicitacaoComponent,
    NovoOrigemSolicitacaoComponent,
    DetalhaOrigemSolicitacaoComponent,
    DeletarOrigemSolicitacaoComponent,
    StatusSolicitacaoComponent,
    CancelaStatusSolicitacaoComponent,
    NovoStatusSolicitacaoComponent,
    DetalhaStatusSolicitacaoComponent,
    DeletarStatusSolicitacaoComponent,
    DetatlheWorkflowComponent,
    StatusWorkflowComponent,
    NovaEtapaWorkflowComponent,
    DetalheEtapaWorkflowComponent,
    GrupoAtendimentoComponent,
    StatusGrupoAtendimentoComponent,
    NovoGrupoAtendimentoComponent,
    DetalhaGrupoAtendimentoComponent,
    DeletarGrupoAtendimentoComponent,
    NovoGrupoAtendimentoUsuarioComponent,
    ErroComponent,
    NovaSolicitacaoComponent,
    DetalhesSolicitacaoComponent,
    DeletarWorkflowComponent,
    SolicitacaoInteracaoNovoDocumentoComponent,
    DetalhesInteracaoComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      appRoutes,
      // { enableTracing: true } // <-- debugging purposes only
    ),
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    EnumService,
    CalendarioService,
    Utils,
    TipoSolicitacaoService,
    DetalhaTipoSolicitacaoService,
    DeletarTipoSolicitacaoService,
    NovoTipoSolicitacaoService,
    StatusTipoSolicitacaoService,
    OrigemSolicitacaoService,
    DetalhaOrigemSolicitacaoService,
    DeletarOrigemSolicitacaoService,
    NovoOrigemSolicitacaoService,
    StatusOrigemSolicitacaoService,
    StatusSolicitacaoService,
    DeletarStatusSolicitacaoService,
    DetalhaStatusSolicitacaoService,
    CancelaStatusSolicitacaoService,
    NovoStatusSolicitacaoService,
    WorkflowService,
    DetalheWorkflowService,
    StatusWorkflowService,
    GerenciaWorkflowService,
    GrupoAtendimentoService,
    DetalhaGrupoAtendimentoService,
    DeletarGrupoAtendimentoService,
    NovoGrupoAtendimentoService,
    StatusGrupoAtendimentoService,
    NovoGrupoAtendimentoUsuarioService,
    SolicitacoesService,
    DeletarWorkflowService,
    NovaSolicitacaoService,
    DetalhesSolicitacaoService,
    JwtHelper,
    AuthGuard,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
