import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../../base/util/utils';

// Service
import { StatusTipoSolicitacaoService } from './status-tipo-solicitacao.service';

// VO
import { TipoSolicitacaoVO } from '../../../../base/vo/tipo-solicitacao';

// PrimeNg
import { Message } from 'primeng/components/common/api';

declare var M: any;

@Component({
  selector: 'app-status-tipo-solicitacao',
  templateUrl: './status-tipo-solicitacao.component.html',
  styleUrls: ['./status-tipo-solicitacao.component.css']
})
export class StatusTipoSolicitacaoComponent implements OnInit {

  @Input() refazerBusca: any;
  @Output() respostaCriaAtualiza = new EventEmitter;
  @Output() MessageDialog = new EventEmitter();

  public tipoSolicitacao: TipoSolicitacaoVO;

  public isLoading: boolean;
  public displayModalStatusTipoSolicitacao: boolean;

  public idItem = '';
  public msgs: Message[] = [];

  constructor(private statusTipoSolicitacaoService: StatusTipoSolicitacaoService,
              public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
    this.tipoSolicitacao = new TipoSolicitacaoVO();
  }

  inativarItem() {
    this.tipoSolicitacao.status = 1;
    this.isLoading = true;
    this.statusTipoSolicitacaoService.inativaTipoSolicitacao(this.tipoSolicitacao).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogStatusItem();
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(response.message);
        }
        this.isLoading = false;
      },
      error => {
        this.msgs = this.serviceUtils.showError('Erro ao tentar inativar');
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  ativarItem() {
    this.tipoSolicitacao.status = 2;
    this.isLoading = true;
    this.statusTipoSolicitacaoService.reativaTipoSolicitacao(this.tipoSolicitacao).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogStatusItem();
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(response.message);
        }
        this.isLoading = false;
      },
      error => {
        this.msgs = this.serviceUtils.showError('Erro ao tentar ativar');
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  recuperarTipoSolicitacao(idItem: string) {
    this.isLoading = true;
    this.statusTipoSolicitacaoService.recuperarTipoSolicitacao(idItem).subscribe(
      data => {
        this.tipoSolicitacao = data;
        this.isLoading = false;
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      });
  }

  showDialogStatusItem(idItem) {
    this.idItem = idItem;
    this.recuperarTipoSolicitacao(idItem);
    this.displayModalStatusTipoSolicitacao = true;
  }

  hideDialogStatusItem() {
    this.idItem = '';
    this.displayModalStatusTipoSolicitacao = false;
    this.isLoading = false;
    this.respostaCriaAtualiza.emit(1);
  }
}
