import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../../../base/util/utils';

// VO
import { StatusSolicitacaoVO } from '../../../../base/vo/status-solicitacao';

// Service
import { CancelaStatusSolicitacaoService } from './cancela-status-solicitacao.service';

// PrimeNg
import { Message } from 'primeng/components/common/api';

declare var M: any;

@Component({
  selector: 'app-cancela-status-solicitacao',
  templateUrl: './cancela-status-solicitacao.component.html',
  styleUrls: ['./cancela-status-solicitacao.component.css']
})
export class CancelaStatusSolicitacaoComponent implements OnInit {

  @Input() refazerBusca: any;
  @Output() respostaCriaAtualiza = new EventEmitter;
  @Output() MessageDialog = new EventEmitter();

  public statusSolicitacao: StatusSolicitacaoVO;

  public isLoading: boolean;
  public displayModalCancelarStatusSolicitacao: boolean;

  public idItem = '';
  public msgs: Message[] = [];

  constructor(private cancelarStatusSolicitacaoService: CancelaStatusSolicitacaoService,
              public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
    this.statusSolicitacao = new StatusSolicitacaoVO();
  }

  inativarItem() {
    this.statusSolicitacao.status = 1;
    this.isLoading = true;
    this.cancelarStatusSolicitacaoService.inativaStatusSolicitacao(this.statusSolicitacao).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogStatusItem();
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(response.message);
        }
        this.isLoading = false;
      },
      error => {
        this.msgs = this.serviceUtils.showError('Erro ao tentar inativar');
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  ativarItem() {
    this.statusSolicitacao.status = 2;
    this.isLoading = true;
    this.cancelarStatusSolicitacaoService.reativaStatusSolicitacao(this.statusSolicitacao).subscribe(
      response => {
        if (response.success && !isNullOrUndefined(response.data)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogStatusItem();
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(response.message);
        }
        this.isLoading = false;
      },
      error => {
        this.msgs = this.serviceUtils.showError('Erro ao tentar ativar');
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  recuperarStatusSolicitacao(idItem: string) {
    this.isLoading = true;
    this.cancelarStatusSolicitacaoService.recuperarStatusSolicitacao(idItem).subscribe(
      data => {
        this.statusSolicitacao = data;
        this.isLoading = false;
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      });
  }

  showDialogStatusItem(idItem) {
    this.idItem = idItem;
    this.recuperarStatusSolicitacao(idItem);
    this.displayModalCancelarStatusSolicitacao = true;
  }

  hideDialogStatusItem() {
    this.idItem = '';
    this.displayModalCancelarStatusSolicitacao = false;
    this.isLoading = false;
    this.respostaCriaAtualiza.emit(1);
  }
}
