import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DeletarWorkflowService } from './deletar-workflow.service';
import { Utils } from '../../../../base/util/utils';
import { isNullOrUndefined } from 'util';
import { Message } from 'primeng/components/common/api';

declare var M: any;
declare var $: any;


@Component({
  selector: 'app-deletar-workflow',
  templateUrl: './deletar-workflow.component.html',
  styleUrls: ['./deletar-workflow.component.css']
})
export class DeletarWorkflowComponent implements OnInit {

  @Output() RecarrePagina = new EventEmitter;

  public isLoading: boolean;
  public Workflow: any;

  public displayModalDeletaWorkflow: boolean;

  public idItem: string = "";
  public msgs: Message[] = [];

  constructor(public deletarWorkflowService: DeletarWorkflowService, public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
  }

  deletarWorkflow() {
    let resposta: any;
    this.isLoading = true;
    this.deletarWorkflowService.deletarWorkflow(this.idItem).subscribe(
      response => {
        resposta = response;
        if (resposta.success && !isNullOrUndefined(resposta)) {
          this.msgs = this.serviceUtils.showSuccess();
          this.hideDialogDeletaWorkflow();
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.msgs = this.serviceUtils.showError(resposta.message);
        }
      },
      erro => {
        this.msgs = this.serviceUtils.showError('');
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  recuperarWorkflow(idItem: string) {
    this.isLoading = true;
    this.deletarWorkflowService.recuperarWorkflow(idItem).subscribe(
      data => {
        this.Workflow = data;
        this.isLoading = false;
        setTimeout(() => {
          $('select').formSelect();
        }, 1);
      },
      erro => {
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  showDialogDeletaWorkflow(idItem) {
    this.idItem = idItem;
    this.recuperarWorkflow(idItem);
    this.displayModalDeletaWorkflow = true;
  }

  hideDialogDeletaWorkflow() {
    this.idItem = "";
    this.displayModalDeletaWorkflow = false;
    this.RecarrePagina.emit();
  }

}
