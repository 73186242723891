import { Component, OnInit, ViewChild } from '@angular/core';
import { SolicitacoesService } from './solicitacoes.service';
import { isNullOrUndefined } from 'util';
import { formatDate } from "@angular/common";
import { Message } from 'primeng/components/common/message';

// Services
import { Utils } from '../../../base/util/utils';

// Components
import { PesquisaPessoasComponent } from '../../pessoas/pessoas-data/pesquisa-pessoas/pesquisa-pessoas.component';

// VOs
import { SolicitacaoGridListVO } from '../../../base/vo/Solicitacoes/solicitacoes-grid-list';

declare var M: any;
declare var $: any;

interface FiltrosSolicitacoes {
  periodoIni: string;
  periodoFim: string;
  protocolo: string;
  nomeSolicitante: string;
  idDomOrigemSolicitacao: number;
  idDomTipoSolicitacao: number;
  idWorkflow: number;
  idDomSolicitacaoWorkflowEtapa: number;
  idDomStatusSolicitacao: number;
}

@Component({
  selector: 'app-solicitacoes',
  templateUrl: './solicitacoes.component.html',
  styleUrls: ['./solicitacoes.component.css']
})
export class SolicitacoesComponent implements OnInit {
  // Mensagens de retorno
  public msgs: Message[] = [];

  @ViewChild('pesquisaPessoa') public pesquisaPessoa: PesquisaPessoasComponent;
  public displayModalCancelarSolicitacao: boolean;
  public esconderPesquisaSolicitacao: boolean;
  public exibirDetalhesSolicitacao: boolean;
  public exibirNovaSolicitacao: boolean;
  public spinnerLoading: boolean;
  public spinnerCancelamentoEmProcesso: boolean;
  public exibirListaDeEtapa: boolean = true;
  public realizarBuscaAutomatica: boolean;

  public solicitacaoDetalhes: boolean;
  public novaSolicitacao: boolean;
  public idSolicitacao: number;

  //#region Paginação
  public numberOfRows: number = 10;
  public numberOfRecords: number = 0;
  public pageNumber: number = 0;
  //#endregion

  //#region variaveis form de busca solicitação
  public protocolo: string;

  // Solicitante
  public idSolicitante: number;
  public nomeDoSolicitante: string;
  public pesquisaNomeSolicitanteRealizada: boolean;

  public periodoInicio: Date;
  public isPeriodoInicioValido: boolean = true;
  public periodoFim: Date;
  public isPeriodoFimValido: boolean = true;
  public idOrigemSolicitacao: number;
  public idTipoSolicitacao: number;
  public idWorkflowSolicitacao: number;
  public idEtapaAtual: number;
  public idStatusSolicitacao: number;

  public idProtocoloReferencia: number;
  public descricaoProtocoloReferencia: string;
  public pesquisaProtocoloReferenciaRealizada: boolean;
  //#endregion

  //#region variaveis de controle de resultado
  public isResultFound: boolean;
  public isResultNotFound: boolean;
  public isLoading: boolean;
  //#endregion

  //#region dropdowns
  public listaDeOrigemDaSolicitacao: any;
  public listaDeTipoDaSolicitacao: any;
  public listaDeWorkflowDaSolicitacao: any;
  public listaDeEtapaDaSolicitacao: any;
  public listaDeStatusDaSolicitacao: any;
  //#endregion
  public listaDeSolicitacoes: Array<SolicitacaoGridListVO>;
  public solicitacaoFiltros: FiltrosSolicitacoes;
  public protocoloModalCancelamento: string;
  private idSolicitacaoParaCancelar: number;

  readonly STATUS_SOLICITACAO_CODIGO_NOVA = "001";
  readonly STATUS_SOLICITACAO_CODIGO_CANCELADA = "005";
  readonly STATUS_SOLICITACAO_CODIGO_FINALIZADA = "004";

  constructor(private solicitacoesService: SolicitacoesService, public utils: Utils) { }

  ngOnInit() {
    this.spinnerLoading = true;
    this.idSolicitacao = 0;
    this.iniciaDataValorPadrao();
    this.carregaDropdowns();
    this.carregaJQuery();
  }

  public buscar() {
    if (this.validaDados()) {
      this.realizarBuscaAutomatica = true;
      this.recuperaSolicitacoes(1);
    }
  }

  public exibirDetalhesDaSolicitacao(idSolicitacao: number) {
    this.idSolicitacao = idSolicitacao;
    this.exibirDetalhesSolicitacao = true;
    this.controlaExibicao();
  }

  public exibirNovaSolicitacaoFormulario(idSolicitacao?: number) {
    this.idSolicitacao = idSolicitacao;
    this.exibirNovaSolicitacao = true;
    this.controlaExibicao();
  }

  public exibirModalCancelamento(solicitacao: any) {
    this.displayModalCancelarSolicitacao = true;
    this.protocoloModalCancelamento = solicitacao.protocolo;
    this.idSolicitacaoParaCancelar = solicitacao.idSolicitacao;
  }

  public showDialogPesquisaSolicitante() {
    this.pesquisaPessoa.showDialogPesquisaPessoa();
  }

  public paginate(event: any) {
    this.recuperaSolicitacoes(event.page + 1);
    this.pageNumber = event.first;
  }

  public setDadosPessoa(event: any) {
    this.nomeDoSolicitante = event.nome;
    this.pesquisaNomeSolicitanteRealizada = true;
  }

  public limparPlaceHolderPesquisaSolicitante() {
    this.nomeDoSolicitante = '';
    this.pesquisaNomeSolicitanteRealizada = false;
  }

  public fechaDetalhesSolicitacao() {
    this.exibirDetalhesSolicitacao = false;
    this.controlaExibicao();
  }

  public fechaNovaSolicitacao(event: any) {
    if (event === true) {
      this.msgs = this.utils.showSuccess();
    }
    this.exibirNovaSolicitacao = false;
    this.controlaExibicao();
    this.refazBusca();
  }

  public carregaListaDeEtapasDoWorkflow(idWorkflowSolicitacao: number) {
    if (idWorkflowSolicitacao != 0) {
      this.recuperaListaDeEtapasPorWorkflow(idWorkflowSolicitacao);
    } else {
      this.exibirListaDeEtapa = true;
      this.idEtapaAtual = null;
      this.utils.carregaJQuery();
    }
  }

  public hideModalCancelamento() {
    this.displayModalCancelarSolicitacao = false;
    this.idProtocoloReferencia = null;
    this.protocoloModalCancelamento = '';
  }

  public cancelarSolicitacao() {
    this.spinnerCancelamentoEmProcesso = true;
    this.solicitacoesService.cancelarSolicitacaio(this.idSolicitacaoParaCancelar).subscribe(
      resultado => {
        if (resultado.success) {
          this.spinnerCancelamentoEmProcesso = false;
          this.msgs = this.utils.showSuccess();
          this.hideModalCancelamento();
          this.refazBusca();
        } else {
          this.spinnerCancelamentoEmProcesso = false;
          this.msgs = this.utils.showError(resultado.message);
        }
      },
      error => {
        this.spinnerCancelamentoEmProcesso = false;
        console.log(error);
      }
    );
  }

  public desabilitaAcaoParaSolicitacaoFinalizadaECancelada(codigoStatus: string) {
    if (this.STATUS_SOLICITACAO_CODIGO_FINALIZADA === codigoStatus || this.STATUS_SOLICITACAO_CODIGO_CANCELADA === codigoStatus) {
      return true
    } else {
      return false;
    }
  }

  private validaDados(): boolean {
    const isValido = new Array<any>();

    isValido.push(this.isPeriodoInicioValido = !isNullOrUndefined(this.periodoInicio));
    isValido.push(this.isPeriodoFimValido = !isNullOrUndefined(this.periodoFim));
    if (this.periodoInicio > this.periodoFim) {
      isValido.push(this.isPeriodoInicioValido = false);
      isValido.push(this.isPeriodoFimValido = false);
      this.msgs = this.utils.showError("Período final não pode ser menor que o inicial");
    }
    return !isValido.some(function isAnyFalse(element) { return (element === false); });
  }

  private populaFiltroDeBusca() {
    this.solicitacaoFiltros = {} as FiltrosSolicitacoes;

    this.solicitacaoFiltros.periodoIni = this.periodoInicio.toLocaleDateString();
    this.solicitacaoFiltros.periodoFim = this.periodoFim.toLocaleDateString();
    this.solicitacaoFiltros.nomeSolicitante = this.nomeDoSolicitante;
    this.solicitacaoFiltros.protocolo = this.protocolo;
    this.solicitacaoFiltros.idWorkflow = this.idWorkflowSolicitacao;
    this.solicitacaoFiltros.idDomOrigemSolicitacao = this.idOrigemSolicitacao;
    this.solicitacaoFiltros.idDomTipoSolicitacao = this.idTipoSolicitacao;
    this.solicitacaoFiltros.idDomStatusSolicitacao = this.idStatusSolicitacao;
    this.solicitacaoFiltros.idDomSolicitacaoWorkflowEtapa = this.idEtapaAtual;
  }

  private resultNotFound() {
    this.isLoading = false;
    this.isResultFound = false;
    this.isResultNotFound = true;
  }

  private resultFound() {
    this.isLoading = false;
    this.isResultFound = true;
    this.isResultNotFound = false;
  }

  private carregaJQuery() {
    setTimeout(function () {
      M.AutoInit();
      $('.dropdown-trigger').dropdown({ "coverTrigger": false });
      $('select').formSelect();
    }, 1);
  }

  private recuperaSolicitacoes(page: number) {
    if (page <= 10) {
      this.pageNumber = 1;
    }
    this.isLoading = true;
    this.populaFiltroDeBusca();
    this.solicitacoesService.recuperaSolicitacoes(page, 10, this.solicitacaoFiltros).subscribe(
      resultado => {
        this.listaDeSolicitacoes = Object.assign(new Array<SolicitacaoGridListVO>(), resultado);
        if (!isNullOrUndefined(this.listaDeSolicitacoes)) {
          if (this.listaDeSolicitacoes.length > 0) {
            this.numberOfRecords = this.listaDeSolicitacoes[0].numberOfRows;
            this.listaDeSolicitacoes.map(solicitacao => {
              solicitacao.dataUltimaInteracao = formatDate(solicitacao.dataUltimaInteracao, 'dd/MM/yyyy', 'en-US');
            });
            this.resultFound();
          } else {
            this.resultNotFound();
          }
        }
      },
      error => {
        this.resultNotFound();
        console.log(error);
      }
    );
  }

  private iniciaDataValorPadrao() {
    const dataIni = new Date();
    const dataFim = new Date();
    dataIni.setDate(dataIni.getDate() - 46);
    dataFim.setDate(dataFim.getDate());
    this.periodoInicio = dataIni;
    this.periodoFim = dataFim;
  }

  private carregaDropdowns() {
    this.recuperaOrigensDaSolicitacao();
    this.recuperaTipoDaSolicitacao();
    this.recuperaListaDeWorkflows();
    this.recuperaListaDeStatusDaSolicitacao();
  }

  private recuperaOrigensDaSolicitacao() {
    this.solicitacoesService.recuperaOrigensDaSolicitacao().subscribe(
      resultado => {
        this.listaDeOrigemDaSolicitacao = resultado;
        this.idOrigemSolicitacao = 0;
        this.carregaJQuery();
      },
      error => {
        console.log(error);
      }
    );
  }

  private recuperaTipoDaSolicitacao() {
    this.solicitacoesService.recuperaTipoDaSolicitacao().subscribe(
      resultado => {
        this.listaDeTipoDaSolicitacao = resultado;
        this.idTipoSolicitacao = 0;
        this.carregaJQuery();
      },
      error => {
        console.log(error);
      }
    );
  }

  private recuperaListaDeWorkflows() {
    this.solicitacoesService.recuperaListaDeWorkflows().subscribe(
      resultado => {
        this.listaDeWorkflowDaSolicitacao = resultado;
        this.idWorkflowSolicitacao = 0;
        this.carregaJQuery();
        this.spinnerLoading = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  private recuperaListaDeEtapasPorWorkflow(idSolicitacaoWorkflow: number) {
    this.solicitacoesService.recuperaListaDeEtapas(idSolicitacaoWorkflow).subscribe(
      resultado => {
        this.listaDeEtapaDaSolicitacao = resultado;
        if (!isNullOrUndefined(this.listaDeEtapaDaSolicitacao)) {
          this.exibirListaDeEtapa = false;
          this.idEtapaAtual = 0;
        }
        this.carregaJQuery();
      },
      error => {
        console.log(error);
      }
    );
  }

  private recuperaListaDeStatusDaSolicitacao() {
    this.solicitacoesService.recuperaListaDeStatusDaSolicitacao().subscribe(
      resultado => {
        this.listaDeStatusDaSolicitacao = resultado;
        this.idStatusSolicitacao = 0;
        this.carregaJQuery();
      },
      error => {
        console.log(error);
      }
    );
  }

  private controlaExibicao() {
    if (this.exibirDetalhesSolicitacao || this.exibirNovaSolicitacao) {
      this.esconderPesquisaSolicitacao = true;
    } else {
      this.esconderPesquisaSolicitacao = false;
    }
  }

  private refazBusca() {
    if (this.realizarBuscaAutomatica) {
      this.recuperaSolicitacoes(1);
    }
  }
}
