import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../../../../base/util/base.service';
import { environment } from './../../../../../../environments/environment';
import { isNullOrUndefined } from 'util';

@Injectable()
export class DetalhaEnderecoPessoaService extends BaseService{

  constructor(public http: HttpClient) { super(http) }  

  recuperarEnderecoPessoa(id:string){
      let urlDestino = environment.baseUrl + "EnderecoPessoaWS";
      let idItem:string;
      if(!isNullOrUndefined(id)){
          idItem = "/" + id;
          urlDestino += idItem;
      }
      return this.get(urlDestino);
  }
}
