import { Utils } from './../../../../base/util/utils';
import { Message } from 'primeng/components/common/message';
import { PadraoEnderecoPessoaComponent } from './padrao-endereco-pessoa/padrao-endereco-pessoa.component';
import { DetalhaEnderecoPessoaComponent } from './detalha-endereco-pessoa/detalha-endereco-pessoa.component';
import { NovoEnderecoPessoaComponent } from './novo-endereco-pessoa/novo-endereco-pessoa.component';
import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { EnderecoPessoaVO } from './../../../../base/vo/endereco-pessoa';
import { DeletarEnderecoPessoaComponent } from './deletar-endereco-pessoa/deletar-endereco-pessoa.component';
import { isNullOrUndefined } from 'util';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-endereco-pessoa',
  templateUrl: './endereco-pessoa.component.html',
  styleUrls: ['./endereco-pessoa.component.css']
})
export class EnderecoPessoaComponent implements OnInit {

  @Output() respostaEnderecoCadastradoParte2 = new EventEmitter;

  @Output() respostaEnderecoAtualizadoParte2 = new EventEmitter;

  @ViewChild('novoEnderecoPessoa')
  public novoEnderecoPessoa: NovoEnderecoPessoaComponent;

  @ViewChild('novoEnderecoPessoa')
  public enderecoPessoaComponent: EnderecoPessoaComponent;

  @ViewChild('detalhaEnderecoPessoa')
  public detalhaEnderecoPessoa: DetalhaEnderecoPessoaComponent;

  @ViewChild('deletaEnderecoPessoa')
  public deletaEnderecoPessoa: DeletarEnderecoPessoaComponent;

  @ViewChild('padraoEnderecoPessoa')
  public padraoEnderecoPessoa: PadraoEnderecoPessoaComponent;

  idItem: any;
  editEndereco = true;
  teste: any;
  public visibleForm = false;
  public index = 0;
  public qntSplice = 1;
  public indexEndereco = 0;
  public detalha = false;
  listEnderecos = new Array<EnderecoPessoaVO>();
  msgs: Message[] = [];

  constructor(public serviceUtils: Utils) { }

  ngOnInit() {
    M.AutoInit();
    if (!this.idItem) {
    }
  }

  showForm() {
    this.visibleForm = true;

    setTimeout(function () {
      $('html, body').animate({
      }, 1000);
    }, 1);

  }

  showDialogNovoEnderecoPessoa(idItem) {
    this.teste = idItem;
    let x: any;
    x = Object.assign({}, idItem);
    if (!idItem) {
      this.novoEnderecoPessoa.showDialogNovoEnderecoPessoa(idItem);
    } if (idItem) {
      this.index = this.listEnderecos.indexOf(idItem);
      this.novoEnderecoPessoa.showDialogNovoEnderecoPessoa(x);
    }

  }

  showDialogDetalhaEnderecoPessoa(idItem) {
    this.detalhaEnderecoPessoa.showDialogDetalhaEnderecoPessoa(idItem);
  }

  showDialogDeletaEnderecoPessoa(Item) {
    if (this.editEndereco) {
      this.deletaEnderecoPessoa.showDialogDeletaEnderecoPessoa(Item);
      this.deletaEnderecoPessoa.editEndereco = true;
    } else {
      this.deletaEnderecoPessoa.editEndereco = false;
      this.indexEndereco = Item.idIndex;
      this.deletaEnderecoPessoa.showDialogDeletaEnderecoPessoa(Item);
    }
  }

  populaArrayEnderecos(respostaEvento) {
    const podeCadastrar = this.verificaTiposDeEnderecos(respostaEvento);
    if (isNullOrUndefined(this.listEnderecos)) {
      this.listEnderecos = new Array<EnderecoPessoaVO>();
    }
    if (podeCadastrar === 1) {
      this.listEnderecos.push(respostaEvento);
      this.respostaEnderecoCadastradoParte2.emit(respostaEvento);
    }
  }

  deletaEndereco(respostaEvento) {
    this.index = this.listEnderecos.indexOf(respostaEvento, 0);
    this.listEnderecos.splice(this.index, 1);
    this.enderecoPessoaComponent.listEnderecos = this.listEnderecos;
  }

  atualizaArrayEnderecos(respostaEvento) {
    const podeCadastrar = this.verificaTiposDeEnderecos(respostaEvento);
    if (podeCadastrar == 1) {
      this.listEnderecos[this.index] = respostaEvento;
      this.respostaEnderecoAtualizadoParte2.emit(this.listEnderecos);
    } else {
      this.listEnderecos[this.index] = this.teste;
    }
  }

  public populaDetalha(detalheCliente) {
    this.listEnderecos = new Array<EnderecoPessoaVO>();
    this.detalha = true;
    this.listEnderecos = detalheCliente.enderecos;
  }

  showDialogPadraoEnderecoPessoa(item) {
    this.padraoEnderecoPessoa.showDialogPadraoEnderecoPessoa(item);
  }

  tornarPadraoEndereco(event) {
    this.index = this.listEnderecos.indexOf(event, 0);
    let i = 0;
    for (i = 0; i < this.listEnderecos.length; i++) {
      if (i !== this.index) {
        this.listEnderecos[i].padrao = 2;
        this.listEnderecos[i].descricaoPadrao = "Não";
      } else {
        this.listEnderecos[this.index].padrao = 1;
        this.listEnderecos[this.index].descricaoPadrao = "Sim";
      }
    }
    this.respostaEnderecoAtualizadoParte2.emit(this.listEnderecos);
  }

  verificaTiposDeEnderecos(endereco) {
    let i: number;
    if (!isNullOrUndefined(this.listEnderecos)) {
      for (i = 0; i < this.listEnderecos.length; i++) {
        if ( this.listEnderecos[i].cep == endereco.cep 
          && this.listEnderecos[i].logradouro.toUpperCase() == endereco.logradouro.toUpperCase() 
          && this.listEnderecos[i].numero == endereco.numero 
          && this.listEnderecos[i].idTipoEndereco == endereco.idTipoEndereco 
          && this.listEnderecos[i].bairro.toUpperCase() == endereco.bairro.toUpperCase() 
          && this.listEnderecos[i].id != endereco.id ) {
          this.msgs = this.serviceUtils.showError("Endereço já cadastrado");
          return 0;
        }
      }
    }
    return 1;
  }
}
