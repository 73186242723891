import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../base/util/base.service';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';

@Injectable()
export class CancelaStatusSolicitacaoService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  recuperarStatusSolicitacao(id: string) {
    let urlDestino = environment.baseUrl + 'status-solicitacao';
    let idItem: string;
    if (!isNullOrUndefined(id)) {
      idItem = '/' + id;
      urlDestino += idItem;
    }
    return this.get(urlDestino);
  }

  reativaStatusSolicitacao(statusSolicitacao) {
    const urlDestino = environment.baseUrl + 'status-solicitacao/reactive';
    return this.put(urlDestino, statusSolicitacao);
  }

  inativaStatusSolicitacao(statusSolicitacao) {
    const urlDestino = environment.baseUrl + 'status-solicitacao/cancel';
    return this.put(urlDestino, statusSolicitacao);
  }

}
