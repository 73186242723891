import { Pipe, PipeTransform } from "@angular/core";
import { isNullOrUndefined } from 'util';

@Pipe({
  name: 'padLeft'
})
export class PadLeftPipe implements PipeTransform {
  transform(val, size) {
    if (size === undefined) {
      return val;
    }
    if (!isNullOrUndefined(val)) {
      let s = val + '';
      while (s.length < size) { s = '0' + s; }
      return s;
    }
  }
}