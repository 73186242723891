import { Pipe } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe {
  transform(val, args) {
    if (args === undefined) {
      return val;
    }
    if (!isNullOrUndefined(val)) {
      if (val.length > args) {
        return val.substring(0, args) + '...';
      } else {
        return val;
      }
    }
  }
}