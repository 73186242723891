export class PessoaVO{
    constructor(
        public id?:number,
        public nome?:string,
        public documento?:string,

        public tipoDocumento?:number,
        public tipoPessoa?:number,

        public nomeFantasia?:string,
        public inscricaoMunicipal?:string,
        public inscricaoEstadual?:string,
        public emailFiscal?:string

    ){}
}