import { Injectable } from '@angular/core';
import { Message } from 'primeng/components/common/api';
import { isNullOrUndefined } from 'util';

interface ResultMensage {
    field: string;
    valid: boolean;
    msg: string;
}

declare var $: any;
declare var M: any;

@Injectable()
export class Globals {

  messageTimeLife = "10000";

}


@Injectable()
export class Utils {

    public globals: Globals = new Globals();

    /**
     *
     * @param mes Tipo (number) referente ao mês de competência não pode ser maior que 12 ou menor que 1.
     * @param ano Tipo (number) referente ao mês e ano de competência.
     * @return Retorna uma string no formato mm/yyyy.
     */
    formatarCompetencia(mes: number, ano: number): string {
        if (isNullOrUndefined(mes) || isNullOrUndefined(ano)) {
            return "Mês e/ou Ano não informado";
        } else {
            if (mes < 1 || mes > 12) {
                return "Mês informado inválido"
            } else {
                return ((mes < 10) ? "0" + mes : mes) + "/" + ano;
            }
        }
    }

    /**
     * @param str String para ser validada.
     * @param tr boolean para ser validada se a string é composta apenas de caractere 'espaço'.
     * @return Retorna um boolean indicando true se a string for vazia ou nula.
     */
    isStringNullOrBlank(str: string, tr?: boolean): boolean {
        if (isNullOrUndefined(str)) {
            return true; // Null ou Undefined
        } else {
            if (str.length <= 0) {
                return true; // Blank/Vazia
            } else if (tr && !str.trim()) {
                return true; // composta apenas por 'espaço'
            } else {
                return false;
            }
        }
    }

    /**
     * @param str String contendo a competência no formato Exemplo: 122018, sendo os dois
     * @param maxYear Tipo number, parametro opcional usado para validar o ano máximo caso seja informado
     * @param minYear Tipo number, parametro opcional usado para validar o ano minimo caso seja informado
     * primeiros caracteres destinados ao mês e os quatro utlimos destinados ao ano.
     * @return Retorna true para uma competência que seja inválida e false para false para
     * uma competência valida.
     */
    isCompetenciaInvalid(str: string, maxYear?: number, minYear?: number): boolean {
        if (isNullOrUndefined(str)) {
            return true;
        } else {
            if (str.length != 6) {
                return true;
            }
            if (parseInt(str.slice(0, 2)) > 12 || parseInt(str.slice(0, 2)) < 1) {
                return true;
            }
            if (!isNullOrUndefined(maxYear) && parseInt(str.slice(2)) > maxYear) {
                return true;
            }
            if (!isNullOrUndefined(minYear) && parseInt(str.slice(2)) < minYear) {
                return true;
            }
        }
        return false;
    }


    /**
     * @param str String com o valor a ser comparado.
     * @param length Number com o valor de comparação maximo.
     * @return Retorna true para uma string maior que o valor informado
     * e false para uma string menor. Caso a string informada seja nula ou undefined
     * retornar false.
     */
    isStringBig(str: string, length: number): boolean {
        if (isNullOrUndefined(str)) {
            return false;
        } else {
            if (str.length > length) {
                return true;
            }
        }
        return false;
    }

    /**
     *
     * @param rangeDate Variavel do tipo Date[]
     * @return Retorna true caso o array esteja null ou undefined, ou caso uma
     * das datas contidas no array esteja null ou undefined. Retorna false quando
     * as datas estiverem preenchidas
     */
    isDateRangeInvalidOrNull(rangeDate: Date[]): boolean {
        if (isNullOrUndefined(rangeDate)) {
            return true;
        } else {
            for (const date of rangeDate) {
                if (isNullOrUndefined(date)) {
                    return true;
                }
            }
        }
        return false;
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }


    msgs: Message[] = [];

    showSuccess() {
        this.msgs = [];
        this.msgs.push({
            severity: 'success',
            summary: 'Sucesso',
            // detail: detalhes
        });
        return this.msgs;
    }

    /**
     *
     * @param detalhes Variavel do tipo string
     * retorna a mensagem de erro com ou sem mensagem adicional do back
     */
    showError(detalhes: string) {
        this.msgs = [];
        this.msgs.push({
            severity: 'error',
            summary: 'Erro',
            detail: detalhes
        });
        return this.msgs;
    }

     /**
     *
     * @param detalhes Variavel do tipo string
     * retorna a mensagem de erro com ou sem mensagem adicional do back
     */
    showInfo(detalhes: string) {
        this.msgs = [];
        this.msgs.push({
            severity: 'info',
            summary: 'Info Mensagem',
            detail: detalhes
        });
        return this.msgs;
    }




    // funções abaixo estão em DEV para tratamento de retorno de erros

    showSuccessObj(detalhes: any) {
        this.msgs = [];

        for (let result of detalhes.data) {

            this.msgs.push({
                severity: 'success',
                summary: 'Sucesso',
                detail: result.property + ' : ' + result.message + '<br/>'
            });
        }
        return this.msgs;
    }

    showErrorObj(detalhes: any) {
        this.msgs = [];

        for (let result of detalhes.data) {
            this.msgs.push({
                severity: 'error',
                summary: 'Erro',
                detail: result.property + ' : ' + result.message + '<br/>'
            });
        }
        return this.msgs;
    }

    /**
     * @param date Variavel do tipo String com a contendo a data a ser validada
     * @param maxYear Variavel do tipo Number com o valor máximo de ano a ser validado
     * @param minYear Variavel do tipo Number com o valor minimo de ano a ser validado
     * @return Retorna um objeto do tipo ResultMensage contendo os resultados da validação
     */
    isDateValid(date: string, maxYear?: number, minYear?: number): ResultMensage {
        const re: RegExp = /^[0-9][0-9]\/[0-9][0-9]\/[0-9]{4}$/;
        const result = {} as ResultMensage;

        let dataValida = null;
        dataValida = (date.match(re));
        if (dataValida === null) {
            dataValida = (!new RegExp(re).test(date));
        }

        if (dataValida) { 
            const splitedDate = date.split("/");

            if (!isNullOrUndefined(maxYear)) {
                if (this.isYearGreatThenAllowed(parseInt(splitedDate[2]), maxYear)) {
                    result.valid = false;
                    result.msg = "Data inválida, ano informado maior que o permitido";
                    return result;
                }
            }

            if (!isNullOrUndefined(maxYear)) {
                if (this.isYearLessThenAllowed(parseInt(splitedDate[2]), minYear)) {
                    result.valid = false;
                    result.msg = "Data inválida, ano informado menor que o permitido";
                    return result;
                }
            }

            if (new Date(splitedDate.reverse().join("-")).toString() == "Invalid Date") {
                result.valid = false;
                result.msg = "Data inválida";
                return result;
            } else {
                result.valid = true;
                result.msg = "Data válida";
                return result;
            }

        } else {
            result.valid = false;
            result.msg = "Data inválida";
            return result;
        }
    }

    private isYearGreatThenAllowed(year: number, maxYear: number): boolean {
        if (year > maxYear) { return true; } else { return false; }
    }

    private isYearLessThenAllowed(year: number, minYear: number): boolean {
        if (year < minYear) { return true; } else { return false; }
    }

    public validaEmails(emailInput: string) {

        if (!isNullOrUndefined(emailInput) && !this.isStringNullOrBlank(emailInput, true)) {
            const base = emailInput.substring(0, emailInput.indexOf("@"));
            const dominio = emailInput.substring(emailInput.indexOf("@") + 1, emailInput.length);
            if ((base.length >= 1) &&
                (dominio.length >= 3) &&
                (base.search("@") == -1) &&
                (dominio.search("@") == -1) &&
                (base.search(" ") == -1) &&
                (dominio.search(" ") == -1) &&
                (dominio.search(".") != -1) &&
                (dominio.indexOf(".") >= 1) &&
                (dominio.lastIndexOf(".") < dominio.length - 1)) {
                    return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    public gerarLinkParaRelatorio(relatorio: any, nome: string) {
        const reader = new FileReader();
        const fileName = this.recuperarFileName(nome);
        reader.onloadend = function (e) {
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.href = reader.result.toString();
          link.download = fileName;
          link.target = "_self";
          link.click();
        };
        reader.readAsDataURL(relatorio);
    }

    private recuperarFileName(nome: string) {
        const hoje = new Date();
        const fileName = nome + hoje.toLocaleDateString('pt-BR') + "-" + hoje.toLocaleTimeString("pt-BR") + ".xlsx";
        return fileName;
    }

    public carregaJQuery() {
        setTimeout(function () {
          M.AutoInit();
          $('.dropdown-trigger').dropdown({ "coverTrigger": false });
          $('select').formSelect();
        }, 1);
    }

    public animateScrollTop() {
        setTimeout(function () {
            $('html, body').animate({
              scrollTop: 0
            }, 1000);
        }, 1);
    }
}

