import { BaseService } from './../../util/base.service';
import { Component, Input, ViewChild, OnInit, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { ValueAccessorBase } from '../value-accessor';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { isNullOrUndefined } from 'util';
import { ZSUploadService } from './zs-upload.service';

@Component({
  selector: 'zs-upload',
  templateUrl: './zs-upload.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ZSUploadComponent,
    multi: true,
  }],
})

export class ZSUploadComponent extends ValueAccessorBase<string> implements OnInit {
  @ViewChild(NgModel) zsupload: NgModel;
  @ViewChild('file') fileElement: ElementRef;
  @ViewChild('filepath') filePathElement: ElementRef;
  @Input() entity: string;
  public progress: number;
  public status: number;
  public message: string;
  public isDescricaoValida = true;
  public isArquivoValido = true;
  public descricao: string;
  public isLoading = true;
  public desabilitaDescricao: boolean;

  constructor(private zSUploadService: ZSUploadService) {
    super();
  }

  ngOnInit(): void {
    this.status = 0;
    this.isLoading = false;
  }

  // Função para limpar os dados do componente, útil para validações/modais
  public limpar() {
    this.message = "";
    this.isDescricaoValida = true;
    this.isArquivoValido = true;
    this.status = 0;
    this.filePathElement.nativeElement.value = "";
    this.fileElement.nativeElement.value = null;
    this.descricao = "";
  }

  public validaDados(): boolean {

    const isValido = new Array<any>();
    this.isDescricaoValida = !isNullOrUndefined(this.descricao);
    this.isArquivoValido = this.status === 0;
    isValido.push(this.isDescricaoValida);
    isValido.push(this.isArquivoValido);

    if (!isValido.some(function isAnyFalse(element) { return (element === false); })) {
      return true;
    } else {
      this.limpar();
      return false;
    }
  }

  public upload(files: FileList) {
    if (!files) { return; }

    if (files.length === 0) { return; }

    if (!this.validaDados()) { return; }

    this.isLoading = true;

    const formData = new FormData();
    formData.append('EntidadeOrigem', this.entity);
    formData.append('Descricao', this.descricao);
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      formData.append(file.name, file);
    }

    const myheader = this.zSUploadService.getToken();

    const uploadReq = new HttpRequest('POST', environment.baseUrl + 'arquivos-anexo', formData, 
    {headers: myheader, reportProgress: true });


    // this.zSUploadService.post(environment.baseUrl + 'arquivos-anexo', formData).subscribe(event => {
    this.zSUploadService.http.request<ReturnUpload>(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
        this.isLoading = true;
      } else {
        if (event.type === HttpEventType.Response) {
          this.status = event.body.success ? 1 : -1;
          // this.message = JSON.stringify(event.body);
          if (event.body.success) {
            this.message = "Arquivo enviado!";
            this.isArquivoValido = true;
            this.status = 0;
            this.desabilitaDescricao = true;
          } else {
            this.message = event.body.message;
            this.isArquivoValido = false;
          }
          this.value = event.body.data ? event.body.data.id : "";
          this.isLoading = false;
        }
      }
    });
  }

}
export interface ReturnUpload {
  success: boolean;
  message: string;
  data: {
    id: string;
  };
}
