import { Utils } from './../../../base/util/utils';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { isNullOrUndefined } from 'util';

// PrimeNg
import { Message } from 'primeng/components/common/api';

// VO
import { TipoSolicitacaoVO } from '../../../base/vo/tipo-solicitacao';

// Component
import { NovoTipoSolicitacaoComponent } from './novo-tipo-solicitacao/novo-tipo-solicitacao.component';
import { DetalhaTipoSolicitacaoComponent } from './detalha-tipo-solicitacao/detalha-tipo-solicitacao.component';
import { DeletarTipoSolicitacaoComponent } from './deletar-tipo-solicitacao/deletar-tipo-solicitacao.component';
import { StatusTipoSolicitacaoComponent } from './status-tipo-solicitacao/status-tipo-solicitacao.component';

// Service
import { TipoSolicitacaoService } from './tipo-solicitacao.service';

interface FiltroTipoSolicitacao {
  Codigo: string;
  Descricao: string;
  status: number;

}

declare var M: any;
declare var $: any;


@Component({
  selector: 'app-tipo-solicitacao',
  templateUrl: './tipo-solicitacao.component.html',
  styleUrls: ['./tipo-solicitacao.component.css']
})
export class TipoSolicitacaoComponent implements OnInit {

  @ViewChild('novoTipoSolicitacao')
  public novoTipoSolicitacao: NovoTipoSolicitacaoComponent;

  @ViewChild('detalhaTipoSolicitacao')
  public detalhaTipoSolicitacao: DetalhaTipoSolicitacaoComponent;

  @ViewChild('deletaTipoSolicitacao')
  public deletaTipoSolicitacao: DeletarTipoSolicitacaoComponent;

  @ViewChild('StatusTipoSolicitacao')
  public StatusTipoSolicitacao: StatusTipoSolicitacaoComponent;

  @Input() refazerBusca: any;

  public visibleForm: boolean;
  public displayModal: boolean;

  public activeAutocompleteCodigo: boolean;
  public activeAutocompleteDescricao: boolean;

  public listaTipoSolicitacao: any;

  public isLoading: boolean;
  public numberOfRecords = 0;
  public resultFound: boolean;
  public resultNotFound: boolean;
  public actualpage = 0;
  public salvaPagina = 0;
  public msgs: Message[] = [];
  public listarStatus: any;

  public Codigo = '';
  public Descricao = '';
  public status: number;
  public itemAtualizado: boolean;

  public tipoTipoSolicitacao: TipoSolicitacaoVO;
  public filtros: FiltroTipoSolicitacao;
  public listaCodigos: any;
  public listaDescricao: any;

  public idITipoItemCancela: number;
  public isItensBuscados: boolean;

  constructor(private tipoSolicitacaoService: TipoSolicitacaoService, public serviceUtils: Utils) { }

  ngOnInit() {
    this.carregaJQuery();
    this.recuperaStatus();
    this.tipoTipoSolicitacao = new TipoSolicitacaoVO;
    this.filtros = {} as FiltroTipoSolicitacao;
  }

  buscarTipoSolicitacao() {
    this.isItensBuscados = true;
    this.populaFiltros();
    this.recuperaTipoSolicitacao(1);
  }

  buscarCodigo(event) {
    this.tipoSolicitacaoService.recuperarCodigo(event.query).subscribe(
      data => {
        this.listaCodigos = data.map(function (el) {
          return el.codigo;
        }).filter(this.serviceUtils.onlyUnique);
      },
      erro => {
        console.log(erro);
      }
    );
  }

  buscarDescricao(event) {
    this.tipoSolicitacaoService.recuperarDescricoes(event.query).subscribe(
      data => {
        this.listaDescricao = data.map(element => element.descricao).filter(this.serviceUtils.onlyUnique);
      },
      erro => {
        console.log(erro);
      }
    );
  }

  refazBusca() {
    if (this.refazBusca && this.isItensBuscados) {
      this.buscarTipoSolicitacao();
    }
  }

  public recuperaTipoSolicitacao(page: number) {
    if (page <= 10) {
      this.actualpage = 1;
    }

    this.isLoading = true;
    this.tipoSolicitacaoService.recuperarTipoSolicitacao(this.filtros, page).subscribe(
      data => {
        this.listaTipoSolicitacao = data;
        if (!isNullOrUndefined(this.listaTipoSolicitacao) && this.listaTipoSolicitacao.length > 0) {
          this.numberOfRecords = this.listaTipoSolicitacao[0].numberOfRows;
          this.resultFound = true;
          this.resultNotFound = false;
          this.isLoading = false;
          this.salvaPagina = page;
        } else {
          this.resultFound = false;
          this.resultNotFound = true;
          this.isLoading = false;
        }
      },
      erro => {
        this.resultFound = false;
        this.resultNotFound = true;
        this.isLoading = false;
        console.log(erro);
      }
    );
  }

  paginate(event) {
    this.recuperaTipoSolicitacao(event.page + 1);
    this.actualpage = event.first;
  }

  populaFiltros() {
    this.filtros.Codigo = this.Codigo;
    this.filtros.Descricao = this.Descricao;
    this.filtros.status = this.status;
  }

  limpaCodigo() {
    this.Codigo = null;
  }

  limpaDescricao() {
    this.Descricao = null;
  }

  hideDialog() {
    this.idITipoItemCancela = null;
    this.displayModal = false;
  }

  showDialogNovoItem(idItem) {
    this.novoTipoSolicitacao.showDialogNovoItem(idItem);
  }

  showDialogDetalhaItem(idItem) {
    this.detalhaTipoSolicitacao.showDialogDetalhaItem(idItem);
  }

  showDialogDeletarItem(idItem) {
    this.deletaTipoSolicitacao.showDialogDeletaTipoSolicitacao(idItem);
  }

  showDialogStatusItem(idItem) {
    this.StatusTipoSolicitacao.showDialogStatusItem(idItem);
  }

  recuperaStatus() {
    this.tipoSolicitacaoService.listaStatus().subscribe(
      data => {
        this.listarStatus = data;
        this.carregaJQuery();
      },
      erro => {
        console.log(erro);
      }
    );
  }

  private carregaJQuery() {
    setTimeout(function () {
      M.AutoInit();
      $('.dropdown-trigger').dropdown({ "coverTrigger": false });
      $('select').formSelect();
    }, 2);
  }
}
