import { Component, Input, OnInit } from '@angular/core';
// PrimeNg
import { Message } from 'primeng/components/common/api';
import { PessoaVO } from './../../../../../base/vo/pessoa';
// Service
import { DetalharContatoPessoaService } from './detalhar-contato-pessoa.service';

@Component({
  selector: 'app-detalhar-contato-pessoa',
  templateUrl: './detalhar-contato-pessoa.component.html',
  styleUrls: ['./detalhar-contato-pessoa.component.css'],
  providers: [DetalharContatoPessoaService],
})

export class DetalharContatoPessoaComponent implements OnInit {

  @Input() refazerBusca: any;

  public detalheItem: any;
  public isLoading: boolean;
  public visibleForm: boolean;
  public idItem: number;
  public pessoa: PessoaVO;
  public msgs: Message[] = [];
  public displayModalDetalhaMotivoAmostra: boolean;

  constructor(public detalharContatoPessoaService: DetalharContatoPessoaService) { }

  ngOnInit() {
    this.pessoa = new PessoaVO;
  }

  recuperarDetalhesContatoPessoa(idItem: number) {
    this.isLoading = true;
    this.detalharContatoPessoaService.recuperarContatoPessoa(this.idItem).subscribe(
      data => {
        this.detalheItem = data;
        this.recuperaDetalhesPessoa(this.detalheItem.idPessoa);
        this.isLoading = false;
      },
      erro => {
        console.log(erro);
        this.isLoading = false;
      }
    );
  }

  recuperaDetalhesPessoa(id) {
    this.detalharContatoPessoaService.recuperarDetalhesPessoa(id).subscribe(
      data => {
        this.pessoa = data;
        this.isLoading = false;
      },
      erro => {
        console.log(erro);
        this.isLoading = false;
      }
    );
  }

  showDialogDetalhaItem(id) {
    this.idItem = id;
    this.recuperarDetalhesContatoPessoa(this.idItem);
    this.displayModalDetalhaMotivoAmostra = true;
  }

  hideDialogDetalhaItem() {
    this.displayModalDetalhaMotivoAmostra = false;
  }

}
